/*
 * Copyright (C) 2023 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */
import { __awaiter } from "tslib";
import { AWSUser } from "./AWSUser";
import { AppSyncClientFactory, Service } from "../backend";
import { UsersRndStatusSetDocument } from "../../generated/gqlUsers";
import { throwGQLError } from "../utils";
import { AuthWrapper } from "../auth";
export class AWSUserAdminDetails extends AWSUser {
    constructor(backend, parameters) {
        super(backend, {
            id: parameters.id,
            email: parameters.email,
            policies: [],
            homeOrganization: parameters.organizationId,
        });
        this.androidPn = parameters.androidPn;
        this.iosPn = parameters.iosPn;
        this.creationDate = parameters.creationDate;
        this.emailVerified = parameters.emailVerified;
        this.firstName = parameters.firstName;
        this.lastName = parameters.lastName;
        this.phoneNumber = parameters.phoneNumber;
        this.updatedDate = parameters.updatedDate;
        this.migrationDetails = parameters.migrationDetails;
        this.rndStatus = parameters.rndStatus;
    }
    setAttributes(parameters) {
        var _a, _b, _c, _d;
        this.firstName = (_a = parameters.firstName) !== null && _a !== void 0 ? _a : undefined;
        this.lastName = (_b = parameters.lastName) !== null && _b !== void 0 ? _b : undefined;
        this.phoneNumber = (_c = parameters.phoneNumber) !== null && _c !== void 0 ? _c : undefined;
        this.updatedDate = parameters.updatedDate ? new Date(parameters.updatedDate) : undefined;
        this.rndStatus = (_d = parameters.rndStatus) !== null && _d !== void 0 ? _d : false;
    }
    setRndStatus(rndStatus) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            const client = AppSyncClientFactory.createProvider().getTypedClient(Service.USERS);
            const result = yield client.mutate(UsersRndStatusSetDocument, { userId: this.id.substring(5), rndStatus });
            if ((_a = result.data) === null || _a === void 0 ? void 0 : _a.usersRndStatusSet) {
                this.setAttributes(result.data.usersRndStatusSet);
                const currentUser = yield AuthWrapper.getCurrentAuthenticatedUser();
                const currentUserId = currentUser === null || currentUser === void 0 ? void 0 : currentUser.attributes.sub;
                if (currentUserId === this.id.substring(5)) {
                    AuthWrapper.refreshAuthentication();
                }
            }
            else {
                throwGQLError(result, "Failed to set rnd status");
            }
        });
    }
    getFirstName() {
        return this.firstName;
    }
    getLastName() {
        return this.lastName;
    }
    getCreationDate() {
        return this.creationDate;
    }
    getUpdatedDate() {
        return this.updatedDate;
    }
    getEmailVerified() {
        return this.emailVerified;
    }
    getPhoneNumber() {
        return this.phoneNumber;
    }
    getPlatforms() {
        return { android: !!this.androidPn, ios: !!this.iosPn };
    }
    getMigrationDetails() {
        return this.migrationDetails;
    }
    getRndStatus() {
        return this.rndStatus;
    }
}
