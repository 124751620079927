/*
 * Copyright (C)
 */

import { Button, Dialog, Typography } from "@material-ui/core";
import React, { Component, ReactNode } from "react";
import { translations } from "../../generated/translationHelper";

interface Props {
  message?: string;
  onClose: () => void;
}

export default class NotificationDialog extends Component<Props> {
  private handleOk = (): void => {
    this.props.onClose();
  };

  public render(): ReactNode {
    if (!this.props.message) {
      return null;
    }
    return (
      <Dialog onClose={this.props.onClose} open={true}>
        <div className="popup-inner">
          <Typography variant="h6" style={{ display: "inline-block" }}>
            {translations.common.texts.notification()}
          </Typography>
          <div className="popup-body">
            <p>{this.props.message}</p>
            <div className="buttons-container">
              <Button variant="contained" color="primary" onClick={this.handleOk}>
                {translations.common.buttons.ok()}
              </Button>
            </div>
          </div>
        </div>
      </Dialog>
    );
  }
}
