/*
 * Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */
import "./styles/css/main.css";
import App from "./app";
import AppPalette from "./styles/app-palette";
import Loader from "./components/ui/loader";
import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { getStage } from "./utils/GetStage";
import { initLocalization } from "./locales/localisator";
import { withTranslation } from "react-i18next";
import { AppConfiguration, AuthWrapper, EndpointsConfigurationProvider } from "@sade/data-access";

//this configures the aws connection for the data access module
const configurationProvider = new EndpointsConfigurationProvider(getStage());
AppConfiguration.configure(configurationProvider).then(() => {
  AuthWrapper.configureAmplify();
  initLocalization();

  const theme = createTheme({
    palette: AppPalette.lightPalette,
    overrides: {
      MuiTableHead: {
        root: {
          backgroundColor: "#eeeeee !important",
          opacity: 0.5,
        },
      },
      MuiTableRow: {
        root: {
          "&$selected": {
            backgroundColor: "#ffffff !important",
          },
        },
      },
    },
  });

  // Wraps App component with withTranslation HOC for delivering language changes throughout the app
  const TranslatedApp = withTranslation()(App);

  ReactDOM.render(
    <Suspense fallback={<Loader />}>
      <ThemeProvider theme={theme}>
        <TranslatedApp />
      </ThemeProvider>
    </Suspense>,
    document.getElementById("root")
  );

  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://bit.ly/CRA-PWA
  // serviceWorker.unregister();
});
