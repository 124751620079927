/*
 * Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 *
 */
/* barrierStatus (string)
  String encoded version of different barrier (door or gate) status flags & values
    Encoding done to minimize the RAM consumption of shadow, with TI CC3200 devices RAM is on critical level
    Fields are following
    1: Barrier open (integer, 1 = open, 0 = closed, -1 = unknown)
    2: Barrier open % (integer) i.e. how open is the door, 100 = fully open, 0 = fully closed
    3: Barrier movement status (integer), 0 = no operation, 1 = opening, 2 = closing, -1 = unknown
    4: AC disconnected (integer), 1 = disconnected, 0 = connected, -1 = unknown
    5: Battery charging (integer), 1 = charging, 0 = not charging, -1 = unknown
    6: Battery level (integer), 0 = Good, 1 = Low, 2 = Critically Low, -1 = unknown
    7: Battery condition (integer), 0 = Good, 1 = Replace Soon, 2 = ReplaceNow, 3 = NoBattery, -1 = unknown
    8: Beam blocked (boolean, 0/1), 1 = obstacle blocking beam, 0 = no obstacles
    Above example: barrier is fully open (100%), no barrier movement in progress, AC connected, battery charging, battery low + needs to be replaced soon, beam is not blocked

 "barrierStatus": "1,100,0,0,1,1,1,0" Encoded door/gate status info */
import { numberToString, parseStatusFromString, parseStatusToString, toNumber, } from "./StatusParser";
export var OpenStatus;
(function (OpenStatus) {
    OpenStatus[OpenStatus["Unknown"] = -1] = "Unknown";
    OpenStatus[OpenStatus["Closed"] = 0] = "Closed";
    OpenStatus[OpenStatus["Open"] = 1] = "Open";
})(OpenStatus || (OpenStatus = {}));
export var MovementStatus;
(function (MovementStatus) {
    MovementStatus[MovementStatus["Unknown"] = -1] = "Unknown";
    MovementStatus[MovementStatus["NoOperation"] = 0] = "NoOperation";
    MovementStatus[MovementStatus["Opening"] = 1] = "Opening";
    MovementStatus[MovementStatus["Closing"] = 2] = "Closing";
})(MovementStatus || (MovementStatus = {}));
export var AcDisconnectedStatus;
(function (AcDisconnectedStatus) {
    AcDisconnectedStatus[AcDisconnectedStatus["Unknown"] = -1] = "Unknown";
    AcDisconnectedStatus[AcDisconnectedStatus["Connected"] = 0] = "Connected";
    AcDisconnectedStatus[AcDisconnectedStatus["Disconnected"] = 1] = "Disconnected";
})(AcDisconnectedStatus || (AcDisconnectedStatus = {}));
export var BatteryChargingStatus;
(function (BatteryChargingStatus) {
    BatteryChargingStatus[BatteryChargingStatus["Unknown"] = -1] = "Unknown";
    BatteryChargingStatus[BatteryChargingStatus["NotCharging"] = 0] = "NotCharging";
    BatteryChargingStatus[BatteryChargingStatus["Charging"] = 1] = "Charging";
})(BatteryChargingStatus || (BatteryChargingStatus = {}));
export var BatteryLevel;
(function (BatteryLevel) {
    BatteryLevel[BatteryLevel["Unknown"] = -1] = "Unknown";
    BatteryLevel[BatteryLevel["Good"] = 0] = "Good";
    BatteryLevel[BatteryLevel["Low"] = 1] = "Low";
    BatteryLevel[BatteryLevel["CriticallyLow"] = 2] = "CriticallyLow";
})(BatteryLevel || (BatteryLevel = {}));
export var BatteryCondition;
(function (BatteryCondition) {
    BatteryCondition[BatteryCondition["Unknown"] = -1] = "Unknown";
    BatteryCondition[BatteryCondition["Good"] = 0] = "Good";
    BatteryCondition[BatteryCondition["ReplaceSoon"] = 1] = "ReplaceSoon";
    BatteryCondition[BatteryCondition["ReplaceNow"] = 2] = "ReplaceNow";
    BatteryCondition[BatteryCondition["NoBattery"] = 3] = "NoBattery";
})(BatteryCondition || (BatteryCondition = {}));
export var BeamBlockedStatus;
(function (BeamBlockedStatus) {
    BeamBlockedStatus[BeamBlockedStatus["NotBlocked"] = 0] = "NotBlocked";
    BeamBlockedStatus[BeamBlockedStatus["Blocked"] = 1] = "Blocked";
})(BeamBlockedStatus || (BeamBlockedStatus = {}));
export var BarrierEvent;
(function (BarrierEvent) {
    BarrierEvent["barrierOpen"] = "event-user-barrier-open";
    BarrierEvent["barrierClosed"] = "event-user-barrier-closed";
    BarrierEvent["barrierObstructed"] = "event-error-barrier-obstructed";
    BarrierEvent["lightOn"] = "event-user-light-on";
    BarrierEvent["lightOff"] = "event-user-light-off";
})(BarrierEvent || (BarrierEvent = {}));
const fields = [
    "open",
    "openPercentage",
    "movementStatus",
    "acDisconnected",
    "batteryCharging",
    "batteryLevel",
    "batteryCondition",
    "beamBlocked",
];
const convertToValue = {
    open: toNumber,
    openPercentage: toNumber,
    movementStatus: toNumber,
    acDisconnected: toNumber,
    batteryCharging: toNumber,
    batteryLevel: toNumber,
    batteryCondition: toNumber,
    beamBlocked: toNumber,
};
const convertToString = {
    open: numberToString,
    openPercentage: numberToString,
    movementStatus: numberToString,
    acDisconnected: numberToString,
    batteryCharging: numberToString,
    batteryLevel: numberToString,
    batteryCondition: numberToString,
    beamBlocked: numberToString,
};
export const parseBarrierStatusFromString = (value) => parseStatusFromString(value, fields, convertToValue, fields.length);
export const parseBarrierStatusToString = (barrierStatus) => parseStatusToString(barrierStatus, fields, convertToString);
