/*
 * Copyright (C) 2023 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 *
 */

import React, { Component, ReactNode } from "react";
import { Chart } from "react-google-charts";
import Loader from "../../../../ui/loader";
import { Moment } from "moment";
import { EventsRepository } from "@sade/data-access";

const CHART_OPTIONS = {
  legend: { position: "none" },
  isStacked: true,
  hAxis: {
    gridlines: {
      color: "transparent",
    },
  },
};

interface Props {
  deviceId: string;
  data: Map<string /* event id */, Moment[] /* event dates */>;
  startDate: Moment;
  endDate: Moment;
}

interface State {
  chartData?: (string | Date | number)[][];
}

export default class EventsTimelineChart extends Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = {};
  }

  public async componentDidMount(): Promise<void> {
    this.updateChartData();
  }

  public async componentDidUpdate(prevProps: Readonly<Props>): Promise<void> {
    if (
      prevProps.deviceId !== this.props.deviceId ||
      prevProps.startDate !== this.props.startDate ||
      prevProps.endDate !== this.props.endDate
    ) {
      this.updateChartData();
    }
  }

  private initializeHistogram(
    startDate: Moment,
    endDate: Moment,
    numCategories: number
  ): { histogram: Map<string, [Date, ...number[]]>; binFunction: (date: Moment) => string } {
    const histogram = new Map<string, [Date, ...number[]]>();

    const timePeriodMinutes = endDate.diff(startDate, "minutes");
    let numBins = 0;
    let binFunction = undefined;
    let increment: "d" | "h" | "m";

    if (timePeriodMinutes > 7 * 24 * 60) {
      numBins = endDate.diff(startDate, "days");
      increment = "d";
      binFunction = (date: Moment): string => date.format("YYYY-MM-DD 00:00:00");
    } else if (timePeriodMinutes > 24 * 60) {
      numBins = endDate.diff(startDate, "hours");
      increment = "h";
      binFunction = (date: Moment): string => date.format("YYYY-MM-DD HH:00:00");
    } else {
      numBins = endDate.diff(startDate, "minutes");
      increment = "m";
      binFunction = (date: Moment): string => date.format("YYYY-MM-DD HH:mm:00");
    }

    const zeros: number[] = Array(numCategories).fill(0);
    let currentDate = this.props.startDate.clone();
    for (let i = 0; i < numBins; i++) {
      const bin = binFunction(currentDate);
      histogram.set(bin, [new Date(bin), ...zeros]);
      currentDate = currentDate.add(1, increment);
    }

    return { histogram, binFunction };
  }

  private updateChartData = (): void => {
    if (this.props.data.size === 0) {
      return;
    }
    const legendData = [...this.props.data.keys()];
    const { histogram, binFunction } = this.initializeHistogram(
      this.props.startDate,
      this.props.endDate,
      legendData.length
    );

    // Fill histogram with data
    for (const [eventId, dates] of this.props.data.entries()) {
      const eventIndex = legendData.indexOf(eventId);
      if (eventIndex === -1) continue;
      dates.forEach((date) => {
        const key = binFunction(date);
        const binData = histogram.get(key);
        if (!binData) return;
        (binData[eventIndex + 1] as number) += 1;
      });
    }

    const legendRow = legendData.map((eventId) => EventsRepository.instance.getEventDescription(eventId));
    this.setState({
      chartData: [["", ...legendRow], ...histogram.values()],
    });
  };

  public render(): ReactNode {
    if (!this.state.chartData) return null;
    return <Chart chartType="ColumnChart" loader={<Loader />} data={this.state.chartData} options={CHART_OPTIONS} />;
  }
}
