/*
 * Copyright (C) 2022 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 *
 */
export function isEntityRecord(value) {
    return typeof value === "object" && value != null && "entity" in value;
}
/**
 * Contains paired (or recently unpaired entities).
 */
export class RelationChange {
    constructor(entity) {
        this.ctor = entity.constructor.name === "Function" ? entity : entity.entityType;
    }
    ofType(type) {
        return this.ctor === type;
    }
}
/**
 * Caches entities that have a N-M relationship, and both of those entities need to be aware of the other.
 * For example, there are entities of type A and B, and they have the following methods:
 * A::getMyBEntities() => B[]
 * B::getMyAEntities() => A[]
 *
 * Given that there are ways to mutate those lists, and mutations to one list can cause mutations to the other list,
 * this cache centralizes those relationships without having to use cross-listeners.
 *
 */
export class EntityRelationCache {
    constructor() {
        this.entityMap = new Map();
    }
    /**
     * Creates a linked pair.
     * Self-links are ignored (cannot link an entity to itself).
     *
     * @param a
     *    entity
     * @param b
     *    entity
     * @param metadata
     *    custom information about the link
     */
    link(a, b, metadata) {
        if (a === b) {
            return;
        }
        if (this.createLink(a, b, metadata)) {
            this.notifyActionToBoth(a, b);
        }
    }
    /**
     * Removes a link from between pairs
     *
     * @param a
     *    entity
     * @param b
     *    entity
     */
    unlink(a, b) {
        if (this.removeLink(a, b)) {
            this.notifyActionToBoth(a, b);
        }
    }
    /**
     * Retrieves metadata for a pair of entities
     *
     * @param a
     *    entity
     * @param b
     *    entity
     */
    getMetadata(a, b) {
        var _a;
        return (_a = this.entityMap.get(a)) === null || _a === void 0 ? void 0 : _a.get(b);
    }
    /**
     * Retrieves all entities of particular type linked to the given entity
     *
     * @param a
     *    entity
     * @param ctor
     *    type's constructor
     */
    listFor(a, ctor) {
        return this.listEntityRecordsFor(a, ctor).map((pair) => pair.entity);
    }
    /**
     * Retrieves all {@link EntityRecord}s for a particular type linked to the given entity
     *
     * @param a
     *    entity
     * @param ctor
     *    type's constructor
     */
    listEntityRecordsFor(a, ctor) {
        const metaMap = this.entityMap.get(a);
        if (!metaMap) {
            return [];
        }
        const records = [];
        for (const [key, metadata] of metaMap.entries()) {
            if (key instanceof ctor) {
                records.push({ entity: key, metadata });
            }
        }
        return records;
    }
    /**
     * Removes existing links from entity {@code a} to all entities of type {@code ctor}.
     * Use {@code keepFilter} to single out linked {@code EntityRecords} that should not be removed.
     *
     * @param a
     *    entity
     * @param ctor
     *    type's constructor
     * @param keepFilter
     *    optional filter for skipping records
     */
    removeTypedLinks(a, ctor, keepFilter) {
        const removedEntities = this.removeLinksForType(a, ctor, keepFilter);
        if (removedEntities.length === 0) {
            return false;
        }
        const aChange = new RelationChange(a);
        removedEntities.forEach((entity) => this.notifyRelationChange(entity, aChange));
        const entityTypes = new Set(removedEntities.map((entity) => entity.entityType));
        entityTypes.forEach((entityType) => this.notifyRelationChange(a, new RelationChange(entityType)));
        return true;
    }
    /**
     * Removes those links of a's where the linked entity does not exist in the entities list.
     * Then links those entities, that were not already linked.
     * The old and new linked entities must share type.
     *
     * If {@code entities} is an empty list, performs exactly as {@link removeTypedLinks}.
     *
     * @param a
     *    entity
     * @param typeHint
     *    constructor of the entities. Used as a type hint to perform replacements
     * @param entities
     *    list of potentially new entities to link
     * @param keepFilter
     *    method for checking, whether an old entity should be kept as linked
     */
    replaceTypedLinks(a, typeHint, entities, keepFilter) {
        const removedEntities = this.removeLinksForType(a, typeHint, keepFilter);
        const recordsToAdd = EntityRelationCache.isEntityRecordArray(entities)
            ? entities
            : entities.map((entity) => ({ entity }));
        const addedEntities = recordsToAdd
            .filter((record) => this.createLink(a, record.entity, record.metadata))
            .map((record) => record.entity);
        // next, we'll build two sets: removed entities and added entities
        // then we we will calculate symmetric difference (https://en.wikipedia.org/wiki/Symmetric_difference)
        // for those sets. This gives us all the entities (besides a) which have been either removed or added
        // since entity which is both removed and added should not be notified
        const addedSet = new Set(addedEntities);
        const symmetricDifference = new Set(removedEntities);
        for (const entity of addedSet) {
            if (symmetricDifference.has(entity)) {
                symmetricDifference.delete(entity);
            }
            else {
                symmetricDifference.add(entity);
            }
        }
        const aChange = new RelationChange(a);
        symmetricDifference.forEach((entity) => this.notifyRelationChange(entity, aChange));
        if (symmetricDifference.size > 0) {
            this.notifyRelationChange(a, new RelationChange(typeHint));
        }
    }
    /**
     * Removes entity and all its links from the cache
     *
     * @param a
     *    entity
     */
    remove(a) {
        const map = this.entityMap.get(a);
        if (!map) {
            return false;
        }
        this.entityMap.delete(a);
        const change = new RelationChange(a);
        [...map.keys()].forEach((b) => {
            this.removeFor(b, a);
            this.notifyRelationChange(b, change);
        });
        return true;
    }
    /**
     * Does cache contain mapping for entity
     *
     * @param a
     *    entity
     */
    contains(a) {
        return this.entityMap.has(a);
    }
    /**
     * Empties the cache
     */
    clear() {
        this.entityMap.clear();
    }
    createLink(a, b, metadata) {
        const ab = this.addFor(a, b, metadata);
        const ba = this.addFor(b, a, metadata);
        return ab || ba;
    }
    removeLink(a, b, retainCollection) {
        const ab = this.removeFor(a, b, retainCollection);
        const ba = this.removeFor(b, a, retainCollection);
        return ab || ba;
    }
    removeLinksForType(a, ctor, keepFilter) {
        const keep = keepFilter !== null && keepFilter !== void 0 ? keepFilter : ((_) => false);
        const records = this.listEntityRecordsFor(a, ctor);
        if (records.length === 0) {
            return [];
        }
        return records
            .filter((record) => !keep(record) && this.removeLink(a, record.entity))
            .map((record) => record.entity);
    }
    addFor(a, b, metadata) {
        const map = this.getMap(a);
        if (map.has(b)) {
            return false;
        }
        else {
            map.set(b, metadata);
            return true;
        }
    }
    removeFor(a, b, retainCollection) {
        const map = this.entityMap.get(a);
        if (!map) {
            return false;
        }
        const result = map.delete(b);
        if (!retainCollection) {
            this.removeIfEmpty(a);
        }
        return result;
    }
    getMap(a) {
        if (!this.entityMap.has(a)) {
            this.entityMap.set(a, new Map());
        }
        return this.entityMap.get(a);
    }
    removeIfEmpty(a) {
        var _a;
        if (((_a = this.entityMap.get(a)) === null || _a === void 0 ? void 0 : _a.size) === 0) {
            this.entityMap.delete(a);
        }
    }
    notifyActionToBoth(a, b) {
        setTimeout(() => a.onRelationChange(new RelationChange(b)), 0);
        setTimeout(() => b.onRelationChange(new RelationChange(a)), 0);
    }
    notifyRelationChange(target, change) {
        setTimeout(() => target.onRelationChange(change), 0);
    }
    static isEntityRecordArray(array) {
        return array.length === array.filter(isEntityRecord).length;
    }
}
