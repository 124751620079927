/*
 * Copyright (C) 2023 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 *
 */

import React, { Component } from "react";
import { UserAdminDetails } from "@sade/data-access";
import { Grid } from "@material-ui/core";
import UserDetailsRenderer from "./user-details-renderer";
import UserMigrationDetails from "./user-migration-details";

interface Props {
  userDetails: UserAdminDetails;
}

export default class Overview extends Component<Props> {
  public constructor(props: Props) {
    super(props);
  }

  public render(): JSX.Element {
    return (
      <Grid container justifyContent="center">
        <UserDetailsRenderer userDetails={this.props.userDetails} />
        <UserMigrationDetails userDetails={this.props.userDetails} />
      </Grid>
    );
  }
}
