/*
 * Copyright (C) 2023 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 *
 */

import React, { Component, Fragment, ReactNode } from "react";
import { Chip, FormControlLabel, Grid, List, ListItem, Switch } from "@material-ui/core";
import { translations } from "../../../../generated/translationHelper";
import { UserAdminDetails, UserPlatforms } from "@sade/data-access";

interface Props {
  userDetails: UserAdminDetails;
}

interface State {
  rndStatusPending: boolean;
}

export default class UserDetailsRenderer extends Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = {
      rndStatusPending: false,
    };
  }

  private renderPlatforms(platforms: UserPlatforms): string {
    if (platforms.android && platforms.ios) {
      return `${translations.admin.texts.android()} & ${translations.admin.texts.ios()}`;
    } else if (platforms.android) {
      return translations.admin.texts.android();
    } else if (platforms.ios) {
      return translations.admin.texts.ios();
    } else {
      return translations.common.inputs.none();
    }
  }

  private renderEmailVerified(verified: boolean): ReactNode {
    if (verified) {
      return <Chip className="overview-chip-green" label={translations.admin.texts.confirmed()} />;
    } else {
      return <Chip className="overview-chip-red" label={translations.admin.texts.unconfirmed()} />;
    }
  }

  private handleRndChange = (): void => {
    const { userDetails } = this.props;
    const rndStatus = userDetails.getRndStatus();
    this.setState(
      {
        rndStatusPending: true,
      },
      async () => {
        await userDetails.setRndStatus(rndStatus ? false : true);
        this.setState({
          rndStatusPending: false,
        });
      }
    );
  };

  private renderRndSwitch = (): ReactNode => {
    const { userDetails } = this.props;
    return (
      <FormControlLabel
        control={
          <Switch
            checked={userDetails.getRndStatus()}
            onChange={this.handleRndChange}
            name="checkedB"
            color="primary"
            disabled={this.state.rndStatusPending}
          />
        }
        label={userDetails.getRndStatus() ? translations.common.texts.enabled() : translations.common.texts.disabled()}
      />
    );
  };

  public render(): ReactNode {
    return (
      <Fragment>
        <Grid container className="overview-header-grid" justifyContent="space-between">
          <Grid item xs={10}>
            {translations.admin.texts.accountInfo()}
          </Grid>
          <Grid item xs={1}>
            {this.renderEmailVerified(this.props.userDetails.getEmailVerified())}
          </Grid>
        </Grid>
        <Grid container className="overview-content-grid">
          <Grid item xs={4}>
            <List>
              <ListItem>{translations.admin.texts.userId().toUpperCase()}</ListItem>
              <ListItem>{translations.admin.texts.userEmail().toUpperCase()}</ListItem>
              <ListItem>{translations.admin.texts.createdDate().toUpperCase()}</ListItem>
              <ListItem>{translations.admin.texts.updatedDate().toUpperCase()}</ListItem>
              <ListItem>{translations.admin.texts.organisationId().toUpperCase()}</ListItem>
              <ListItem>{translations.admin.texts.userFirstname().toUpperCase()}</ListItem>
              <ListItem>{translations.admin.texts.userLastname().toUpperCase()}</ListItem>
              <ListItem>{translations.admin.texts.userPlatforms().toUpperCase()}</ListItem>
              <ListItem className="rnd-status-label">{translations.admin.texts.rndStatus().toUpperCase()}</ListItem>
            </List>
            <Grid item></Grid>
          </Grid>
          <Grid item xs={8}>
            <List>
              <ListItem>{this.props.userDetails.getId()}</ListItem>
              <ListItem>{this.props.userDetails.getUsername()}</ListItem>
              <ListItem>
                {this.props.userDetails.getCreationDate()?.toLocaleString() ?? translations.common.texts.notAvailable()}
              </ListItem>
              <ListItem>
                {this.props.userDetails.getUpdatedDate()?.toLocaleString() ?? translations.common.texts.notAvailable()}
              </ListItem>
              <ListItem>{this.props.userDetails.getHomeOrganizationId()}</ListItem>
              <ListItem>{this.props.userDetails.getFirstName() ?? translations.common.texts.notAvailable()}</ListItem>
              <ListItem>{this.props.userDetails.getLastName() ?? translations.common.texts.notAvailable()}</ListItem>
              <ListItem>
                {this.renderPlatforms(this.props.userDetails?.getPlatforms()) ??
                  translations.common.texts.notAvailable()}
              </ListItem>
              <ListItem className="rnd-status-switch">{this.renderRndSwitch()}</ListItem>
            </List>
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}
