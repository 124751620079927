/*
 * Copyright (C) 2023 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */

import { Grid } from "@material-ui/core";
import MUIDataTable, { MUIDataTableColumnDef, MUIDataTableOptions } from "mui-datatables";
import { Device, Event, EventsRepository } from "@sade/data-access";
import React, { Component, Fragment, ReactNode } from "react";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import { translations } from "../../../../../generated/translationHelper";
import Loader from "../../../../ui/loader";
import TimeRangePicker from "../../../../ui/time-range-picker";
import moment, { Moment } from "moment";
import EventsTimelineChart from "./events-timeline-chart";
import EventsPieChart from "./events-pie-chart";
import { RouteComponentProps, withRouter } from "react-router-dom";

interface Props extends RouteComponentProps {
  device: Device;
}

interface State {
  events?: Event[];
  startTimestamp?: number;
  endTimestamp?: number;
  loading: boolean;
}

type EventTableCell = string | number;
type EventTableRow = EventTableCell[];

const theme = createTheme({
  overrides: {
    MUIDataTableToolbar: {
      filterPaper: {
        width: "450px",
      },
    },
  },
});

class DeviceEvents extends Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    const timestamps = DeviceEvents.parseTimestamps(this.props.location.search);
    this.state = {
      startTimestamp: timestamps.startTimestamp,
      endTimestamp: timestamps.endTimestamp,
      loading: false,
    };
  }

  private readonly tableColumns: MUIDataTableColumnDef[] = [
    {
      name: "id",
      label: translations.admin.inputs.eventType().toUpperCase(),
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "timestamp",
      label: translations.admin.texts.timeAndDate().toUpperCase(),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value): string => {
          const timestamp = new Date(value);
          return timestamp.toLocaleString();
        },
      },
    },
    {
      name: "metadata",
      label: translations.events.data.metadata().toUpperCase(),
      options: {
        filter: true,
        sort: true,
      },
    },
  ];

  private readonly tableOptions: MUIDataTableOptions = {
    sortOrder: {
      name: "timestamp",
      direction: "desc",
    },
    filterType: "multiselect",
    selectableRowsHeader: false,
    selectableRowsHideCheckboxes: true,
    selectableRowsOnClick: true,
    selectToolbarPlacement: "above",
    textLabels: {
      body: {
        noMatch: translations.events.texts.noEventsFound(),
        toolTip: translations.common.texts.sort(),
      },
      pagination: {
        next: translations.common.buttons.nextPage(),
        previous: translations.common.buttons.previousPage(),
        rowsPerPage: translations.common.inputs.rowsPerPage(),
        displayRows: translations.common.texts.of(),
      },
      toolbar: {
        downloadCsv: translations.common.buttons.downloadCsv(),
        filterTable: translations.common.buttons.filterTable(),
        print: translations.common.buttons.print(),
        search: translations.common.buttons.search(),
        viewColumns: translations.common.buttons.viewColumns(),
      },
      filter: {
        reset: translations.common.buttons.reset(),
        title: translations.common.texts.filters().toUpperCase(),
      },
      viewColumns: {
        title: translations.common.texts.showColumns(),
      },
    },
  };

  public async componentDidMount(): Promise<void> {
    this.updateDeviceEvents();
  }

  public async componentDidUpdate(prevProps: Readonly<Props>): Promise<void> {
    if (prevProps.device !== this.props.device) {
      this.updateDeviceEvents();
    }
  }

  private updateUrl = (startTimestamp: number, endTimestamp: number): void => {
    const urlParams = new URLSearchParams(this.props.location.search);
    urlParams.set("startTimestamp", startTimestamp.toString());
    urlParams.set("endTimestamp", endTimestamp.toString());
    this.props.history.push(`${this.props.location.pathname}?${urlParams.toString()}`);
  };

  private updateDeviceEvents = async (
    startTimestamp = this.state.startTimestamp,
    endTimestamp = this.state.endTimestamp
  ): Promise<void> => {
    if (!startTimestamp || !endTimestamp) return;
    this.setState({
      loading: true,
    });
    const deviceEvents = await this.props.device.getEventSet(startTimestamp, endTimestamp, { force: true });
    this.setState(
      {
        events: deviceEvents?.getData(),
        startTimestamp: startTimestamp,
        endTimestamp: endTimestamp,
        loading: false,
      },
      () => {
        this.updateUrl(startTimestamp, endTimestamp);
      }
    );
  };

  private parseMetadata(metadata: string): Record<string, string> {
    const noCurlies = metadata.substring(1, metadata.length - 1);
    const pairs = noCurlies.split(", ").map((pair) => pair.split("="));
    return Object.fromEntries(pairs);
  }

  private getPrintableMetadata = (metadata: string): string => {
    if (metadata[0] === "{") {
      const parsed = this.parseMetadata(metadata);
      if (parsed.originatorFirstName && parsed.originatorLastName) {
        const originator = `${parsed.originatorFirstName} ${parsed.originatorLastName}`;
        parsed.originator = originator;
        delete parsed.originatorFirstName;
        delete parsed.originatorLastName;
      }
      return JSON.stringify(parsed);
    }
    return metadata;
  };

  private getTableData = (): EventTableRow[] => {
    const eventTime = new Date();
    return (this.state.events ?? []).map((event) => {
      return [
        event.eventId ? EventsRepository.instance.getEventDescription(event.eventId) : "",
        eventTime.setTime(parseInt(event.timestamp)),
        event.metadata ? this.getPrintableMetadata(event.metadata) : "",
      ];
    });
  };

  private getChartData = (): Map<string, Moment[]> => {
    return (this.state.events ?? []).reduce((map, event) => {
      const { eventId, timestamp } = event;
      if (!eventId) return map;
      if (!map.has(eventId)) {
        map.set(eventId, []);
      }
      map.get(eventId)!.push(moment(parseInt(timestamp)));
      return map;
    }, new Map<string, Moment[]>());
  };

  private static getWeekAgoDateTime = (): number => {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() - 7);
    return currentDate.getTime();
  };

  private static parseTimestamps = (query: string): { startTimestamp: number; endTimestamp: number } => {
    const urlParams = new URLSearchParams(query);
    const startTimestamp = urlParams.get("startTimestamp");
    const endTimestamp = urlParams.get("endTimestamp");
    return {
      startTimestamp: startTimestamp ? parseInt(startTimestamp) : this.getWeekAgoDateTime(),
      endTimestamp: endTimestamp ? parseInt(endTimestamp) : Date.now(),
    };
  };

  private renderHeader(): ReactNode {
    return (
      <Grid container spacing={1} className="event-header-grid">
        <Grid container spacing={1} style={{ flex: 2 }}>
          <Grid item>{translations.events.texts.events()}</Grid>
          <Grid item>&#8213;</Grid>
          <Grid item>
            {translations.admin.texts.lastEventDays({
              number: moment(this.state.endTimestamp).diff(moment(this.state.startTimestamp), "days"),
            })}
          </Grid>
        </Grid>
        <Grid container justifyContent="flex-start" style={{ flex: 3 }}>
          <Grid item>
            <TimeRangePicker
              startTimestamp={this.state.startTimestamp}
              endTimestamp={this.state.endTimestamp}
              onTimeRangeSelect={this.updateDeviceEvents}
              disabled={false}
            />
          </Grid>
        </Grid>
        <Grid container justifyContent="flex-start" style={{ flex: 1 }}>
          <Loader show={this.state.loading} size={"small"} topBottomPadding="0" leftRightPadding="0" />
        </Grid>
      </Grid>
    );
  }

  private renderEventTable(): ReactNode {
    if (!this.state.events) return <Loader />;
    return (
      <Grid container className="event-table-grid">
        <Grid item className="event-table">
          <ThemeProvider theme={theme}>
            <MUIDataTable
              title={translations.events.texts.events()}
              data={this.getTableData()}
              columns={this.tableColumns}
              options={this.tableOptions}
            />
          </ThemeProvider>
        </Grid>
      </Grid>
    );
  }

  private renderEventCharts(): ReactNode {
    if (!this.state.events || this.state.events.length === 0) return null;
    return (
      <Grid container className="event-chart-container">
        <Grid item xs={8} className="event-chart-grid">
          <EventsTimelineChart
            deviceId={this.props.device.getId()}
            data={this.getChartData()}
            startDate={moment(this.state.startTimestamp)}
            endDate={moment(this.state.endTimestamp)}
          />
        </Grid>
        <Grid item xs={4} className="event-chart-grid">
          <EventsPieChart deviceId={this.props.device.getId()} data={this.getChartData()} />
        </Grid>
      </Grid>
    );
  }

  public render(): ReactNode {
    return (
      <Fragment>
        {this.renderHeader()}
        {this.renderEventCharts()}
        {this.renderEventTable()}
      </Fragment>
    );
  }
}

export default withRouter(DeviceEvents);
