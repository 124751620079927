/*
 * Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */
import { GateUnitHWState } from "../GateUnitHW";
export class Mms100HWState extends GateUnitHWState {
    set powerLevel(value) {
        this.desired.powerLevel = value;
        this.changedValues.powerLevel = value;
    }
    get powerLevel() {
        return this.deltaExists("powerLevel") ? this.desired.powerLevel : this.reported.powerLevel;
    }
}
