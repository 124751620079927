/*
 * Copyright (C) 2022 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */
import moment from "moment";
const HOUR_AS_MILLISECONDS = 60 * 60 * 1000;
const MINUTE_AS_MILLISECONDS = 60 * 1000;
// For sorting device names ending with a number (so that e.g. "Device 2" shows up before "Device 10")
function compareDeviceNamesAsNumbers(deviceName1, deviceName2) {
    try {
        // Split up the strings to compare into tokens
        const tokens1 = deviceName1.split(" ");
        const tokens2 = deviceName2.split(" ");
        // We only care about strings with equal number of tokens
        if (tokens1.length > 0 && tokens1.length === tokens2.length) {
            // Compare the all the other tokens except the last one...
            let tokenComparisons = 0;
            tokens1.forEach((token1, index) => {
                if (index < tokens1.length - 1) {
                    tokenComparisons += Math.abs(token1.localeCompare(tokens2[index]));
                }
            });
            // If all other tokens were equal, we compare the final tokens
            if (tokenComparisons === 0) {
                // ...and compare them as numbers, if applicable
                const number1 = Number(tokens1[tokens1.length - 1].replace("#", ""));
                const number2 = Number(tokens2[tokens2.length - 1].replace("#", ""));
                // One or both of the tokens are not a number => rely on "normal" comparison
                if (isNaN(number1) || isNaN(number2)) {
                    return 0;
                }
                return number1 < number2 ? -1 : 1;
            }
        }
    }
    catch (error) {
        console.log("Error in compareDeviceNamesAsNumbers", error);
    }
    return 0;
}
export var DateTimeFormatTarget;
(function (DateTimeFormatTarget) {
    DateTimeFormatTarget[DateTimeFormatTarget["ChartTimeAxis"] = 0] = "ChartTimeAxis";
    DateTimeFormatTarget[DateTimeFormatTarget["ChartTooltip"] = 1] = "ChartTooltip";
    DateTimeFormatTarget[DateTimeFormatTarget["ShadowUpdate"] = 2] = "ShadowUpdate";
    DateTimeFormatTarget[DateTimeFormatTarget["StatusTable"] = 3] = "StatusTable";
    DateTimeFormatTarget[DateTimeFormatTarget["EventsTable"] = 4] = "EventsTable";
})(DateTimeFormatTarget || (DateTimeFormatTarget = {}));
export var DrawerState;
(function (DrawerState) {
    DrawerState[DrawerState["Closed"] = 0] = "Closed";
    DrawerState[DrawerState["Open"] = 1] = "Open";
    DrawerState[DrawerState["Full"] = 2] = "Full";
})(DrawerState || (DrawerState = {}));
export function getDisplayName(device) {
    if (device) {
        const deviceState = device.getState();
        if (deviceState && deviceState.displayName) {
            return deviceState.displayName;
        }
        return device.getId();
    }
    return "N/A";
}
export function getDateTimeFormat(target) {
    switch (target) {
        case DateTimeFormatTarget.ChartTimeAxis:
            return "d/M HH:mm";
        case DateTimeFormatTarget.ChartTooltip:
            return "d/M/yyyy HH:mm:ss";
        case DateTimeFormatTarget.StatusTable:
        case DateTimeFormatTarget.EventsTable:
            return "DD/MM/YYYY HH:mm:ss";
        case DateTimeFormatTarget.ShadowUpdate:
            return "D/M/Y HH:mm:ss";
    }
}
export function numberToFixed(value, decimalCount) {
    if (isNaN(value) || value == null) {
        return "N/A";
    }
    return value.toFixed(decimalCount);
}
export function convertTimestampToString(timestamp, formatTarget) {
    return moment(timestamp).format(getDateTimeFormat(formatTarget));
}
export function convertStringToTimestamp(dateTime, inputFormat) {
    return moment(dateTime, getDateTimeFormat(inputFormat)).unix();
}
export function millisecondsToHoursAndMinutes(milliseconds) {
    const hours = Math.floor(milliseconds / HOUR_AS_MILLISECONDS);
    const minutes = Math.floor((milliseconds % HOUR_AS_MILLISECONDS) / MINUTE_AS_MILLISECONDS);
    if (hours > 0) {
        return hours + " h " + minutes + " min";
    }
    return minutes + " min";
}
export function compareDevicesForSorting(first, second) {
    const firstName = getDisplayName(first);
    const secondName = getDisplayName(second);
    const numberComparison = compareDeviceNamesAsNumbers(firstName, secondName);
    if (numberComparison === 0) {
        return firstName.localeCompare(secondName);
    }
    else {
        return numberComparison;
    }
}
export function compareGroupsForSorting(first, second) {
    return first.getId().localeCompare(second.getId());
}
export function timestampToMilliseconds(timestamp) {
    let millisecTimestamp = timestamp;
    if (`${timestamp}`.length < 13) {
        // timestamp seems to be expressed as seconds => convert to millisec
        millisecTimestamp = timestamp * 1000;
    }
    return millisecTimestamp;
}
export function isTimestampWithinTimeoutBounds(timestamp, timeoutMs) {
    const deltaMs = Date.now() - timestampToMilliseconds(timestamp);
    return deltaMs < timeoutMs;
}
export function startInterval(callback, interval) {
    let id = -1;
    const stop = () => window.clearInterval(id);
    id = window.setInterval(callback, interval, stop);
    return stop;
}
export function startTimeout(callback, timeout) {
    let id = -1;
    const stop = () => window.clearTimeout(id);
    id = window.setTimeout(callback, timeout, stop);
    return stop;
}
export function throwGQLError(response, fallback = "Unexpected error") {
    if (!response.errors || response.errors.length === 0) {
        throw new Error(fallback);
    }
    else {
        const error = response.errors[0]; // for now, ignore all the other errors
        const msg = `${error.name}: ${error.message}${error.originalError ? "( " + error.originalError.message + " )" : ""}`;
        throw new Error(msg);
    }
}
/**
 * Verifies that value is an object.
 * @param {unknown} value Potential object
 * @returns {boolean} result
 */
export function isObject(value) {
    return typeof value === "object" && value != null && !Array.isArray(value);
}
export function timestampToHumanReadable(stamp) {
    return moment(stamp).format("DD.MM.YYYY HH:mm:ss");
}
