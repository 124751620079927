/*
 * Copyright (C) 2023 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */

import React, { Component, Fragment, ReactNode } from "react";
import { Grid, List, ListItem } from "@material-ui/core";
import { Device, Invitation, InvitationObject, ResponseStatus } from "@sade/data-access";
import { translations } from "../../../../../generated/translationHelper";

interface Props {
  invitations?: Invitation[];
  device: Device;
}

interface State {
  deviceInvitations: Invitation[];
}

export default class DeviceGuestUsers extends Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = {
      deviceInvitations: [],
    };
  }

  private statusTranslation: Record<ResponseStatus, string> = {
    [ResponseStatus.None]: translations.admin.texts.pending(),
    [ResponseStatus.Accepted]: translations.admin.texts.accepted(),
    [ResponseStatus.Declined]: translations.admin.texts.declined(),
  };

  private roleTranslation(role: string): string {
    switch (role.toLowerCase()) {
      case "owner":
        return translations.admin.texts.coOwner();
      case "guest":
        return translations.admin.texts.guest();
      default:
        return role;
    }
  }

  public async componentDidMount(): Promise<void> {
    this.updateDeviceInvitations();
  }

  public async componentDidUpdate(prevProps: Readonly<Props>): Promise<void> {
    if (prevProps.device.getId() !== this.props.device.getId() || prevProps.invitations !== this.props.invitations) {
      this.updateDeviceInvitations();
    }
  }

  private updateDeviceInvitations = (): void => {
    if (!this.props.invitations) return;
    const deviceInvitations = this.props.invitations.filter((invitation: Invitation) =>
      this.isDeviceInvitation(invitation, this.props.device)
    );
    this.setState({
      deviceInvitations,
    });
  };

  private isDeviceInvitation = (invitation: Invitation, device: Device): boolean => {
    return Boolean(invitation.devices.find((invitationDevice) => invitationDevice.id === device.getId()));
  };

  private getDeviceRole = (invitation: Invitation): string => {
    const [invitationObject] = invitation.devices
      .map((invitationObject) => invitationObject)
      .filter((invitationObject: InvitationObject) => invitationObject.id === this.props.device.getId());
    return invitationObject ? invitationObject.accessType : "";
  };

  private renderHeader(): ReactNode {
    return (
      <Grid container spacing={1} className="overview-header-grid">
        <Grid item>{translations.admin.texts.invitedUsers()}</Grid>
      </Grid>
    );
  }

  private renderGuestUsers(): ReactNode {
    if (!this.props.invitations) return null;
    if (this.state.deviceInvitations.length === 0)
      return (
        <Grid container className="overview-content-grid">
          <Grid item className="no-content-grid">
            {translations.admin.texts.noInvitedUsers()}
          </Grid>
        </Grid>
      );
    return (
      <Grid container className="overview-content-grid">
        <Grid item xs={4}>
          <List>
            <ListItem style={{ fontWeight: "bold" }}>{translations.admin.texts.emailAddress().toUpperCase()}</ListItem>
            {this.state.deviceInvitations.map((invitation) => (
              <ListItem key={invitation.id} className="list-item">
                {invitation.receiver.email}
              </ListItem>
            ))}
          </List>
        </Grid>
        <Grid item xs={2}>
          <List>
            <ListItem style={{ fontWeight: "bold" }}>{translations.admin.texts.role().toUpperCase()}</ListItem>
            {this.state.deviceInvitations.map((invitation) => (
              <ListItem key={invitation.id} className="list-item">
                {this.roleTranslation(this.getDeviceRole(invitation))}
              </ListItem>
            ))}
          </List>
        </Grid>
        <Grid item xs={3}>
          <List>
            <ListItem style={{ fontWeight: "bold" }}>
              {translations.admin.texts.responseStatus().toUpperCase()}
            </ListItem>
            {this.state.deviceInvitations.map((invitation) => (
              <ListItem key={invitation.id} className="list-item">
                {this.statusTranslation[invitation.responseStatus]}
              </ListItem>
            ))}
          </List>
        </Grid>
        <Grid item xs={3}>
          <List>
            <ListItem style={{ fontWeight: "bold" }}>{translations.admin.texts.responseTime().toUpperCase()}</ListItem>
            {this.state.deviceInvitations.map((invitation) => (
              <ListItem key={invitation.id} className="list-item">
                {invitation.responseDate?.toLocaleString()}
              </ListItem>
            ))}
          </List>
        </Grid>
      </Grid>
    );
  }

  public render(): ReactNode {
    return (
      <Fragment>
        {this.renderHeader()}
        {this.renderGuestUsers()}
      </Fragment>
    );
  }
}
