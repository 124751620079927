/*
 * Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */
import { GateUnitHWStateProperties } from "../GateUnitHW";
export class Mms100HWStateProperties extends GateUnitHWStateProperties {
    constructor(properties) {
        var _a;
        super(properties);
        this.powerLevel = (_a = properties.powerLevel) !== null && _a !== void 0 ? _a : null;
    }
}
