/*
 * Copyright (C) 2022 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */
import { __awaiter } from "tslib";
import { AppSyncClientFactory } from "../backend/AppSyncClientFactory";
import { Service } from "../backend/AppSyncClientProvider";
import { eventIdentitiesMatch } from "./Event";
import { EventSet } from "./EventSet";
import { EventsSubscriptionManager } from "./EventsSubscriptionManager";
import { isDefined, toNumber } from "../../common/Utils";
import { DevicesEventsListDocument, EventsDeactivateDocument, EventState } from "../../generated/gqlEvents";
export class AWSEventSet extends EventSet {
    constructor(deviceId, startTimestamp, endTimestamp) {
        super();
        this.changeTimePeriod = (startTimestamp, endTimestamp) => {
            this.period = { startTimestamp, endTimestamp };
            this.events = [];
        };
        this.deviceId = deviceId;
        this.period = { startTimestamp, endTimestamp };
        this.events = [];
    }
    fetch() {
        var _a, _b, _c, _d, _e;
        return __awaiter(this, void 0, void 0, function* () {
            let nextToken = null;
            let events = [];
            try {
                do {
                    const client = AppSyncClientFactory.createProvider().getTypedClient(Service.EVENTS);
                    const deviceEventsResponse = yield client.query(DevicesEventsListDocument, {
                        deviceId: this.deviceId,
                        period: {
                            endTimestamp: this.period.endTimestamp.toString(),
                            startTimestamp: this.period.startTimestamp.toString(),
                        },
                        nextToken,
                    }, 
                    // mobile-app specific: Force fetch or will return old data from cache
                    {
                        fetchPolicy: "network-only",
                    });
                    // cast is required or response's type inference goes into a loop
                    nextToken = ((_b = (_a = deviceEventsResponse.data.devicesEventsList) === null || _a === void 0 ? void 0 : _a.nextToken) !== null && _b !== void 0 ? _b : null);
                    events = events.concat((_e = (_d = (_c = deviceEventsResponse.data.devicesEventsList) === null || _c === void 0 ? void 0 : _c.events) === null || _d === void 0 ? void 0 : _d.filter(isDefined)) !== null && _e !== void 0 ? _e : []);
                } while (nextToken);
                this.events = events.sort(EventSet.eventOrdering);
            }
            catch (error) {
                console.error("Error", error);
                this.events = [];
            }
        });
    }
    getId() {
        return this.deviceId;
    }
    getData() {
        return [...this.events];
    }
    getTimePeriod() {
        return this.period;
    }
    addOrUpdateEvent(event) {
        const matchIndex = this.events.findIndex((e) => eventIdentitiesMatch(event, e));
        if (matchIndex >= 0) {
            this.events[matchIndex] = event;
        }
        else {
            this.addEvent(event);
        }
        this.notifyAction((observer) => observer.onEventSetUpdate(this));
    }
    deactivateEvent(event) {
        return __awaiter(this, void 0, void 0, function* () {
            // Set inactive for fast UI update. State will recover via subs if deactivation fails in cloud side
            event.eventState = EventState.Inactive;
            const eventPayload = {
                deviceId: event.deviceId,
                timestamp: event.timestamp,
            };
            try {
                const client = AppSyncClientFactory.createProvider().getTypedClient(Service.EVENTS);
                yield client.mutate(EventsDeactivateDocument, {
                    payload: eventPayload,
                });
            }
            catch (error) {
                console.log(error);
            }
        });
    }
    addObserver(observer) {
        const _super = Object.create(null, {
            addObserver: { get: () => super.addObserver }
        });
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.events) {
                yield this.fetch();
            }
            _super.addObserver.call(this, observer);
            EventsSubscriptionManager.instance.addListener(this);
        });
    }
    removeObserver(observer) {
        super.removeObserver(observer);
        EventsSubscriptionManager.instance.removeListener(this);
    }
    addEvent(event) {
        this.events.push(event);
        this.events.sort(EventSet.eventOrdering);
        const timestamp = toNumber(event.timestamp);
        if (timestamp && timestamp > this.period.endTimestamp) {
            this.period.endTimestamp = timestamp;
        }
    }
}
