/*
 * Copyright (C) 2023 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */

import React, { Component, Fragment, ReactNode } from "react";
import { Grid, Typography } from "@material-ui/core";
import { Organization } from "@sade/data-access";

import { OrganizationSearch } from "./organization-search";
import DashboardIcon from "@material-ui/icons/Dashboard";
import { translations } from "../../../generated/translationHelper";

interface Props {
  onOptionSelect: (option: Organization) => void;
}

export class LandingView extends Component<Props> {
  public constructor(props: Props) {
    super(props);
  }

  public render(): ReactNode {
    return (
      <Fragment>
        <Grid container justifyContent="center" className="landing-title-grid">
          <Grid item className="landing-icon-grid">
            <DashboardIcon className="landing-icon" />
          </Grid>
          <Grid item className="landing-text-grid">
            <Typography variant="h3">{translations.admin.texts.supportDashboard().toUpperCase()}</Typography>
          </Grid>
        </Grid>
        <Grid container justifyContent="center" className="landing-search-grid">
          <Grid item xs={4}>
            <OrganizationSearch onOptionSelect={this.props.onOptionSelect} />
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}
