/*
 * Copyright (C) 2022 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 *
 */
import { BaseObservable } from "../observer";
import { policyListContainsPermission } from "./Utils";
/**
 * Checks how many of coveree's policies are covered by coverer
 * @param coveree
 * @param coverer
 */
function permissionCoverage(coveree, coverer) {
    return coveree
        .getPolicies()
        .map((policy) => policyListContainsPermission(coverer.getPolicies(), policy))
        .reduce((acc, contained) => acc + (contained ? 1 : 0), 0);
}
/**
 * Calculates a permission score for policy group. Bigger number => more permissions.
 * This is based on heuristics since we do not have a reliable way of telling how many permissions one wildcard covers.
 * @param group
 */
function permissionRank(group) {
    const WILDCARD_POINTS = 3;
    if (group.getPolicies().length === 0) {
        return Number.NEGATIVE_INFINITY;
    }
    if (group.getPolicies().includes("*")) {
        return Number.POSITIVE_INFINITY;
    }
    return group.getPolicies().reduce((acc, policy) => acc + (policy.indexOf("*") > -1 ? WILDCARD_POINTS : 1), 0);
}
export class PolicyGroup extends BaseObservable {
    constructor(parameters) {
        super();
        this.id = parameters.id;
        this.name = parameters.name;
        this.organizationId = parameters.organization;
        this.policies = [...parameters.policies];
    }
    /**
     * Compares two policy groups by their policies, sorting ones with LESS permissions first:
     * given groups A and B, where A has less permissions than B, compareByPolicy(A,B) == -1
     *
     * @param policyGroup1
     * @param policyGroup2
     */
    static compareByPolicies(policyGroup1, policyGroup2) {
        // calculates how many of pg1's policies are covered by pg2's policies
        // the larger the number, the more permissions the _other_ group has
        const pg1Coverage = permissionCoverage(policyGroup1, policyGroup2);
        const pg2Coverage = permissionCoverage(policyGroup2, policyGroup1);
        if (pg1Coverage === pg2Coverage) {
            // perform crude rank heuristic, since we do not know how many permissions something* covers
            const pg1Rank = permissionRank(policyGroup1);
            const pg2Rank = permissionRank(policyGroup2);
            return pg1Rank === pg2Rank ? 0 : pg1Rank < pg2Rank ? -1 : 1;
        }
        else {
            return pg1Coverage > pg2Coverage ? -1 : 1;
        }
    }
    getId() {
        return this.id;
    }
    getName() {
        return this.name;
    }
    getPolicies() {
        return this.policies;
    }
    getOrganizationId() {
        return this.organizationId;
    }
}
