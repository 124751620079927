/*
 * Copyright (C) 2022 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */
import { Service } from "../backend/AppSyncClientProvider";
import { DataUtil } from "./Data";
import { DevicesMeasurementsUpdateFeedDocument, } from "../../generated/gqlData";
import { AbstractMapSubscriptionManager } from "../utils/subscriptions/AbstractMapSubscriptionManager";
// REFACTOR: Could LatestDataSubscriptionManager and DataSubscriptionManager be
// combined through Generics?
export class DataSubscriptionManager extends AbstractMapSubscriptionManager {
    constructor() {
        super(Service.DATA, DevicesMeasurementsUpdateFeedDocument);
    }
    static get instance() {
        return this.__instance;
    }
    subscriptionHandler(result) {
        var _a;
        if (!(result === null || result === void 0 ? void 0 : result.devicesMeasurementsUpdateFeed))
            return;
        const dataItem = DataUtil.parseDataFragment(result.devicesMeasurementsUpdateFeed.item);
        if (dataItem) {
            (_a = this.getListener(dataItem.deviceId)) === null || _a === void 0 ? void 0 : _a.appendData(dataItem);
        }
    }
}
DataSubscriptionManager.__instance = new DataSubscriptionManager();
