/*
 * Copyright (C) 2023 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */
/**
 * Returns the major and minor versions we expect the Backend have.
 *
 * These need to be updated, when we make changes to the Backend and reflect those changes here.
 */
export function getBackendCompatibilityVersion() {
    return {
        major: 2,
        minor: 43,
    };
}
