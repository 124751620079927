/*
 * Copyright (C) 2022 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */
import { Service } from "../backend/AppSyncClientProvider";
import { DevicesStatesUpdateFeedDocument } from "../../generated/gqlDevice";
import { AbstractMapSubscriptionManager } from "../utils/subscriptions/AbstractMapSubscriptionManager";
export class ShadowSubscriptionManager extends AbstractMapSubscriptionManager {
    constructor() {
        super(Service.DEVICE, DevicesStatesUpdateFeedDocument);
    }
    static get instance() {
        return this.__instance;
    }
    subscriptionHandler(result) {
        var _a, _b, _c;
        const { deviceId, reported, desired, timestamp, version, connectionState } = (_b = (_a = result === null || result === void 0 ? void 0 : result.devicesStatesUpdateFeed) === null || _a === void 0 ? void 0 : _a.item) !== null && _b !== void 0 ? _b : {};
        if (deviceId) {
            const reportedProps = reported ? JSON.parse(reported) : undefined;
            const desiredProps = desired ? JSON.parse(desired) : undefined;
            (_c = this.getListener(deviceId)) === null || _c === void 0 ? void 0 : _c.setState(timestamp !== null && timestamp !== void 0 ? timestamp : undefined, version !== null && version !== void 0 ? version : undefined, reportedProps, desiredProps, connectionState
                ? { connected: connectionState.connected, updatedTimestamp: connectionState.updatedTimestamp }
                : undefined);
        }
    }
}
ShadowSubscriptionManager.__instance = new ShadowSubscriptionManager();
