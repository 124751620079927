/*
 * Copyright (C) 2022 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */
import { __awaiter } from "tslib";
export default class ReceiverObserverSubscription {
    constructor(clientProvider, document, handler) {
        var _a, _b;
        this.clientProvider = clientProvider;
        this.document = document;
        this.handler = handler;
        this.subscriptions = new Map();
        this.operationName = (_b = (_a = document.definitions.find((def) => def.kind === "OperationDefinition")) === null || _a === void 0 ? void 0 : _a.name) === null || _b === void 0 ? void 0 : _b.value;
        console.log("Created ReceiverObserverSubscription for " + this.operationName);
    }
    get knownReceivers() {
        return [...this.subscriptions.keys()];
    }
    terminateSubscriptions() {
        this.knownReceivers.forEach((receiver) => this.removeSubscription(receiver));
    }
    onReceiversChanged(newReceivers) {
        this.knownReceivers
            .filter((oldReceiver) => !newReceivers.includes(oldReceiver))
            .forEach((receiver) => this.removeSubscription(receiver));
        newReceivers
            .filter((receiver) => !this.subscriptions.has(receiver))
            .forEach((receiver) => this.subscribe(receiver));
    }
    subscribe(receiver) {
        if (this.subscriptions.has(receiver)) {
            return;
        }
        console.log(`${this.operationName} subscribing to ${receiver}`);
        const client = this.clientProvider();
        const subscription = client
            .subscribe(this.document, {
            receiver,
        })
            .subscribe({
            // TODO: Fix any type
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            error: (error) => {
                if (error.errorMessage === "AMQJS0008I Socket closed.") {
                    console.trace("Socket closed, resubscribing");
                    this.reSubscribe(receiver);
                }
                else if (error.message === "Connection failed: WebSocket is not defined") {
                    console.error("Received subscription error", error);
                }
            },
            next: (update) => __awaiter(this, void 0, void 0, function* () {
                var _a;
                try {
                    this.handler((_a = update.data) !== null && _a !== void 0 ? _a : undefined);
                }
                catch (err) {
                    console.error("subscription handler leaks errors, unsubscribing handler", err);
                    this.removeSubscription(receiver);
                }
            }),
        });
        this.subscriptions.set(receiver, subscription);
    }
    removeSubscription(receiver) {
        var _a;
        (_a = this.subscriptions.get(receiver)) === null || _a === void 0 ? void 0 : _a.unsubscribe();
        this.subscriptions.delete(receiver);
    }
    reSubscribe(receiver) {
        this.removeSubscription(receiver);
        this.subscribe(receiver);
    }
}
