/*
 * Copyright (C) 2023 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */

import React, { Component, ReactNode } from "react";
import { List, ListItem, ListItemText } from "@material-ui/core";
import { Organization, BackendFactory, Device } from "@sade/data-access";
import { SearchOption, getOrganizationId } from "./search-option";

interface Props {
  option: SearchOption;
}

interface State {
  optionOrganization?: Organization;
}

export class OptionRenderer extends Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = {};
  }

  public async componentDidMount(): Promise<void> {
    const organizationId = getOrganizationId(this.props.option);
    if (organizationId) {
      const organization = await BackendFactory.getOrganizationBackend().getOrganization(organizationId);
      this.setState({ optionOrganization: organization });
    }
  }

  private getLabel(option: SearchOption): string {
    if (option instanceof Device) {
      return option.getId();
    } else {
      return option.getUsername();
    }
  }

  public render(): ReactNode {
    return (
      <List disablePadding={true}>
        <ListItem divider={true} disableGutters={true}>
          <ListItemText
            primary={this.state.optionOrganization?.getName()}
            secondary={this.getLabel(this.props.option)}
          />
        </ListItem>
      </List>
    );
  }
}
