/*
 * Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 *
 */
export const toBoolean = (str) => Boolean(Number(str));
export const booleanToString = (b) => (b ? "1" : "0");
export const toNumber = (str) => Number(str);
export const numberToString = (n) => n.toString(10);
export const parseStatusFromString = (value, fields, parser, size) => {
    const values = value.split(",");
    if (values.length !== size) {
        throw Error(`String length is not correct. The length should be ${size}`);
    }
    return values.reduce((prevValue, currentValue, currentIndex) => {
        const name = fields[currentIndex];
        const result = parser[name](currentValue);
        if (result == null)
            throw new Error(`no conversion function for ${String(name)} with value ${result}`);
        prevValue[name] = result;
        return prevValue;
    }, {});
};
export const parseStatusToString = (status, fields, parser) => {
    return fields
        .map((fieldName) => {
        const value = status[fieldName];
        const ret = parser[fieldName](value);
        if (ret == null)
            throw new Error(`no conversion function for ${String(fieldName)} with value ${value}`);
        return ret;
    })
        .join(",");
};
