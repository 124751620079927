/*
 * Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 *
 */

import React, { Component, ReactNode } from "react";
import { OtaManager, OtaUpdate } from "@sade/data-access";
import { translations } from "../../../../generated/translationHelper";
import OtaPackageInformationItem from "../../../device-settings/components/settings-page-ota/ota-package-information-item";
import FormDialog from "../../../ui/form-dialog";
import { Maybe } from "@sade/data-access/lib/common/Utils";
import { semverishCompare } from "../../../device-settings/components/settings-page-ota/semverCompare";

interface Props {
  type: string;
  open: boolean;
  onClose: (selection: Maybe<OtaUpdate>) => void;
}

interface State {
  selectedOtaPackage?: OtaUpdate;
  availableUpdates: OtaUpdate[];
}

export default class SelectOtaUpdatePopup extends Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = {
      availableUpdates: [],
    };
  }

  public componentDidMount(): void {
    this.updateAvailableOTAs(true).then();
  }

  public componentDidUpdate(prevProps: Readonly<Props>): void {
    if (prevProps.type !== this.props.type) {
      this.updateAvailableOTAs(true);
    }
    console.log("updated");
  }

  private updateAvailableOTAs = async (force?: boolean): Promise<void> => {
    const updates = await OtaManager.getInstance().getOtaUpdates(this.props.type, force, true);
    console.log(JSON.stringify(updates));
    console.log("otas updated " + updates.length);
    this.setState({
      availableUpdates: updates
        .filter((update: OtaUpdate) => update.enabled)
        .sort((a, b) => semverishCompare(b.firmwareVersion, a.firmwareVersion)),
    });
  };

  private handleSelectOta = (id: OtaUpdate): void => {
    this.setState({ selectedOtaPackage: id });
  };

  private firmwareSelected = async (): Promise<void> => {
    this.props.onClose(this.state.selectedOtaPackage);
  };

  private renderUpdatePackageListing(): ReactNode {
    if (!this.state.availableUpdates || this.state.availableUpdates.length === 0) {
      return <p>{translations.deviceSettings.texts.noUpdateAvailable()}</p>;
    }
    // force contents to be scrollable - otherwise the whole popup will be scrollable.
    return (
      <div style={{ height: "300px", overflowY: "auto", borderRadius: "1px" }}>
        {this.state.availableUpdates.map((update: OtaUpdate): JSX.Element => {
          console.log("update ota list");
          return (
            <OtaPackageInformationItem
              key={update.otaId}
              item={update}
              selected={this.state.selectedOtaPackage?.otaId === update.otaId}
              onClick={(): void => this.handleSelectOta(update)}
            />
          );
        })}
      </div>
    );
  }

  public render(): ReactNode {
    return (
      <FormDialog
        title={translations.admin.texts.selectOtaUpdate()}
        acceptButtonText={translations.common.buttons.confirm()}
        isOpen={this.props.open}
        onAccept={this.firmwareSelected}
        onCancel={(): void => this.props.onClose(undefined)}
        disableAccept={!this.state.selectedOtaPackage}
      >
        {this.renderUpdatePackageListing()}
      </FormDialog>
    );
  }
}
