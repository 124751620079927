/*
 * Copyright (C) 2022 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */
import { __awaiter } from "tslib";
import { AppSyncClientFactory } from "../backend/AppSyncClientFactory";
import { Service } from "../backend/AppSyncClientProvider";
import { SessionSet } from "./SessionSet";
import { isDefined } from "../../common/Utils";
import { DevicesSessionsListDocument } from "../../generated/gqlData";
export class AWSSessionSet extends SessionSet {
    constructor(deviceId, startTimestamp, endTimestamp) {
        super();
        this.deviceId = deviceId;
        this.startTimestamp = startTimestamp;
        this.endTimestamp = endTimestamp;
        this.data = [];
    }
    getId() {
        return this.deviceId;
    }
    getSessions() {
        return this.data;
    }
    fetch() {
        var _a, _b, _c, _d;
        return __awaiter(this, void 0, void 0, function* () {
            try {
                let nextToken = null;
                let sessions = [];
                const appSyncClient = AppSyncClientFactory.createProvider().getTypedClient(Service.DATA);
                do {
                    const response = yield appSyncClient.query(DevicesSessionsListDocument, {
                        deviceId: this.deviceId,
                        startTimestamp: this.startTimestamp.toString(),
                        endTimestamp: this.endTimestamp.toString(),
                        nextToken,
                    });
                    // cast required to avoid cyclic type inference for response
                    nextToken = ((_b = (_a = response.data.devicesSessionsList) === null || _a === void 0 ? void 0 : _a.nextToken) !== null && _b !== void 0 ? _b : null);
                    const convertedSessions = (_d = (_c = response.data.devicesSessionsList) === null || _c === void 0 ? void 0 : _c.sessions) === null || _d === void 0 ? void 0 : _d.filter(isDefined).map((fragment) => {
                        var _a;
                        return ({
                            deviceId: fragment.deviceId,
                            sessionId: fragment.sessionId,
                            durationMs: (_a = fragment.durationMs) !== null && _a !== void 0 ? _a : 0,
                        });
                    });
                    sessions = sessions.concat(convertedSessions !== null && convertedSessions !== void 0 ? convertedSessions : []);
                } while (nextToken);
                this.data = sessions;
            }
            catch (error) {
                console.error("Error", error);
            }
        });
    }
    setData(data) {
        this.data.push(data);
    }
}
