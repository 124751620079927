/*
 * Copyright (C) 2022 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 *
 */
import { Mms100HW, WallStationHW, WallStationESP32HW, MMS100ESP32HW } from "./devices";
const DEVICE_CONSTRUCTORS = {
    [Mms100HW.type]: Mms100HW,
    [WallStationHW.type]: WallStationHW,
    [MMS100ESP32HW.type]: MMS100ESP32HW,
    [WallStationESP32HW.type]: WallStationESP32HW,
};
export class DeviceFactoryImplementation {
    createDevice(backend, type, params) {
        const ctor = DEVICE_CONSTRUCTORS[type];
        if (ctor) {
            return new ctor(backend, params);
        }
        else {
            console.error(`Could not locate device implementation for type ${type}`);
        }
    }
    listDeviceTypes() {
        return Object.keys(DEVICE_CONSTRUCTORS);
    }
}
