/*
 * Copyright (C) 2022 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */
import { __awaiter } from "tslib";
import { Amplify, Auth } from "aws-amplify";
import AWS from "aws-sdk";
import { AppConfiguration } from "."; // importing through index.ts avoids circular reference problems (ugly!)
import { isValidLanguageCode } from "../../common/Utils";
import { ReceiverManager } from "../utils";
const PHONE_NUMBER_REGEX = /\+[0-9]+/;
const ORG_ID_PREFIX = "ORG/";
const USER_ID_PREFIX = "USER/";
// the custom claims are set by preTokenGenerationHook in users-service (but many vtl files depend on these values)
const GRANTS_CLAIM = "custom:policies";
const HOME_CLAIM = "custom:home";
const ORGANIZATIONS_CLAIM = "custom:orgs";
const USER_ID_CLAIM = "sub";
// REFACTOR: Hard to unit test as dependencies are built in. Could use dependency injection.
// Usage of static methods can make mocking hard in classes that use AuthWrapper.
export class AuthWrapper {
    static configureAmplify() {
        Amplify.configure(AppConfiguration.getAwsConfiguration());
    }
    static logIn(email, password) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!password.length) {
                throw new Error("Empty password");
            }
            const result = yield Auth.signIn(email, password);
            return result;
        });
    }
    /**
     * Refreshes users authentication tokens, allowing user to benefit
     * from potential changes to their access.
     */
    static refreshAuthentication() {
        return __awaiter(this, void 0, void 0, function* () {
            const [currentUser, currentSession] = yield Promise.all([Auth.currentUserPoolUser(), Auth.currentSession()]);
            // Simple helper to get null check and type-coercion in one
            const isAuthenticatedUser = (user) => user != null;
            if (isAuthenticatedUser(currentUser) && currentSession) {
                // since the refreshSession is based on callback, we need to do some promise wrapping
                yield new Promise((resolve, reject) => {
                    currentUser.refreshSession(currentSession.getRefreshToken(), (err, result) => err ? reject(err) : resolve(result));
                });
                // resetting claim cache: getCurrentAuthenticatedUserClaims fills in the cache when called
                AuthWrapper.userClaims = undefined;
                // updating new organizations to the receiver manager
                yield ReceiverManager.instance.forceStandardReceiverRefresh();
            }
        });
    }
    static signUp(attributes, password) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            if (!((_a = attributes.email) === null || _a === void 0 ? void 0 : _a.length)) {
                throw new Error("Invalid email");
            }
            if (!password.length) {
                throw new Error("Empty password");
            }
            attributes.email = attributes.email.toLowerCase();
            return Auth.signUp({
                username: attributes.email,
                password,
                attributes,
            });
        });
    }
    static confirmSignUp(email, code) {
        return __awaiter(this, void 0, void 0, function* () {
            yield Auth.confirmSignUp(email, code);
        });
    }
    static resendConfirmationCode(email, forAttribute) {
        return __awaiter(this, void 0, void 0, function* () {
            if (forAttribute != null) {
                yield Auth.verifyCurrentUserAttribute(forAttribute);
            }
            else {
                yield Auth.resendSignUp(email);
            }
        });
    }
    static logOut(reason) {
        return __awaiter(this, void 0, void 0, function* () {
            AuthWrapper.logoutReason = reason;
            yield Auth.signOut();
            AuthWrapper.userClaims = undefined;
        });
    }
    static getLogoutReason() {
        return AuthWrapper.logoutReason;
    }
    static forgotPassword(email) {
        return __awaiter(this, void 0, void 0, function* () {
            return Auth.forgotPassword(email);
        });
    }
    static checkCodeAndSubmitNewPassword(email, code, newPassword) {
        return __awaiter(this, void 0, void 0, function* () {
            yield Auth.forgotPasswordSubmit(email, code, newPassword);
        });
    }
    static completeNewPassword(user, password) {
        return __awaiter(this, void 0, void 0, function* () {
            return Auth.completeNewPassword(user, password, {});
        });
    }
    static isCurrentUserAuthenticated(bypassCache) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const result = yield this.getCurrentAuthenticatedUser(bypassCache);
                return result !== undefined;
            }
            catch (_a) {
                return false;
            }
        });
    }
    static getCurrentAuthenticatedUser(bypassCache) {
        return __awaiter(this, void 0, void 0, function* () {
            return Auth.currentAuthenticatedUser({
                bypassCache: bypassCache !== null && bypassCache !== void 0 ? bypassCache : false,
            });
        });
    }
    static getCurrentAuthenticatedUserClaims() {
        var _a, _b, _c;
        return __awaiter(this, void 0, void 0, function* () {
            if (!AuthWrapper.userClaims) {
                const claims = (_c = (_b = (_a = (yield AuthWrapper.getCurrentAuthenticatedUser(false))) === null || _a === void 0 ? void 0 : _a.getSignInUserSession()) === null || _b === void 0 ? void 0 : _b.getIdToken()) === null || _c === void 0 ? void 0 : _c.decodePayload();
                if (!claims) {
                    console.error("Cannot retrieve claims for an unauthenticated user");
                    return;
                }
                const rawUserId = claims[USER_ID_CLAIM];
                const organizations = (claims[ORGANIZATIONS_CLAIM] ? JSON.parse(claims[ORGANIZATIONS_CLAIM]) : [])
                    // add organization prefix to IDs
                    .map((organization) => ORG_ID_PREFIX + organization);
                const grantsRaw = claims[GRANTS_CLAIM] ? JSON.parse(claims[GRANTS_CLAIM]) : {};
                AuthWrapper.userClaims = {
                    userId: USER_ID_PREFIX + rawUserId,
                    homeOrganizationId: ORG_ID_PREFIX + claims[HOME_CLAIM],
                    uniqueParentOrganizations: organizations,
                    // user id in canSee value does not have user prefix, but organizations have organization prefix
                    canSee: [rawUserId].concat(organizations),
                    // add organization prefix to raw id keys
                    grants: Object.entries(grantsRaw).reduce((acc, [key, value]) => {
                        acc[ORG_ID_PREFIX + key] = value;
                        return acc;
                    }, {}),
                };
            }
            return AuthWrapper.userClaims;
        });
    }
    static getCurrentAuthenticatedUsername() {
        return __awaiter(this, void 0, void 0, function* () {
            const username = yield this.getAttribute("username");
            if (!username) {
                // TODO:  fix return type (major change for internal APIs)
                //        we have to stop lying to ourselves!
                console.error("No username available!");
            }
            return username !== null && username !== void 0 ? username : "";
        });
    }
    static getEmail() {
        return __awaiter(this, void 0, void 0, function* () {
            const email = yield this.getAttribute("email");
            if (!email) {
                // TODO:  fix return type (major change for internal APIs)
                //        we have to stop lying to ourselves!
                console.error("No email available!");
            }
            return email !== null && email !== void 0 ? email : "";
        });
    }
    static getGivenName() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.getAttribute("given_name");
        });
    }
    static getFamilyName() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.getAttribute("family_name");
        });
    }
    static getPhoneNumber() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.getAttribute("phone_number");
        });
    }
    static getDealerName() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.getAttribute("custom:dealer_name");
        });
    }
    static getDealerCompanyName() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.getAttribute("custom:dealer_company_name");
        });
    }
    static getDealerAddress() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.getAttribute("custom:dealer_address");
        });
    }
    static getDealerPhone() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.getAttribute("custom:dealer_phone");
        });
    }
    static getDealerEmail() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.getAttribute("custom:dealer_email");
        });
    }
    static getLanguage() {
        return __awaiter(this, void 0, void 0, function* () {
            const attribute = yield this.getAttribute("custom:language");
            const languageCode = attribute === null || attribute === void 0 ? void 0 : attribute.toLowerCase();
            if (languageCode && isValidLanguageCode(languageCode))
                return languageCode;
            if (languageCode)
                console.error(`Language code ${languageCode} is not supported`);
            return undefined;
        });
    }
    static setLanguage(language) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.setAttributes({ "custom:language": language.toLowerCase() });
        });
    }
    //platform param should be the output of Platform.OS. string type used here to avoid a dependency to react native in this repo/NPM
    static getPushNotificationTokensAttribute(platform) {
        return __awaiter(this, void 0, void 0, function* () {
            const attribute = yield this.getAttribute(platform === "android" ? "custom:android_pn" : "custom:ios_pn");
            return attribute !== null && attribute !== void 0 ? attribute : ""; // TODO: why does this return an empty string id return type allows for `undefined`?
        });
    }
    static getRdUserFlag() {
        return __awaiter(this, void 0, void 0, function* () {
            const flag = yield this.getAttribute("custom:is_rd_user");
            return (flag === null || flag === void 0 ? void 0 : flag.toLowerCase()) === "true";
        });
    }
    static setName(firstname, lastname) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.setAttributes({ given_name: firstname, family_name: lastname });
        });
    }
    static setDealer(dealerName, dealerCompanyName, dealerAddress, dealerPhone, dealerEmail) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.setAttributes({
                "custom:dealer_name": dealerName,
                "custom:dealer_company_name": dealerCompanyName,
                "custom:dealer_address": dealerAddress,
                "custom:dealer_phone": dealerPhone,
                "custom:dealer_email": dealerEmail,
            });
        });
    }
    /**
     * Sets user's phone number and returns an {@link AttributeVerificationCB} object for entering the verification code.
     *
     * @param number
     *  International phone number
     * @see verifyPhoneNumber
     * @throws if invalid phone number
     */
    static setPhoneNumber(number) {
        return __awaiter(this, void 0, void 0, function* () {
            const phone_number = number.trim();
            if (!PHONE_NUMBER_REGEX.test(phone_number))
                throw new Error("Invalid phone number");
            yield this.setAttributes({ phone_number });
            return (code) => AuthWrapper.verifyPhoneNumber(code);
        });
    }
    static verifyPhoneNumber(code) {
        return __awaiter(this, void 0, void 0, function* () {
            yield Auth.verifyCurrentUserAttributeSubmit("phone_number", code);
        });
    }
    /**
     * Sets user's email address and returns an {@link AttributeVerificationCB} object for entering the verification code.
     *
     * @param email
     *  International phone number
     * @see verifyEmail
     * @throws if invalid email address
     */
    static setEmail(email) {
        return __awaiter(this, void 0, void 0, function* () {
            const addr = email.trim();
            if (!addr.includes("@"))
                throw new Error("Invalid email address");
            yield this.setAttributes({ email: addr });
            return (code) => AuthWrapper.verifyEmail(code);
        });
    }
    static verifyEmail(code) {
        return __awaiter(this, void 0, void 0, function* () {
            yield Auth.verifyCurrentUserAttributeSubmit("email", code);
        });
    }
    //platform param should be the output of Platform.OS. string type used here to avoid a dependency to react native in this repo/NPM
    static setPushNotificationTokens(tokens, platform) {
        return __awaiter(this, void 0, void 0, function* () {
            console.log(`setPushNotificationTokens ${tokens}`);
            switch (platform) {
                case "android":
                    return this.setAttributes({ "custom:android_pn": tokens });
                case "ios":
                    return this.setAttributes({ "custom:ios_pn": tokens });
            }
        });
    }
    static submitNewPassword(oldPassword, newPassword) {
        return __awaiter(this, void 0, void 0, function* () {
            const loggedInUser = yield Auth.currentAuthenticatedUser();
            yield Auth.changePassword(loggedInUser, oldPassword, newPassword);
        });
    }
    static getOpenIdToken() {
        return __awaiter(this, void 0, void 0, function* () {
            const currentSession = yield Auth.currentSession();
            const awsConfig = AppConfiguration.getAwsConfiguration();
            const cognitoAuthenticatedLoginsKey = `cognito-idp.${awsConfig.Auth.region}.amazonaws.com/${awsConfig.Auth.userPoolId}`;
            const cognitoAuthenticatedLogins = { [cognitoAuthenticatedLoginsKey]: currentSession.getIdToken().getJwtToken() };
            if (!awsConfig.Auth.identityPoolId) {
                console.error("No identity pool id available");
                return;
            }
            const getIdParams = {
                IdentityPoolId: awsConfig.Auth.identityPoolId,
                Logins: cognitoAuthenticatedLogins,
            };
            if (!AWS.config.region) {
                AWS.config.update({
                    region: awsConfig.Auth.region,
                });
            }
            const cognitoIdentity = new AWS.CognitoIdentity();
            try {
                const data = yield cognitoIdentity.getId(getIdParams).promise();
                if (!data.IdentityId) {
                    console.error("No identity id available");
                    return;
                }
                const getOpenIdParams = {
                    IdentityId: data.IdentityId,
                    Logins: cognitoAuthenticatedLogins,
                };
                const response = yield cognitoIdentity
                    .getOpenIdToken(getOpenIdParams)
                    .promise();
                return response.Token;
            }
            catch (error) {
                console.error("getOpenIdToken", error);
            }
        });
    }
    static setAttributes(attributes) {
        return __awaiter(this, void 0, void 0, function* () {
            const loggedInUser = yield Auth.currentAuthenticatedUser();
            yield Auth.updateUserAttributes(loggedInUser, Object.assign({}, attributes));
        });
    }
    static getAttribute(key) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            const userInfo = yield Auth.currentUserInfo();
            if (!userInfo) {
                console.log("No user info found, signing out");
                yield Auth.signOut();
            }
            else
                return (_a = userInfo.attributes) === null || _a === void 0 ? void 0 : _a[key];
        });
    }
}
