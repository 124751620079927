/*
 * Copyright (C) 2022 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */
import { __awaiter } from "tslib";
import { BaseObservable } from "../observer";
import { AuthWrapper } from "../auth";
export class User extends BaseObservable {
    constructor(parameters) {
        super();
        this.id = parameters.id;
        this.email = parameters.email;
        this.policies = parameters.policies;
        this.displayName = parameters.displayName;
        if (typeof parameters.homeOrganization === "string") {
            this.homeOrganizationId = parameters.homeOrganization;
        }
        else {
            this.homeOrganizationId = parameters.homeOrganization.getId();
            this.homeOrganization = parameters.homeOrganization;
        }
    }
    static alphabeticUserOrdering(a, b) {
        return a.getUsername().localeCompare(b.getUsername());
    }
    getId() {
        return this.id;
    }
    getUsername() {
        return this.email;
    }
    getDisplayName() {
        return this.displayName;
    }
    /**
     * Resolves a printable name for the user in priority order:
     * - display name
     * - username
     */
    printName() {
        var _a;
        return (_a = this.displayName) !== null && _a !== void 0 ? _a : this.email;
    }
    getHomeOrganizationId() {
        return this.homeOrganizationId;
    }
    getPolicies() {
        return this.policies;
    }
    isCurrentUser() {
        return __awaiter(this, void 0, void 0, function* () {
            const claims = yield AuthWrapper.getCurrentAuthenticatedUserClaims();
            return this.id === (claims === null || claims === void 0 ? void 0 : claims.userId);
        });
    }
}
