/*
 * Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */

import { colors } from "@material-ui/core";
import { PaletteOptions } from "@material-ui/core/styles/createPalette";

const white = "#ffffff";

const lightPalette: PaletteOptions = {
  background: {
    default: white,
    paper: colors.grey[50],
  },
  primary: colors.blue,
  secondary: colors.lightBlue,
  error: colors.red,
  warning: colors.deepOrange,
  info: colors.cyan,
  success: colors.green,
  text: {
    disabled: colors.grey[300],
    hint: colors.cyan[500],
    primary: colors.grey[900],
    secondary: colors.grey[700],
  },
  tonalOffset: 0.1,
  type: "light",
};

// TODO: this does not work at all since the theming has so much custom coloring going on
const darkPalette: PaletteOptions = {
  background: {
    default: colors.grey[900],
    paper: colors.grey[800],
  },
  primary: colors.blue,
  secondary: colors.lightBlue,
  error: colors.red,
  warning: colors.deepOrange,
  info: colors.cyan,
  success: colors.green,
  text: {
    disabled: colors.grey[700],
    hint: colors.cyan[400],
    primary: colors.grey[50],
    secondary: colors.grey[200],
  },
  tonalOffset: 0.1,
  type: "dark",
};

const AppPalette = {
  lightPalette,
  darkPalette,
};

export default AppPalette;
