/*
 * Copyright (C) 2022 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */
import { AbstractSubscriptionManager } from "./AbstractSubscriptionManager";
export class AbstractMapSubscriptionManager extends AbstractSubscriptionManager {
    constructor() {
        super(...arguments);
        this.listenerMap = new Map();
    }
    addListener(listener) {
        const id = listener.getId();
        if (!this.listenerMap.has(id)) {
            this.listenerMap.set(id, listener);
            this.subscribeOnce();
        }
    }
    removeListener(listener) {
        this.listenerMap.delete(listener.getId());
        if (this.listenerMap.size === 0) {
            this.unsubscribe();
        }
    }
    refreshSubscriptions() {
        if (this.listenerMap.size > 0) {
            this.unsubscribe();
            this.subscribeOnce();
        }
    }
    getListener(id) {
        return this.listenerMap.get(id);
    }
}
