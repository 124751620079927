/*
 * Copyright (C) 2022 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */
import SubscriptionControllerFactory from "../../SubscriptionControllerFactory";
export class AbstractSubscriptionManager {
    constructor(service, document) {
        this.service = service;
        this.document = document;
    }
    subscribeOnce() {
        if (!this.subscriptionController) {
            this.subscriptionController = SubscriptionControllerFactory.getController(this.service, this.document, this.subscriptionHandler.bind(this));
        }
    }
    unsubscribe() {
        var _a;
        (_a = this.subscriptionController) === null || _a === void 0 ? void 0 : _a.terminateSubscription();
        this.subscriptionController = undefined;
    }
}
