/*
 * Copyright (C) 2023 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */

import { Device, User } from "@sade/data-access";

export type SearchOption = Device | User;

export function getOrganizationId(option: SearchOption): string | undefined {
  let organizationId: string | undefined = undefined;
  if (option instanceof Device) {
    organizationId = option.getAttribute("organization");
  } else if (option instanceof User) {
    organizationId = option.getHomeOrganizationId();
  }
  return organizationId;
}
