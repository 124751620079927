/*
 * Copyright (C) 2022 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */
import { __awaiter } from "tslib";
import { AWSUser } from "./AWSUser";
import { Organization, } from "./Organization";
import { User } from "./User";
import { AppSyncClientFactory, Service } from "../backend";
import { OrganizationsDeleteDocument, OrganizationsOrganizationsListDocument, OrganizationsPolicyGroupsListDocument, OrganizationsUpdateDocument, OrganizationsUsersListDocument, OrganizationsUsersRemoveDocument, ResultType, } from "../../generated/gqlUsers";
import { verifyUserType } from "./AWSTypeUtils";
import { AWSPolicyGroup } from "./AWSPolicyGroup";
import { PromiseSemaphore, throwGQLError } from "../utils";
export class AWSOrganization extends Organization {
    constructor(backend, parameters) {
        super(parameters);
        this.backend = backend;
        this.entityType = AWSOrganization;
        this.usersSemaphore = new PromiseSemaphore(() => this.backendFetchUsers());
        this.policyGroupSemaphore = new PromiseSemaphore(() => this.backendFetchPolicyGroups());
        this.childrenSemaphore = new PromiseSemaphore(() => this.backendFetchChildOrganizations());
    }
    static instanceOf(value) {
        return value instanceof AWSOrganization;
    }
    changeName(name) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.backendChangeName(name);
            this.name = name;
            this.notifyAction((observer) => { var _a; return (_a = observer.onNameChange) === null || _a === void 0 ? void 0 : _a.call(observer, this); });
        });
    }
    getUsers() {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.usersSemaphore.guard();
            const result = this.backend.entityRelationCache.listFor(this, AWSUser);
            result.sort(User.alphabeticUserOrdering);
            return result;
        });
    }
    createOrganization(parameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const newOrganization = yield this.backend.createOrganization(this, parameters);
            yield this.getChildOrganizations();
            this.backend.entityRelationCache.link(this, newOrganization);
            return newOrganization;
        });
    }
    getChildOrganizations() {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.childrenSemaphore.guard();
            return (this.backend.entityRelationCache
                .listFor(this, AWSOrganization)
                // the relationship set will include parent node, so that needs to be removed
                .filter((potentialChild) => potentialChild.id.startsWith(this.id)));
        });
    }
    getParentOrganization() {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.parentId) {
                return this.backend.getOrganization(this.parentId);
            }
        });
    }
    createUser(parameters) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const newUser = yield this.backend.createUser(this, parameters);
                this.backend.entityRelationCache.link(this, newUser);
                return newUser;
            }
            catch (error) {
                console.error("Failed to create new user: " + error);
                throw error;
            }
        });
    }
    addUser(user) {
        verifyUserType(user);
        throw new Error("Method not implemented.");
    }
    removeUser(user) {
        return __awaiter(this, void 0, void 0, function* () {
            verifyUserType(user);
            const removed = yield this.backendRemoveUser(user.getId());
            if (removed) {
                this.backend.entityRelationCache.unlink(this, user);
            }
        });
    }
    getPolicyGroups() {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.policyGroupSemaphore.guard();
            return this.backend.entityRelationCache.listFor(this, AWSPolicyGroup);
        });
    }
    delete() {
        var _a, _b, _c, _d, _e;
        return __awaiter(this, void 0, void 0, function* () {
            const client = AppSyncClientFactory.createProvider().getTypedClient(Service.USERS);
            const response = yield client.mutate(OrganizationsDeleteDocument, {
                organizationId: this.id,
            });
            if (((_b = (_a = response.data) === null || _a === void 0 ? void 0 : _a.organizationsDelete) === null || _b === void 0 ? void 0 : _b.result) !== ResultType.Ok) {
                throw new Error((_e = (_d = (_c = response.data) === null || _c === void 0 ? void 0 : _c.organizationsDelete) === null || _d === void 0 ? void 0 : _d.failureReason) !== null && _e !== void 0 ? _e : "Failed to delete organization");
            }
            yield this.backend.cleanEntityFromCaches(this.id);
            this.notifyAction((observer) => { var _a; return (_a = observer.onDeleted) === null || _a === void 0 ? void 0 : _a.call(observer, this); });
            this.clearObservers();
        });
    }
    onRelationChange(change) {
        return __awaiter(this, void 0, void 0, function* () {
            // using semaphore.invoked() to check if some external entity is interested in the particular updates
            // otherwise we'd end up greedily loading resources (such as the whole organization tree)
            if (change.ofType(AWSOrganization) && this.childrenSemaphore.invoked()) {
                const children = yield this.getChildOrganizations();
                this.notifyAction((observer) => { var _a; return (_a = observer.onChildrenChange) === null || _a === void 0 ? void 0 : _a.call(observer, children, this); });
            }
            else if (change.ofType(AWSUser) && this.usersSemaphore.invoked()) {
                const users = yield this.getUsers();
                this.notifyAction((observer) => { var _a; return (_a = observer.onUsersChange) === null || _a === void 0 ? void 0 : _a.call(observer, users, this); });
            }
            else if (change.ofType(AWSPolicyGroup) && this.policyGroupSemaphore.invoked()) {
                const groups = yield this.getPolicyGroups();
                this.notifyAction((observer) => { var _a; return (_a = observer.onPolicyGroupsChange) === null || _a === void 0 ? void 0 : _a.call(observer, groups, this); });
            }
        });
    }
    backendChangeName(name) {
        return __awaiter(this, void 0, void 0, function* () {
            const client = AppSyncClientFactory.createProvider().getTypedClient(Service.USERS);
            yield client.mutate(OrganizationsUpdateDocument, {
                payload: {
                    id: this.id,
                    name,
                },
            });
        });
    }
    backendFetchUsers() {
        return __awaiter(this, void 0, void 0, function* () {
            const client = AppSyncClientFactory.createProvider().getTypedClient(Service.USERS);
            const response = yield client.query(OrganizationsUsersListDocument, {
                organizationId: this.id,
                // TODO: token
            }, {
                fetchPolicy: "network-only",
            });
            if (!response.data.organizationsUsersList) {
                throwGQLError(response, "Failed to get organization's users");
            }
            // TODO:  in order to improve performance here, we need to decide what data should be duplicated
            //        from user to the organization-user link row, and use that to construct a simplified view to the user
            //        ... or we can just resolve the users on the service side, but that is as expensive as OrganizationUtils.
            //            maybe a little faster
            //        ... or we could add some smart indexes here and there for specific use-cases, such as this (although,
            //            custom indexing org-users relationship is pretty hard)
            //
            //        Currently, the backend does a lot of caching, so that helps with repeated requests
            const maybeUsers = yield Promise.all(response.data.organizationsUsersList.users.map((id) => this.backend.getUser(id)));
            const users = maybeUsers.filter(AWSUser.instanceOf);
            this.backend.entityRelationCache.replaceTypedLinks(this, AWSUser, users);
        });
    }
    backendFetchPolicyGroups() {
        return __awaiter(this, void 0, void 0, function* () {
            const client = AppSyncClientFactory.createProvider().getTypedClient(Service.USERS);
            const response = yield client.query(OrganizationsPolicyGroupsListDocument, {
                organizationId: this.id,
                // TODO: next token
            }, {
                fetchPolicy: "network-only",
            });
            if (!response.data.organizationsPolicyGroupsList) {
                throwGQLError(response, "Failed to get organization's policy groups");
            }
            // TODO: this is also very slow, much like backendGetUsers
            const maybePolicyGroups = yield Promise.all(response.data.organizationsPolicyGroupsList.groups.map((id) => this.backend.getPolicyGroup(id)));
            const policyGroups = maybePolicyGroups.filter(AWSPolicyGroup.instanceOf);
            this.backend.entityRelationCache.replaceTypedLinks(this, AWSPolicyGroup, policyGroups);
        });
    }
    backendFetchChildOrganizations() {
        return __awaiter(this, void 0, void 0, function* () {
            const client = AppSyncClientFactory.createProvider().getTypedClient(Service.USERS);
            const response = yield client.query(OrganizationsOrganizationsListDocument, {
                organizationId: this.id,
                // TODO: next token
            }, {
                fetchPolicy: "network-only",
            });
            if (!response.data.organizationsOrganizationsList) {
                throwGQLError(response, "Failed to get organization's child organizations");
            }
            // TODO: this is also very slow, much like backendGetUsers
            const organizations = yield Promise.all(response.data.organizationsOrganizationsList.organizations.map((id) => this.backend.getOrganization(id)));
            const children = organizations.filter(AWSOrganization.instanceOf);
            // keeps the potential parent in the cache, otherwise we are in trouble
            this.backend.entityRelationCache.replaceTypedLinks(this, AWSOrganization, children, (record) => record.entity.id === this.parentId);
        });
    }
    backendRemoveUser(id) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function* () {
            const client = AppSyncClientFactory.createProvider().getTypedClient(Service.USERS);
            const response = yield client.mutate(OrganizationsUsersRemoveDocument, {
                userId: id,
                organizationId: this.id,
            });
            return ((_b = (_a = response.data) === null || _a === void 0 ? void 0 : _a.organizationsUsersRemove) === null || _b === void 0 ? void 0 : _b.result) === ResultType.Ok;
        });
    }
}
