/*
 * Copyright (C) 2023 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */

import { Dialog } from "@material-ui/core";
import React, { Component, ReactNode } from "react";
import { Device, DeviceObserver, getDisplayName, DeviceState, DeviceStateProperties } from "@sade/data-access";
import SettingsPageOta from "../../../../device-settings/components/settings-page-ota";

interface Props {
  selectedDevice: Device;
  closeOtaUpdate: () => void;
}

interface State {
  deviceState?: DeviceState<DeviceStateProperties>;
}

export default class OtaUpdatePopup extends Component<Props, State> implements DeviceObserver {
  public constructor(props: Props) {
    super(props);
    this.state = {
      deviceState: this.props.selectedDevice.getState(),
    };
  }

  public componentDidMount(): void {
    this.props.selectedDevice.addObserver(this);
  }

  public componentWillUnmount(): void {
    this.props.selectedDevice.removeObserver(this);
  }

  public onDeviceStateUpdated(device: Device): void {
    const deviceState = device.getState();
    this.setState({
      deviceState: deviceState,
    });
  }

  private renderOtaPage(): ReactNode {
    if (this.state.deviceState) {
      return (
        <SettingsPageOta
          deviceState={this.state.deviceState}
          selectedDevice={this.props.selectedDevice}
          onCloseSettings={this.props.closeOtaUpdate}
        />
      );
    }
  }

  public render(): ReactNode {
    return (
      <Dialog maxWidth={"md"} onClose={this.props.closeOtaUpdate} open={true}>
        <div className="popup-inner">
          <div className="popup-header">
            <div className="popup-header-device-name">
              <span
                className="device-icon"
                dangerouslySetInnerHTML={{ __html: this.props.selectedDevice.getIcon() }}
              ></span>
              {getDisplayName(this.props.selectedDevice)}
            </div>
            <div className="popup-header-device-type">{this.props.selectedDevice.getType()}</div>
            <div className="popup-header-secondary-info">{this.props.selectedDevice.getId()}</div>
          </div>

          <div className="popup-body">{this.renderOtaPage()}</div>
        </div>
      </Dialog>
    );
  }
}
