/*
 * Copyright (C) 2022 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */
import moment from "moment";
export class TimeWrapper {
    static getStartOfXDaysAgo(days) {
        const now = new Date(Date.now());
        return new Date(now.getFullYear(), now.getMonth(), now.getDate() - days).getTime();
    }
    static getTimestampXDaysAgo(days) {
        const date = new Date();
        date.setDate(date.getDate() - days);
        return date.getTime();
    }
    static getTimestampXUnitsAgo(fromTimestamp, num, unit) {
        return moment(fromTimestamp).utc().subtract(num, unit).valueOf();
    }
}
