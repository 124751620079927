/*
 * Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */
import { WallStationHWStateProperties } from "./WallStationHWStateProperties";
import { WallStationHWState } from "./WallStationHWState";
import { ConnectivityUnitHW } from "../ConnectivityUnitHW/ConnectivityUnitHW";
export class WallStationHW extends ConnectivityUnitHW {
    constructor(backend, params) {
        super(WallStationHW.type, backend, params);
    }
    createState(timestamp, version, reported, desired, connectionState) {
        return new WallStationHWState(this.getId(), new WallStationHWStateProperties(reported !== null && reported !== void 0 ? reported : {}), new WallStationHWStateProperties(desired !== null && desired !== void 0 ? desired : {}), timestamp, version, connectionState);
    }
    static instanceOf(device) {
        return device instanceof WallStationHW;
    }
}
WallStationHW.type = "WallStation";
