/*
 * Copyright (C) 2022 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */
export class BaseObservable {
    constructor() {
        this.observers = new Set();
    }
    addObserver(observer) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        BaseObservable.log(`Observable[${this.constructor.name}]: added observer ${observer.constructor.name} `);
        this.observers.add(observer);
    }
    removeObserver(observer) {
        if (this.observers.delete(observer)) {
            BaseObservable.log(
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            `Observable[${this.constructor.name}]: removed observer ${observer.constructor.name} `);
        }
    }
    isObservedBy(observer) {
        return this.observers.has(observer);
    }
    get observerCount() {
        return this.observers.size;
    }
    notifyAction(action) {
        [...this.observers].forEach((observer) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            BaseObservable.log(`Observable[${this.constructor.name}]: notifying ${observer.constructor.name} `);
            // set the callback into the event loop, to avoid long operations on the main
            setTimeout(() => action(observer), 0);
        });
    }
    clearObservers() {
        this.observers.clear();
    }
    static log(line) {
        if (BaseObservable.enableSpam) {
            console.log(line);
        }
    }
}
// set to true to enable logging for all observers
BaseObservable.enableSpam = false;
