import { __awaiter } from "tslib";
/**
 * Provides the configuration for the app.
 *
 * Fetches the configuration from the endpoints service, using the stage to determine which environment to fetch from.
 * In order to run this provider in node, you must provide a fetch implementation.
 * For example, you can use node-fetch: https://www.npmjs.com/package/node-fetch (v2 for CommonJs)
 */
export class EndpointsConfigurationProvider {
    /**
     *
     * @param stage - The stage to fetch the configuration for.
     * @param options - Options for the provider.
     * @param options.url - The url of the endpoints service.
     *    If not provided, the url will be read from the environment variable REACT_APP_ENDPOINTS_SERVICE_URL_OVERRIDE.
     *    If neither is set, url is constructed from the stage.
     * @param options.fetch - The fetch implementation to use. If not provided, browser's fetch is used.
     */
    constructor(stage, options) {
        var _a;
        this.stage = stage;
        this.url = (_a = options === null || options === void 0 ? void 0 : options.url) !== null && _a !== void 0 ? _a : (process.env.REACT_APP_ENDPOINTS_SERVICE_URL_OVERRIDE || undefined);
        this.fetch = options === null || options === void 0 ? void 0 : options.fetch;
    }
    static getSchemaUrls(graphql) {
        return Object.entries(graphql).reduce((acc, [key, value]) => {
            acc[key] = { url: value.schemaUrl };
            return acc;
        }, {});
    }
    static getRestApiUrls(restApi) {
        return Object.entries(restApi).reduce((acc, [key, value]) => {
            acc[key] = { url: value.https, wss: value.wss };
            return acc;
        }, {});
    }
    getConfiguration() {
        return __awaiter(this, void 0, void 0, function* () {
            const endpoints = yield this.fetchEndpoints();
            return this.responseToConfiguration(endpoints);
        });
    }
    responseToConfiguration(response) {
        try {
            const { GraphQL, Config, RestApi, version, minimumVersions } = response.endpoints;
            return {
                awsConfig: {
                    Analytics: {
                        disabled: true,
                    },
                    Auth: {
                        stage: this.stage,
                        userPoolId: Config.UserPoolId,
                        userPoolWebClientId: Config.ClientId,
                        region: Config.Region,
                        identityPoolId: Config.IdentityPoolId,
                    },
                    AppSync: {
                        AuthenticationType: Config.AuthType,
                        Region: Config.Region,
                        GqlEndpointUsers: GraphQL.users.https,
                        GqlEndpointData: GraphQL.data.https,
                        GqlEndpointDevice: GraphQL.device.https,
                        GqlEndpointEvents: GraphQL.events.https,
                        GqlEndpointStats: GraphQL.stats.https,
                    },
                },
                resources: {
                    urls: Config.Urls,
                    schemas: EndpointsConfigurationProvider.getSchemaUrls(GraphQL),
                    restApis: EndpointsConfigurationProvider.getRestApiUrls(RestApi),
                },
                backendVersion: version,
                minimumVersions,
            };
        }
        catch (err) {
            console.error("Response and error", response, err);
            throw new Error("Invalid response from endpoints service");
        }
    }
    fetchEndpoints() {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            const fetchApi = (_a = this.fetch) !== null && _a !== void 0 ? _a : fetch;
            const response = yield fetchApi(this.getEndpointsUrl());
            if (response.ok) {
                return response.json();
            }
            else {
                console.error("Response failure body", response.body);
                throw new Error(response.statusText);
            }
        });
    }
    getEndpointsUrl() {
        var _a;
        return (_a = this.url) !== null && _a !== void 0 ? _a : `https://${this.stage}.api.nortek-smartcontrol.com/endpoints`;
    }
}
