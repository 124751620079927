/*
 * Copyright (C) 2023 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 *
 */

import React, { Component } from "react";
import { BackendFactory, Device, Invitation, Organization, UserAdminDetails } from "@sade/data-access";
import { Grid } from "@material-ui/core";
import Loader from "../../../ui/loader";
import DeviceDetails from "./components/device-details";
import { translations } from "../../../../generated/translationHelper";
import DeviceEvents from "./components/device-events";
import DeviceGuestUsers from "./components/device-guest-users";
import { RouteComponentProps, withRouter } from "react-router-dom";

interface Props extends RouteComponentProps {
  organization: Organization;
  userDetails: UserAdminDetails;
}

interface State {
  deviceList?: Device[];
  currentDevice?: Device;
  invitations?: Invitation[];
}

class Devices extends Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = {};
  }

  public async componentDidMount(): Promise<void> {
    this.fetchInvitations();
    this.updateDevices();
  }

  public async componentDidUpdate(prevProps: Readonly<Props>): Promise<void> {
    if (prevProps.organization.getId() !== this.props.organization.getId()) {
      this.updateDevices();
    }
  }

  private updateDevices = async (): Promise<void> => {
    this.setState({ deviceList: undefined });
    const devices = await BackendFactory.getBackend().getOrganizationDevices(this.props.organization.getId());
    if (!devices) return;
    this.setState(
      {
        deviceList: devices,
      },
      () => {
        if (this.urlHasDevice()) {
          this.updateDeviceFromUrl();
        } else {
          this.setState({ currentDevice: devices[0] }, () => {
            this.updateUrl();
          });
        }
      }
    );
  };

  private urlHasDevice = (): boolean => {
    const urlParams = new URLSearchParams(this.props.location.search);
    const deviceId = urlParams.get("device");
    return !!deviceId;
  };

  private updateDeviceFromUrl = (): void => {
    const deviceList = this.state.deviceList;
    if (!deviceList) return;
    const urlParams = new URLSearchParams(this.props.location.search);
    const deviceId = urlParams.get("device");
    const device = deviceList.find((device) => {
      return device.getId() === deviceId;
    });
    this.setState({
      currentDevice: device ? device : deviceList[0],
    });
  };

  private updateUrl = (): void => {
    const deviceId = this.state.currentDevice?.getId();
    if (!deviceId) return;
    const urlParams = new URLSearchParams(this.props.location.search);
    urlParams.set("device", deviceId);
    this.props.history.push(`${this.props.location.pathname}?${urlParams.toString()}`);
  };

  private async fetchInvitations(): Promise<void> {
    const invitations = await BackendFactory.getOrganizationBackend().getSentInvitations(
      this.props.userDetails.getId()
    );
    this.setState({
      invitations: invitations,
    });
  }

  private handleDeviceSelect = (index: number): void => {
    if (this.state.deviceList) {
      this.setState({ currentDevice: this.state.deviceList[index] }, () => {
        this.updateUrl();
      });
    }
  };

  public render(): JSX.Element {
    if (!this.state.deviceList) return <Loader />;
    if (!this.state.currentDevice) {
      return (
        <Grid container className="overview-content-grid">
          {translations.admin.texts.noDevices()}
        </Grid>
      );
    }
    return (
      <Grid container justifyContent="center">
        <DeviceDetails
          deviceList={this.state.deviceList}
          device={this.state.currentDevice}
          onDeviceSelect={this.handleDeviceSelect}
        />
        <DeviceGuestUsers invitations={this.state.invitations} device={this.state.currentDevice} />
        {this.state.invitations ? null : <Loader />}
        <DeviceEvents device={this.state.currentDevice} />
      </Grid>
    );
  }
}

export default withRouter(Devices);
