/*
 * Copyright (C) 2022 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */
export class DataUtil {
    static parseDataFragment(item, type) {
        var _a, _b;
        if (item && item.data) {
            const dataItem = JSON.parse(item.data);
            dataItem.deviceId = item.deviceId;
            dataItem.timestamp = Number(item.timestamp); // Here timestamp gets converted into a number
            dataItem.sessionId = (_a = item.sessionId) !== null && _a !== void 0 ? _a : undefined;
            dataItem.type = (_b = item.type) !== null && _b !== void 0 ? _b : type;
            if (type && dataItem.type && dataItem.type !== type) {
                console.error("Incorrect data item type");
                return;
            }
            return dataItem;
        }
        console.error("Could not parse data item, missing fields", item);
        return;
    }
}
