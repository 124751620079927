export var EventState;
(function (EventState) {
    EventState["Active"] = "ACTIVE";
    EventState["Inactive"] = "INACTIVE";
})(EventState || (EventState = {}));
export var EventSeverity;
(function (EventSeverity) {
    EventSeverity["High"] = "HIGH";
    EventSeverity["Low"] = "LOW";
    EventSeverity["Medium"] = "MEDIUM";
})(EventSeverity || (EventSeverity = {}));
export var EventType;
(function (EventType) {
    EventType["Generic"] = "GENERIC";
    EventType["Sensor"] = "SENSOR";
})(EventType || (EventType = {}));
export var NotificationState;
(function (NotificationState) {
    NotificationState["MissingEndpoint"] = "MISSING_ENDPOINT";
    NotificationState["Ok"] = "OK";
})(NotificationState || (NotificationState = {}));
export var NotificationType;
(function (NotificationType) {
    NotificationType["Email"] = "EMAIL";
    NotificationType["Push"] = "PUSH";
    NotificationType["Sms"] = "SMS";
})(NotificationType || (NotificationType = {}));
export const EventFieldsFragmentDoc = { "kind": "Document", "definitions": [{ "kind": "FragmentDefinition", "name": { "kind": "Name", "value": "eventFields" }, "typeCondition": { "kind": "NamedType", "name": { "kind": "Name", "value": "Event" } }, "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "Field", "name": { "kind": "Name", "value": "deviceId" } }, { "kind": "Field", "name": { "kind": "Name", "value": "timestamp" } }, { "kind": "Field", "name": { "kind": "Name", "value": "eventId" } }, { "kind": "Field", "name": { "kind": "Name", "value": "organizationId" } }, { "kind": "Field", "name": { "kind": "Name", "value": "updatedTimestamp" } }, { "kind": "Field", "name": { "kind": "Name", "value": "type" } }, { "kind": "Field", "name": { "kind": "Name", "value": "eventState" } }, { "kind": "Field", "name": { "kind": "Name", "value": "severity" } }, { "kind": "Field", "name": { "kind": "Name", "value": "sensorName" } }, { "kind": "Field", "name": { "kind": "Name", "value": "sensorValue" } }, { "kind": "Field", "name": { "kind": "Name", "value": "metadata" } }] } }] };
export const SubscriptionFieldsFragmentDoc = { "kind": "Document", "definitions": [{ "kind": "FragmentDefinition", "name": { "kind": "Name", "value": "subscriptionFields" }, "typeCondition": { "kind": "NamedType", "name": { "kind": "Name", "value": "NotificationSubscription" } }, "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "Field", "name": { "kind": "Name", "value": "id" } }, { "kind": "Field", "name": { "kind": "Name", "value": "userId" } }, { "kind": "Field", "name": { "kind": "Name", "value": "eventIds" } }, { "kind": "Field", "name": { "kind": "Name", "value": "organizationId" } }, { "kind": "Field", "name": { "kind": "Name", "value": "type" } }, { "kind": "Field", "name": { "kind": "Name", "value": "state" } }] } }] };
export const EventsListDocument = { "kind": "Document", "definitions": [{ "kind": "OperationDefinition", "operation": "query", "name": { "kind": "Name", "value": "eventsList" }, "variableDefinitions": [{ "kind": "VariableDefinition", "variable": { "kind": "Variable", "name": { "kind": "Name", "value": "startTimestamp" } }, "type": { "kind": "NonNullType", "type": { "kind": "NamedType", "name": { "kind": "Name", "value": "String" } } } }, { "kind": "VariableDefinition", "variable": { "kind": "Variable", "name": { "kind": "Name", "value": "endTimestamp" } }, "type": { "kind": "NonNullType", "type": { "kind": "NamedType", "name": { "kind": "Name", "value": "String" } } } }, { "kind": "VariableDefinition", "variable": { "kind": "Variable", "name": { "kind": "Name", "value": "nextToken" } }, "type": { "kind": "NamedType", "name": { "kind": "Name", "value": "ID" } } }], "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "Field", "name": { "kind": "Name", "value": "eventsList" }, "arguments": [{ "kind": "Argument", "name": { "kind": "Name", "value": "startTimestamp" }, "value": { "kind": "Variable", "name": { "kind": "Name", "value": "startTimestamp" } } }, { "kind": "Argument", "name": { "kind": "Name", "value": "endTimestamp" }, "value": { "kind": "Variable", "name": { "kind": "Name", "value": "endTimestamp" } } }, { "kind": "Argument", "name": { "kind": "Name", "value": "nextToken" }, "value": { "kind": "Variable", "name": { "kind": "Name", "value": "nextToken" } } }], "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "Field", "name": { "kind": "Name", "value": "events" }, "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "FragmentSpread", "name": { "kind": "Name", "value": "eventFields" } }] } }, { "kind": "Field", "name": { "kind": "Name", "value": "nextToken" } }] } }] } }, ...EventFieldsFragmentDoc.definitions] };
export const DevicesEventsListDocument = { "kind": "Document", "definitions": [{ "kind": "OperationDefinition", "operation": "query", "name": { "kind": "Name", "value": "devicesEventsList" }, "variableDefinitions": [{ "kind": "VariableDefinition", "variable": { "kind": "Variable", "name": { "kind": "Name", "value": "deviceId" } }, "type": { "kind": "NonNullType", "type": { "kind": "NamedType", "name": { "kind": "Name", "value": "ID" } } } }, { "kind": "VariableDefinition", "variable": { "kind": "Variable", "name": { "kind": "Name", "value": "period" } }, "type": { "kind": "NamedType", "name": { "kind": "Name", "value": "TimePeriod" } } }, { "kind": "VariableDefinition", "variable": { "kind": "Variable", "name": { "kind": "Name", "value": "nextToken" } }, "type": { "kind": "NamedType", "name": { "kind": "Name", "value": "ID" } } }], "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "Field", "name": { "kind": "Name", "value": "devicesEventsList" }, "arguments": [{ "kind": "Argument", "name": { "kind": "Name", "value": "deviceId" }, "value": { "kind": "Variable", "name": { "kind": "Name", "value": "deviceId" } } }, { "kind": "Argument", "name": { "kind": "Name", "value": "period" }, "value": { "kind": "Variable", "name": { "kind": "Name", "value": "period" } } }, { "kind": "Argument", "name": { "kind": "Name", "value": "nextToken" }, "value": { "kind": "Variable", "name": { "kind": "Name", "value": "nextToken" } } }], "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "Field", "name": { "kind": "Name", "value": "events" }, "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "FragmentSpread", "name": { "kind": "Name", "value": "eventFields" } }] } }, { "kind": "Field", "name": { "kind": "Name", "value": "nextToken" } }] } }] } }, ...EventFieldsFragmentDoc.definitions] };
export const EventsMetadataListDocument = { "kind": "Document", "definitions": [{ "kind": "OperationDefinition", "operation": "query", "name": { "kind": "Name", "value": "eventsMetadataList" }, "variableDefinitions": [{ "kind": "VariableDefinition", "variable": { "kind": "Variable", "name": { "kind": "Name", "value": "nextToken" } }, "type": { "kind": "NamedType", "name": { "kind": "Name", "value": "ID" } } }], "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "Field", "name": { "kind": "Name", "value": "eventsMetadataList" }, "arguments": [{ "kind": "Argument", "name": { "kind": "Name", "value": "nextToken" }, "value": { "kind": "Variable", "name": { "kind": "Name", "value": "nextToken" } } }], "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "Field", "name": { "kind": "Name", "value": "eventMetadataItems" }, "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "Field", "name": { "kind": "Name", "value": "eventId" } }, { "kind": "Field", "name": { "kind": "Name", "value": "name" } }, { "kind": "Field", "name": { "kind": "Name", "value": "description" } }] } }, { "kind": "Field", "name": { "kind": "Name", "value": "nextToken" } }] } }] } }] };
export const NotificationsSubscriptionsListDocument = { "kind": "Document", "definitions": [{ "kind": "OperationDefinition", "operation": "query", "name": { "kind": "Name", "value": "notificationsSubscriptionsList" }, "variableDefinitions": [{ "kind": "VariableDefinition", "variable": { "kind": "Variable", "name": { "kind": "Name", "value": "userId" } }, "type": { "kind": "NonNullType", "type": { "kind": "NamedType", "name": { "kind": "Name", "value": "ID" } } } }, { "kind": "VariableDefinition", "variable": { "kind": "Variable", "name": { "kind": "Name", "value": "organizationId" } }, "type": { "kind": "NonNullType", "type": { "kind": "NamedType", "name": { "kind": "Name", "value": "ID" } } } }], "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "Field", "name": { "kind": "Name", "value": "notificationsSubscriptionsList" }, "arguments": [{ "kind": "Argument", "name": { "kind": "Name", "value": "userId" }, "value": { "kind": "Variable", "name": { "kind": "Name", "value": "userId" } } }, { "kind": "Argument", "name": { "kind": "Name", "value": "organizationId" }, "value": { "kind": "Variable", "name": { "kind": "Name", "value": "organizationId" } } }], "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "Field", "name": { "kind": "Name", "value": "subscriptions" }, "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "FragmentSpread", "name": { "kind": "Name", "value": "subscriptionFields" } }] } }] } }] } }, ...SubscriptionFieldsFragmentDoc.definitions] };
export const UsersEventsListDocument = { "kind": "Document", "definitions": [{ "kind": "OperationDefinition", "operation": "query", "name": { "kind": "Name", "value": "usersEventsList" }, "variableDefinitions": [{ "kind": "VariableDefinition", "variable": { "kind": "Variable", "name": { "kind": "Name", "value": "startTimestamp" } }, "type": { "kind": "NonNullType", "type": { "kind": "NamedType", "name": { "kind": "Name", "value": "String" } } } }, { "kind": "VariableDefinition", "variable": { "kind": "Variable", "name": { "kind": "Name", "value": "endTimestamp" } }, "type": { "kind": "NonNullType", "type": { "kind": "NamedType", "name": { "kind": "Name", "value": "String" } } } }, { "kind": "VariableDefinition", "variable": { "kind": "Variable", "name": { "kind": "Name", "value": "nextToken" } }, "type": { "kind": "NamedType", "name": { "kind": "Name", "value": "ID" } } }], "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "Field", "name": { "kind": "Name", "value": "usersEventsList" }, "arguments": [{ "kind": "Argument", "name": { "kind": "Name", "value": "startTimestamp" }, "value": { "kind": "Variable", "name": { "kind": "Name", "value": "startTimestamp" } } }, { "kind": "Argument", "name": { "kind": "Name", "value": "endTimestamp" }, "value": { "kind": "Variable", "name": { "kind": "Name", "value": "endTimestamp" } } }, { "kind": "Argument", "name": { "kind": "Name", "value": "nextToken" }, "value": { "kind": "Variable", "name": { "kind": "Name", "value": "nextToken" } } }], "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "Field", "name": { "kind": "Name", "value": "events" }, "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "FragmentSpread", "name": { "kind": "Name", "value": "eventFields" } }] } }, { "kind": "Field", "name": { "kind": "Name", "value": "nextToken" } }] } }] } }, ...EventFieldsFragmentDoc.definitions] };
export const EventsDeactivateDocument = { "kind": "Document", "definitions": [{ "kind": "OperationDefinition", "operation": "mutation", "name": { "kind": "Name", "value": "eventsDeactivate" }, "variableDefinitions": [{ "kind": "VariableDefinition", "variable": { "kind": "Variable", "name": { "kind": "Name", "value": "payload" } }, "type": { "kind": "NonNullType", "type": { "kind": "NamedType", "name": { "kind": "Name", "value": "EventPayload" } } } }], "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "Field", "name": { "kind": "Name", "value": "eventsDeactivate" }, "arguments": [{ "kind": "Argument", "name": { "kind": "Name", "value": "payload" }, "value": { "kind": "Variable", "name": { "kind": "Name", "value": "payload" } } }], "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "FragmentSpread", "name": { "kind": "Name", "value": "eventFields" } }] } }] } }, ...EventFieldsFragmentDoc.definitions] };
export const NotificationsSubscriptionsCreateDocument = { "kind": "Document", "definitions": [{ "kind": "OperationDefinition", "operation": "mutation", "name": { "kind": "Name", "value": "notificationsSubscriptionsCreate" }, "variableDefinitions": [{ "kind": "VariableDefinition", "variable": { "kind": "Variable", "name": { "kind": "Name", "value": "subscriptionDetails" } }, "type": { "kind": "NonNullType", "type": { "kind": "NamedType", "name": { "kind": "Name", "value": "SubscriptionDetails" } } } }], "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "Field", "name": { "kind": "Name", "value": "notificationsSubscriptionsCreate" }, "arguments": [{ "kind": "Argument", "name": { "kind": "Name", "value": "subscriptionDetails" }, "value": { "kind": "Variable", "name": { "kind": "Name", "value": "subscriptionDetails" } } }], "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "FragmentSpread", "name": { "kind": "Name", "value": "subscriptionFields" } }] } }] } }, ...SubscriptionFieldsFragmentDoc.definitions] };
export const NotificationsSubscriptionsRemoveDocument = { "kind": "Document", "definitions": [{ "kind": "OperationDefinition", "operation": "mutation", "name": { "kind": "Name", "value": "notificationsSubscriptionsRemove" }, "variableDefinitions": [{ "kind": "VariableDefinition", "variable": { "kind": "Variable", "name": { "kind": "Name", "value": "userId" } }, "type": { "kind": "NonNullType", "type": { "kind": "NamedType", "name": { "kind": "Name", "value": "ID" } } } }, { "kind": "VariableDefinition", "variable": { "kind": "Variable", "name": { "kind": "Name", "value": "subscriptionId" } }, "type": { "kind": "NonNullType", "type": { "kind": "NamedType", "name": { "kind": "Name", "value": "ID" } } } }], "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "Field", "name": { "kind": "Name", "value": "notificationsSubscriptionsRemove" }, "arguments": [{ "kind": "Argument", "name": { "kind": "Name", "value": "userId" }, "value": { "kind": "Variable", "name": { "kind": "Name", "value": "userId" } } }, { "kind": "Argument", "name": { "kind": "Name", "value": "subscriptionId" }, "value": { "kind": "Variable", "name": { "kind": "Name", "value": "subscriptionId" } } }], "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "Field", "name": { "kind": "Name", "value": "subscription" }, "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "FragmentSpread", "name": { "kind": "Name", "value": "subscriptionFields" } }] } }] } }] } }, ...SubscriptionFieldsFragmentDoc.definitions] };
export const EventsFeedDocument = { "kind": "Document", "definitions": [{ "kind": "OperationDefinition", "operation": "subscription", "name": { "kind": "Name", "value": "eventsFeed" }, "variableDefinitions": [{ "kind": "VariableDefinition", "variable": { "kind": "Variable", "name": { "kind": "Name", "value": "receiver" } }, "type": { "kind": "NonNullType", "type": { "kind": "NamedType", "name": { "kind": "Name", "value": "ID" } } } }], "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "Field", "name": { "kind": "Name", "value": "eventsFeed" }, "arguments": [{ "kind": "Argument", "name": { "kind": "Name", "value": "receiver" }, "value": { "kind": "Variable", "name": { "kind": "Name", "value": "receiver" } } }], "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "Field", "name": { "kind": "Name", "value": "receiver" } }, { "kind": "Field", "name": { "kind": "Name", "value": "item" }, "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "FragmentSpread", "name": { "kind": "Name", "value": "eventFields" } }] } }] } }] } }, ...EventFieldsFragmentDoc.definitions] };
