/*
 * Copyright (C) 2022 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */
import ReceiverObserverSubscription from "./utils/receivers/ReceiverObserverSubscription";
import { AppSyncClientFactory } from "./backend/AppSyncClientFactory";
import { ReceiverManager } from "./utils/receivers/ReceiverManager";
export default class SubscriptionControllerFactory {
    static getController(service, document, handler) {
        const receiverSubscription = new ReceiverObserverSubscription(() => AppSyncClientFactory.createProvider().getTypedClient(service), document, handler);
        ReceiverManager.instance.addObserver(receiverSubscription);
        return {
            terminateSubscription: () => {
                ReceiverManager.instance.removeObserver(receiverSubscription);
                receiverSubscription.terminateSubscriptions();
            },
        };
    }
}
