/*
 * Copyright (C) 2022 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */
import { __awaiter } from "tslib";
import { AppSyncClientFactory } from "../backend/AppSyncClientFactory";
import { Service } from "../backend/AppSyncClientProvider";
import { DataUtil } from "./Data";
import { DataSet } from "./DataSet";
import { DataSubscriptionManager } from "./DataSubscriptionManager";
import { isDefined } from "../../common/Utils";
import { DevicesMeasurementsListDocument } from "../../generated/gqlData";
import { timestampToHumanReadable } from "../utils/Utils";
export class AWSDataSet extends DataSet {
    constructor(deviceId, type, startTimestamp, endTimestamp) {
        super();
        this.deviceId = deviceId;
        this.type = type;
        this.startTimestamp = startTimestamp;
        this.endTimestamp = endTimestamp;
        this.data = [];
        this.timestampOrder = (a, b) => a.timestamp - b.timestamp;
    }
    fetch() {
        var _a, _b, _c, _d;
        return __awaiter(this, void 0, void 0, function* () {
            let nextToken = null;
            let data = [];
            try {
                do {
                    const appSyncClient = AppSyncClientFactory.createProvider().getTypedClient(Service.DATA);
                    const dataResponse = yield appSyncClient.query(DevicesMeasurementsListDocument, {
                        deviceId: this.deviceId,
                        startTimestamp: this.startTimestamp.toString(),
                        endTimestamp: this.endTimestamp.toString(),
                        nextToken,
                    }, 
                    // mobile-app specific: Force fetch or will return old data from cache
                    {
                        fetchPolicy: "network-only",
                    });
                    // cast is required or response encounters a cyclic type inference
                    nextToken = ((_b = (_a = dataResponse.data.devicesMeasurementsList) === null || _a === void 0 ? void 0 : _a.nextToken) !== null && _b !== void 0 ? _b : null);
                    const dataItems = ((_d = (_c = dataResponse.data.devicesMeasurementsList) === null || _c === void 0 ? void 0 : _c.measurementItems) !== null && _d !== void 0 ? _d : [])
                        .map((item) => DataUtil.parseDataFragment(item, this.type))
                        .filter(isDefined);
                    data = data.concat(dataItems);
                } while (nextToken);
                this.setData(data);
            }
            catch (error) {
                console.error("Error", error);
            }
        });
    }
    getId() {
        return this.deviceId;
    }
    getData() {
        return [...this.data];
    }
    getType() {
        return this.type;
    }
    getTimePeriod() {
        return { startTimestamp: this.startTimestamp, endTimestamp: this.endTimestamp };
    }
    setData(data) {
        const newData = [...data];
        newData.sort(this.timestampOrder);
        this.data = newData;
        console.log("DataSet set:", timestampToHumanReadable(this.endTimestamp));
    }
    appendData(data) {
        this.data.push(data);
        this.data.sort(this.timestampOrder);
        if (data.timestamp > this.endTimestamp) {
            this.endTimestamp = data.timestamp;
            console.log("DataSet append:", timestampToHumanReadable(this.endTimestamp));
        }
        this.notifyAction((observer) => observer.onDataUpdate(this));
    }
    addObserver(observer) {
        const _super = Object.create(null, {
            addObserver: { get: () => super.addObserver }
        });
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.data) {
                yield this.fetch();
            }
            _super.addObserver.call(this, observer);
            if (this.observerCount === 1) {
                DataSubscriptionManager.instance.addListener(this);
            }
        });
    }
    removeObserver(observer) {
        super.removeObserver(observer);
        if (this.observerCount === 0) {
            DataSubscriptionManager.instance.removeListener(this);
        }
    }
}
