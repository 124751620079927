export var DeliveryStatus;
(function (DeliveryStatus) {
    DeliveryStatus["Delivered"] = "Delivered";
    DeliveryStatus["DeliveryFailed"] = "DeliveryFailed";
    DeliveryStatus["Pending"] = "Pending";
})(DeliveryStatus || (DeliveryStatus = {}));
export var AccessType;
(function (AccessType) {
    AccessType["Guest"] = "Guest";
    AccessType["Owner"] = "Owner";
})(AccessType || (AccessType = {}));
export var ResponseStatus;
(function (ResponseStatus) {
    ResponseStatus["Accepted"] = "Accepted";
    ResponseStatus["Declined"] = "Declined";
    ResponseStatus["None"] = "None";
})(ResponseStatus || (ResponseStatus = {}));
export var InvitationIdentifierType;
(function (InvitationIdentifierType) {
    InvitationIdentifierType["Email"] = "Email";
    InvitationIdentifierType["Id"] = "ID";
})(InvitationIdentifierType || (InvitationIdentifierType = {}));
export var ResultType;
(function (ResultType) {
    ResultType["Failure"] = "FAILURE";
    ResultType["NotExist"] = "NOT_EXIST";
    ResultType["Ok"] = "OK";
})(ResultType || (ResultType = {}));
export const OrganizationFieldsFragmentDoc = { "kind": "Document", "definitions": [{ "kind": "FragmentDefinition", "name": { "kind": "Name", "value": "organizationFields" }, "typeCondition": { "kind": "NamedType", "name": { "kind": "Name", "value": "Organization" } }, "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "Field", "name": { "kind": "Name", "value": "id" } }, { "kind": "Field", "name": { "kind": "Name", "value": "name" } }, { "kind": "Field", "name": { "kind": "Name", "value": "organizationId" } }] } }] };
export const PolicyGroupFieldsFragmentDoc = { "kind": "Document", "definitions": [{ "kind": "FragmentDefinition", "name": { "kind": "Name", "value": "policyGroupFields" }, "typeCondition": { "kind": "NamedType", "name": { "kind": "Name", "value": "PolicyGroup" } }, "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "Field", "name": { "kind": "Name", "value": "id" } }, { "kind": "Field", "name": { "kind": "Name", "value": "policies" } }, { "kind": "Field", "name": { "kind": "Name", "value": "name" } }, { "kind": "Field", "name": { "kind": "Name", "value": "organizationId" } }] } }] };
export const UserFieldsFragmentDoc = { "kind": "Document", "definitions": [{ "kind": "FragmentDefinition", "name": { "kind": "Name", "value": "userFields" }, "typeCondition": { "kind": "NamedType", "name": { "kind": "Name", "value": "User" } }, "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "Field", "name": { "kind": "Name", "value": "id" } }, { "kind": "Field", "name": { "kind": "Name", "value": "email" } }, { "kind": "Field", "name": { "kind": "Name", "value": "policies" } }, { "kind": "Field", "name": { "kind": "Name", "value": "organizationId" } }, { "kind": "Field", "name": { "kind": "Name", "value": "displayName" } }] } }] };
export const ResultFieldsFragmentDoc = { "kind": "Document", "definitions": [{ "kind": "FragmentDefinition", "name": { "kind": "Name", "value": "resultFields" }, "typeCondition": { "kind": "NamedType", "name": { "kind": "Name", "value": "Result" } }, "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "Field", "name": { "kind": "Name", "value": "result" } }, { "kind": "Field", "name": { "kind": "Name", "value": "failureReason" } }] } }] };
export const InvitationUserFieldsFragmentDoc = { "kind": "Document", "definitions": [{ "kind": "FragmentDefinition", "name": { "kind": "Name", "value": "invitationUserFields" }, "typeCondition": { "kind": "NamedType", "name": { "kind": "Name", "value": "InvitationUser" } }, "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "Field", "name": { "kind": "Name", "value": "email" } }, { "kind": "Field", "name": { "kind": "Name", "value": "id" } }, { "kind": "Field", "name": { "kind": "Name", "value": "firstName" } }, { "kind": "Field", "name": { "kind": "Name", "value": "lastName" } }] } }] };
export const InvitationFieldsFragmentDoc = { "kind": "Document", "definitions": [{ "kind": "FragmentDefinition", "name": { "kind": "Name", "value": "invitationFields" }, "typeCondition": { "kind": "NamedType", "name": { "kind": "Name", "value": "Invitation" } }, "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "Field", "name": { "kind": "Name", "value": "id" } }, { "kind": "Field", "name": { "kind": "Name", "value": "sender" }, "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "FragmentSpread", "name": { "kind": "Name", "value": "invitationUserFields" } }] } }, { "kind": "Field", "name": { "kind": "Name", "value": "receiver" }, "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "FragmentSpread", "name": { "kind": "Name", "value": "invitationUserFields" } }] } }, { "kind": "Field", "name": { "kind": "Name", "value": "creationDate" } }, { "kind": "Field", "name": { "kind": "Name", "value": "deliveryStatus" } }, { "kind": "Field", "name": { "kind": "Name", "value": "responseStatus" } }, { "kind": "Field", "name": { "kind": "Name", "value": "responseDate" } }, { "kind": "Field", "name": { "kind": "Name", "value": "active" } }, { "kind": "Field", "name": { "kind": "Name", "value": "activeChangedDate" } }, { "kind": "Field", "name": { "kind": "Name", "value": "devices" }, "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "Field", "name": { "kind": "Name", "value": "id" } }, { "kind": "Field", "name": { "kind": "Name", "value": "accessType" } }] } }] } }, ...InvitationUserFieldsFragmentDoc.definitions] };
export const UserAdminFieldsFragmentDoc = { "kind": "Document", "definitions": [{ "kind": "FragmentDefinition", "name": { "kind": "Name", "value": "userAdminFields" }, "typeCondition": { "kind": "NamedType", "name": { "kind": "Name", "value": "UserAdminDetails" } }, "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "Field", "name": { "kind": "Name", "value": "id" } }, { "kind": "Field", "name": { "kind": "Name", "value": "email" } }, { "kind": "Field", "name": { "kind": "Name", "value": "androidPn" } }, { "kind": "Field", "name": { "kind": "Name", "value": "creationDate" } }, { "kind": "Field", "name": { "kind": "Name", "value": "emailVerified" } }, { "kind": "Field", "name": { "kind": "Name", "value": "firstName" } }, { "kind": "Field", "name": { "kind": "Name", "value": "iosPn" } }, { "kind": "Field", "name": { "kind": "Name", "value": "lastName" } }, { "kind": "Field", "name": { "kind": "Name", "value": "organizationId" } }, { "kind": "Field", "name": { "kind": "Name", "value": "phoneNumber" } }, { "kind": "Field", "name": { "kind": "Name", "value": "updatedDate" } }, { "kind": "Field", "name": { "kind": "Name", "value": "migrationDetails" }, "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "Field", "name": { "kind": "Name", "value": "type" } }, { "kind": "Field", "name": { "kind": "Name", "value": "data" } }, { "kind": "Field", "name": { "kind": "Name", "value": "timestamp" } }] } }, { "kind": "Field", "name": { "kind": "Name", "value": "rndStatus" } }] } }] };
export const OrganizationsGetDocument = { "kind": "Document", "definitions": [{ "kind": "OperationDefinition", "operation": "query", "name": { "kind": "Name", "value": "organizationsGet" }, "variableDefinitions": [{ "kind": "VariableDefinition", "variable": { "kind": "Variable", "name": { "kind": "Name", "value": "organizationId" } }, "type": { "kind": "NonNullType", "type": { "kind": "NamedType", "name": { "kind": "Name", "value": "ID" } } } }], "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "Field", "name": { "kind": "Name", "value": "organizationsGet" }, "arguments": [{ "kind": "Argument", "name": { "kind": "Name", "value": "organizationId" }, "value": { "kind": "Variable", "name": { "kind": "Name", "value": "organizationId" } } }], "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "FragmentSpread", "name": { "kind": "Name", "value": "organizationFields" } }] } }] } }, ...OrganizationFieldsFragmentDoc.definitions] };
export const OrganizationsOrganizationsListDocument = { "kind": "Document", "definitions": [{ "kind": "OperationDefinition", "operation": "query", "name": { "kind": "Name", "value": "organizationsOrganizationsList" }, "variableDefinitions": [{ "kind": "VariableDefinition", "variable": { "kind": "Variable", "name": { "kind": "Name", "value": "organizationId" } }, "type": { "kind": "NonNullType", "type": { "kind": "NamedType", "name": { "kind": "Name", "value": "ID" } } } }, { "kind": "VariableDefinition", "variable": { "kind": "Variable", "name": { "kind": "Name", "value": "nextToken" } }, "type": { "kind": "NamedType", "name": { "kind": "Name", "value": "ID" } } }], "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "Field", "name": { "kind": "Name", "value": "organizationsOrganizationsList" }, "arguments": [{ "kind": "Argument", "name": { "kind": "Name", "value": "organizationId" }, "value": { "kind": "Variable", "name": { "kind": "Name", "value": "organizationId" } } }, { "kind": "Argument", "name": { "kind": "Name", "value": "nextToken" }, "value": { "kind": "Variable", "name": { "kind": "Name", "value": "nextToken" } } }], "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "Field", "name": { "kind": "Name", "value": "organizations" } }, { "kind": "Field", "name": { "kind": "Name", "value": "nextToken" } }] } }] } }] };
export const OrganizationsPolicyGroupsListDocument = { "kind": "Document", "definitions": [{ "kind": "OperationDefinition", "operation": "query", "name": { "kind": "Name", "value": "organizationsPolicyGroupsList" }, "variableDefinitions": [{ "kind": "VariableDefinition", "variable": { "kind": "Variable", "name": { "kind": "Name", "value": "organizationId" } }, "type": { "kind": "NonNullType", "type": { "kind": "NamedType", "name": { "kind": "Name", "value": "ID" } } } }, { "kind": "VariableDefinition", "variable": { "kind": "Variable", "name": { "kind": "Name", "value": "nextToken" } }, "type": { "kind": "NamedType", "name": { "kind": "Name", "value": "ID" } } }], "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "Field", "name": { "kind": "Name", "value": "organizationsPolicyGroupsList" }, "arguments": [{ "kind": "Argument", "name": { "kind": "Name", "value": "organizationId" }, "value": { "kind": "Variable", "name": { "kind": "Name", "value": "organizationId" } } }, { "kind": "Argument", "name": { "kind": "Name", "value": "nextToken" }, "value": { "kind": "Variable", "name": { "kind": "Name", "value": "nextToken" } } }], "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "Field", "name": { "kind": "Name", "value": "groups" } }, { "kind": "Field", "name": { "kind": "Name", "value": "nextToken" } }] } }] } }] };
export const OrganizationsUsersListDocument = { "kind": "Document", "definitions": [{ "kind": "OperationDefinition", "operation": "query", "name": { "kind": "Name", "value": "organizationsUsersList" }, "variableDefinitions": [{ "kind": "VariableDefinition", "variable": { "kind": "Variable", "name": { "kind": "Name", "value": "organizationId" } }, "type": { "kind": "NonNullType", "type": { "kind": "NamedType", "name": { "kind": "Name", "value": "ID" } } } }, { "kind": "VariableDefinition", "variable": { "kind": "Variable", "name": { "kind": "Name", "value": "nextToken" } }, "type": { "kind": "NamedType", "name": { "kind": "Name", "value": "ID" } } }], "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "Field", "name": { "kind": "Name", "value": "organizationsUsersList" }, "arguments": [{ "kind": "Argument", "name": { "kind": "Name", "value": "organizationId" }, "value": { "kind": "Variable", "name": { "kind": "Name", "value": "organizationId" } } }, { "kind": "Argument", "name": { "kind": "Name", "value": "nextToken" }, "value": { "kind": "Variable", "name": { "kind": "Name", "value": "nextToken" } } }], "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "Field", "name": { "kind": "Name", "value": "users" } }, { "kind": "Field", "name": { "kind": "Name", "value": "nextToken" } }] } }] } }] };
export const PolicyGroupsGetDocument = { "kind": "Document", "definitions": [{ "kind": "OperationDefinition", "operation": "query", "name": { "kind": "Name", "value": "policyGroupsGet" }, "variableDefinitions": [{ "kind": "VariableDefinition", "variable": { "kind": "Variable", "name": { "kind": "Name", "value": "policyGroupId" } }, "type": { "kind": "NonNullType", "type": { "kind": "NamedType", "name": { "kind": "Name", "value": "ID" } } } }], "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "Field", "name": { "kind": "Name", "value": "policyGroupsGet" }, "arguments": [{ "kind": "Argument", "name": { "kind": "Name", "value": "policyGroupId" }, "value": { "kind": "Variable", "name": { "kind": "Name", "value": "policyGroupId" } } }], "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "FragmentSpread", "name": { "kind": "Name", "value": "policyGroupFields" } }] } }] } }, ...PolicyGroupFieldsFragmentDoc.definitions] };
export const PolicyGroupsUsersListDocument = { "kind": "Document", "definitions": [{ "kind": "OperationDefinition", "operation": "query", "name": { "kind": "Name", "value": "policyGroupsUsersList" }, "variableDefinitions": [{ "kind": "VariableDefinition", "variable": { "kind": "Variable", "name": { "kind": "Name", "value": "policyGroupId" } }, "type": { "kind": "NonNullType", "type": { "kind": "NamedType", "name": { "kind": "Name", "value": "ID" } } } }, { "kind": "VariableDefinition", "variable": { "kind": "Variable", "name": { "kind": "Name", "value": "nextToken" } }, "type": { "kind": "NamedType", "name": { "kind": "Name", "value": "ID" } } }], "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "Field", "name": { "kind": "Name", "value": "policyGroupsUsersList" }, "arguments": [{ "kind": "Argument", "name": { "kind": "Name", "value": "policyGroupId" }, "value": { "kind": "Variable", "name": { "kind": "Name", "value": "policyGroupId" } } }, { "kind": "Argument", "name": { "kind": "Name", "value": "nextToken" }, "value": { "kind": "Variable", "name": { "kind": "Name", "value": "nextToken" } } }], "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "Field", "name": { "kind": "Name", "value": "users" } }, { "kind": "Field", "name": { "kind": "Name", "value": "nextToken" } }] } }] } }] };
export const UsersGetDocument = { "kind": "Document", "definitions": [{ "kind": "OperationDefinition", "operation": "query", "name": { "kind": "Name", "value": "usersGet" }, "variableDefinitions": [{ "kind": "VariableDefinition", "variable": { "kind": "Variable", "name": { "kind": "Name", "value": "userId" } }, "type": { "kind": "NonNullType", "type": { "kind": "NamedType", "name": { "kind": "Name", "value": "ID" } } } }], "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "Field", "name": { "kind": "Name", "value": "usersGet" }, "arguments": [{ "kind": "Argument", "name": { "kind": "Name", "value": "userId" }, "value": { "kind": "Variable", "name": { "kind": "Name", "value": "userId" } } }], "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "FragmentSpread", "name": { "kind": "Name", "value": "userFields" } }] } }] } }, ...UserFieldsFragmentDoc.definitions] };
export const UsersAdminGetDocument = { "kind": "Document", "definitions": [{ "kind": "OperationDefinition", "operation": "query", "name": { "kind": "Name", "value": "usersAdminGet" }, "variableDefinitions": [{ "kind": "VariableDefinition", "variable": { "kind": "Variable", "name": { "kind": "Name", "value": "userId" } }, "type": { "kind": "NonNullType", "type": { "kind": "NamedType", "name": { "kind": "Name", "value": "ID" } } } }], "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "Field", "name": { "kind": "Name", "value": "usersAdminGet" }, "arguments": [{ "kind": "Argument", "name": { "kind": "Name", "value": "userId" }, "value": { "kind": "Variable", "name": { "kind": "Name", "value": "userId" } } }], "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "FragmentSpread", "name": { "kind": "Name", "value": "userAdminFields" } }] } }] } }, ...UserAdminFieldsFragmentDoc.definitions] };
export const UsersSearchDocument = { "kind": "Document", "definitions": [{ "kind": "OperationDefinition", "operation": "query", "name": { "kind": "Name", "value": "usersSearch" }, "variableDefinitions": [{ "kind": "VariableDefinition", "variable": { "kind": "Variable", "name": { "kind": "Name", "value": "attributeName" } }, "type": { "kind": "NonNullType", "type": { "kind": "NamedType", "name": { "kind": "Name", "value": "String" } } } }, { "kind": "VariableDefinition", "variable": { "kind": "Variable", "name": { "kind": "Name", "value": "attributeValue" } }, "type": { "kind": "NonNullType", "type": { "kind": "NamedType", "name": { "kind": "Name", "value": "String" } } } }], "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "Field", "name": { "kind": "Name", "value": "usersSearch" }, "arguments": [{ "kind": "Argument", "name": { "kind": "Name", "value": "attributeName" }, "value": { "kind": "Variable", "name": { "kind": "Name", "value": "attributeName" } } }, { "kind": "Argument", "name": { "kind": "Name", "value": "attributeValue" }, "value": { "kind": "Variable", "name": { "kind": "Name", "value": "attributeValue" } } }], "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "Field", "name": { "kind": "Name", "value": "users" }, "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "FragmentSpread", "name": { "kind": "Name", "value": "userFields" } }] } }, { "kind": "Field", "name": { "kind": "Name", "value": "nextToken" } }] } }] } }, ...UserFieldsFragmentDoc.definitions] };
export const UsersGrantsListDocument = { "kind": "Document", "definitions": [{ "kind": "OperationDefinition", "operation": "query", "name": { "kind": "Name", "value": "usersGrantsList" }, "variableDefinitions": [{ "kind": "VariableDefinition", "variable": { "kind": "Variable", "name": { "kind": "Name", "value": "userId" } }, "type": { "kind": "NonNullType", "type": { "kind": "NamedType", "name": { "kind": "Name", "value": "ID" } } } }, { "kind": "VariableDefinition", "variable": { "kind": "Variable", "name": { "kind": "Name", "value": "organizationId" } }, "type": { "kind": "NonNullType", "type": { "kind": "NamedType", "name": { "kind": "Name", "value": "ID" } } } }], "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "Field", "name": { "kind": "Name", "value": "usersGrantsList" }, "arguments": [{ "kind": "Argument", "name": { "kind": "Name", "value": "userId" }, "value": { "kind": "Variable", "name": { "kind": "Name", "value": "userId" } } }, { "kind": "Argument", "name": { "kind": "Name", "value": "organizationId" }, "value": { "kind": "Variable", "name": { "kind": "Name", "value": "organizationId" } } }], "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "Field", "name": { "kind": "Name", "value": "grants" }, "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "Field", "name": { "kind": "Name", "value": "organizationId" } }, { "kind": "Field", "name": { "kind": "Name", "value": "policies" } }] } }] } }] } }] };
export const UsersPolicyGroupsListDocument = { "kind": "Document", "definitions": [{ "kind": "OperationDefinition", "operation": "query", "name": { "kind": "Name", "value": "usersPolicyGroupsList" }, "variableDefinitions": [{ "kind": "VariableDefinition", "variable": { "kind": "Variable", "name": { "kind": "Name", "value": "userId" } }, "type": { "kind": "NonNullType", "type": { "kind": "NamedType", "name": { "kind": "Name", "value": "ID" } } } }, { "kind": "VariableDefinition", "variable": { "kind": "Variable", "name": { "kind": "Name", "value": "nextToken" } }, "type": { "kind": "NamedType", "name": { "kind": "Name", "value": "ID" } } }], "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "Field", "name": { "kind": "Name", "value": "usersPolicyGroupsList" }, "arguments": [{ "kind": "Argument", "name": { "kind": "Name", "value": "userId" }, "value": { "kind": "Variable", "name": { "kind": "Name", "value": "userId" } } }, { "kind": "Argument", "name": { "kind": "Name", "value": "nextToken" }, "value": { "kind": "Variable", "name": { "kind": "Name", "value": "nextToken" } } }], "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "Field", "name": { "kind": "Name", "value": "groups" } }, { "kind": "Field", "name": { "kind": "Name", "value": "nextToken" } }] } }] } }] };
export const UsersInvitationsSentListDocument = { "kind": "Document", "definitions": [{ "kind": "OperationDefinition", "operation": "query", "name": { "kind": "Name", "value": "usersInvitationsSentList" }, "variableDefinitions": [{ "kind": "VariableDefinition", "variable": { "kind": "Variable", "name": { "kind": "Name", "value": "userId" } }, "type": { "kind": "NonNullType", "type": { "kind": "NamedType", "name": { "kind": "Name", "value": "ID" } } } }, { "kind": "VariableDefinition", "variable": { "kind": "Variable", "name": { "kind": "Name", "value": "nextToken" } }, "type": { "kind": "NamedType", "name": { "kind": "Name", "value": "ID" } } }], "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "Field", "name": { "kind": "Name", "value": "usersInvitationsSentList" }, "arguments": [{ "kind": "Argument", "name": { "kind": "Name", "value": "userId" }, "value": { "kind": "Variable", "name": { "kind": "Name", "value": "userId" } } }, { "kind": "Argument", "name": { "kind": "Name", "value": "nextToken" }, "value": { "kind": "Variable", "name": { "kind": "Name", "value": "nextToken" } } }], "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "Field", "name": { "kind": "Name", "value": "invitations" }, "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "FragmentSpread", "name": { "kind": "Name", "value": "invitationFields" } }] } }, { "kind": "Field", "name": { "kind": "Name", "value": "nextToken" } }] } }] } }, ...InvitationFieldsFragmentDoc.definitions] };
export const UsersInvitationsReceivedListDocument = { "kind": "Document", "definitions": [{ "kind": "OperationDefinition", "operation": "query", "name": { "kind": "Name", "value": "usersInvitationsReceivedList" }, "variableDefinitions": [{ "kind": "VariableDefinition", "variable": { "kind": "Variable", "name": { "kind": "Name", "value": "userId" } }, "type": { "kind": "NonNullType", "type": { "kind": "NamedType", "name": { "kind": "Name", "value": "ID" } } } }, { "kind": "VariableDefinition", "variable": { "kind": "Variable", "name": { "kind": "Name", "value": "nextToken" } }, "type": { "kind": "NamedType", "name": { "kind": "Name", "value": "ID" } } }], "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "Field", "name": { "kind": "Name", "value": "usersInvitationsReceivedList" }, "arguments": [{ "kind": "Argument", "name": { "kind": "Name", "value": "userId" }, "value": { "kind": "Variable", "name": { "kind": "Name", "value": "userId" } } }, { "kind": "Argument", "name": { "kind": "Name", "value": "nextToken" }, "value": { "kind": "Variable", "name": { "kind": "Name", "value": "nextToken" } } }], "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "Field", "name": { "kind": "Name", "value": "invitations" }, "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "FragmentSpread", "name": { "kind": "Name", "value": "invitationFields" } }] } }, { "kind": "Field", "name": { "kind": "Name", "value": "nextToken" } }] } }] } }, ...InvitationFieldsFragmentDoc.definitions] };
export const OrganizationsCreateDocument = { "kind": "Document", "definitions": [{ "kind": "OperationDefinition", "operation": "mutation", "name": { "kind": "Name", "value": "organizationsCreate" }, "variableDefinitions": [{ "kind": "VariableDefinition", "variable": { "kind": "Variable", "name": { "kind": "Name", "value": "payload" } }, "type": { "kind": "NonNullType", "type": { "kind": "NamedType", "name": { "kind": "Name", "value": "OrganizationCreatePayload" } } } }], "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "Field", "name": { "kind": "Name", "value": "organizationsCreate" }, "arguments": [{ "kind": "Argument", "name": { "kind": "Name", "value": "payload" }, "value": { "kind": "Variable", "name": { "kind": "Name", "value": "payload" } } }], "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "FragmentSpread", "name": { "kind": "Name", "value": "organizationFields" } }] } }] } }, ...OrganizationFieldsFragmentDoc.definitions] };
export const OrganizationsDeleteDocument = { "kind": "Document", "definitions": [{ "kind": "OperationDefinition", "operation": "mutation", "name": { "kind": "Name", "value": "organizationsDelete" }, "variableDefinitions": [{ "kind": "VariableDefinition", "variable": { "kind": "Variable", "name": { "kind": "Name", "value": "organizationId" } }, "type": { "kind": "NonNullType", "type": { "kind": "NamedType", "name": { "kind": "Name", "value": "ID" } } } }], "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "Field", "name": { "kind": "Name", "value": "organizationsDelete" }, "arguments": [{ "kind": "Argument", "name": { "kind": "Name", "value": "organizationId" }, "value": { "kind": "Variable", "name": { "kind": "Name", "value": "organizationId" } } }], "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "FragmentSpread", "name": { "kind": "Name", "value": "resultFields" } }] } }] } }, ...ResultFieldsFragmentDoc.definitions] };
export const OrganizationsUpdateDocument = { "kind": "Document", "definitions": [{ "kind": "OperationDefinition", "operation": "mutation", "name": { "kind": "Name", "value": "organizationsUpdate" }, "variableDefinitions": [{ "kind": "VariableDefinition", "variable": { "kind": "Variable", "name": { "kind": "Name", "value": "payload" } }, "type": { "kind": "NonNullType", "type": { "kind": "NamedType", "name": { "kind": "Name", "value": "OrganizationUpdatePayload" } } } }], "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "Field", "name": { "kind": "Name", "value": "organizationsUpdate" }, "arguments": [{ "kind": "Argument", "name": { "kind": "Name", "value": "payload" }, "value": { "kind": "Variable", "name": { "kind": "Name", "value": "payload" } } }], "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "FragmentSpread", "name": { "kind": "Name", "value": "organizationFields" } }] } }] } }, ...OrganizationFieldsFragmentDoc.definitions] };
export const OrganizationsUsersAddDocument = { "kind": "Document", "definitions": [{ "kind": "OperationDefinition", "operation": "mutation", "name": { "kind": "Name", "value": "organizationsUsersAdd" }, "variableDefinitions": [{ "kind": "VariableDefinition", "variable": { "kind": "Variable", "name": { "kind": "Name", "value": "organizationId" } }, "type": { "kind": "NonNullType", "type": { "kind": "NamedType", "name": { "kind": "Name", "value": "ID" } } } }, { "kind": "VariableDefinition", "variable": { "kind": "Variable", "name": { "kind": "Name", "value": "userId" } }, "type": { "kind": "NonNullType", "type": { "kind": "NamedType", "name": { "kind": "Name", "value": "ID" } } } }], "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "Field", "name": { "kind": "Name", "value": "organizationsUsersAdd" }, "arguments": [{ "kind": "Argument", "name": { "kind": "Name", "value": "organizationId" }, "value": { "kind": "Variable", "name": { "kind": "Name", "value": "organizationId" } } }, { "kind": "Argument", "name": { "kind": "Name", "value": "userId" }, "value": { "kind": "Variable", "name": { "kind": "Name", "value": "userId" } } }], "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "FragmentSpread", "name": { "kind": "Name", "value": "resultFields" } }] } }] } }, ...ResultFieldsFragmentDoc.definitions] };
export const OrganizationsUsersRemoveDocument = { "kind": "Document", "definitions": [{ "kind": "OperationDefinition", "operation": "mutation", "name": { "kind": "Name", "value": "organizationsUsersRemove" }, "variableDefinitions": [{ "kind": "VariableDefinition", "variable": { "kind": "Variable", "name": { "kind": "Name", "value": "organizationId" } }, "type": { "kind": "NonNullType", "type": { "kind": "NamedType", "name": { "kind": "Name", "value": "ID" } } } }, { "kind": "VariableDefinition", "variable": { "kind": "Variable", "name": { "kind": "Name", "value": "userId" } }, "type": { "kind": "NonNullType", "type": { "kind": "NamedType", "name": { "kind": "Name", "value": "ID" } } } }], "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "Field", "name": { "kind": "Name", "value": "organizationsUsersRemove" }, "arguments": [{ "kind": "Argument", "name": { "kind": "Name", "value": "organizationId" }, "value": { "kind": "Variable", "name": { "kind": "Name", "value": "organizationId" } } }, { "kind": "Argument", "name": { "kind": "Name", "value": "userId" }, "value": { "kind": "Variable", "name": { "kind": "Name", "value": "userId" } } }], "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "FragmentSpread", "name": { "kind": "Name", "value": "resultFields" } }] } }] } }, ...ResultFieldsFragmentDoc.definitions] };
export const PolicyGroupsCreateDocument = { "kind": "Document", "definitions": [{ "kind": "OperationDefinition", "operation": "mutation", "name": { "kind": "Name", "value": "policyGroupsCreate" }, "variableDefinitions": [{ "kind": "VariableDefinition", "variable": { "kind": "Variable", "name": { "kind": "Name", "value": "payload" } }, "type": { "kind": "NonNullType", "type": { "kind": "NamedType", "name": { "kind": "Name", "value": "PolicyGroupCreatePayload" } } } }], "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "Field", "name": { "kind": "Name", "value": "policyGroupsCreate" }, "arguments": [{ "kind": "Argument", "name": { "kind": "Name", "value": "payload" }, "value": { "kind": "Variable", "name": { "kind": "Name", "value": "payload" } } }], "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "FragmentSpread", "name": { "kind": "Name", "value": "policyGroupFields" } }] } }] } }, ...PolicyGroupFieldsFragmentDoc.definitions] };
export const PolicyGroupsDeleteDocument = { "kind": "Document", "definitions": [{ "kind": "OperationDefinition", "operation": "mutation", "name": { "kind": "Name", "value": "policyGroupsDelete" }, "variableDefinitions": [{ "kind": "VariableDefinition", "variable": { "kind": "Variable", "name": { "kind": "Name", "value": "policyGroupId" } }, "type": { "kind": "NonNullType", "type": { "kind": "NamedType", "name": { "kind": "Name", "value": "ID" } } } }], "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "Field", "name": { "kind": "Name", "value": "policyGroupsDelete" }, "arguments": [{ "kind": "Argument", "name": { "kind": "Name", "value": "policyGroupId" }, "value": { "kind": "Variable", "name": { "kind": "Name", "value": "policyGroupId" } } }], "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "FragmentSpread", "name": { "kind": "Name", "value": "resultFields" } }] } }] } }, ...ResultFieldsFragmentDoc.definitions] };
export const PolicyGroupsUpdateDocument = { "kind": "Document", "definitions": [{ "kind": "OperationDefinition", "operation": "mutation", "name": { "kind": "Name", "value": "policyGroupsUpdate" }, "variableDefinitions": [{ "kind": "VariableDefinition", "variable": { "kind": "Variable", "name": { "kind": "Name", "value": "payload" } }, "type": { "kind": "NonNullType", "type": { "kind": "NamedType", "name": { "kind": "Name", "value": "PolicyGroupUpdatePayload" } } } }], "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "Field", "name": { "kind": "Name", "value": "policyGroupsUpdate" }, "arguments": [{ "kind": "Argument", "name": { "kind": "Name", "value": "payload" }, "value": { "kind": "Variable", "name": { "kind": "Name", "value": "payload" } } }], "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "FragmentSpread", "name": { "kind": "Name", "value": "policyGroupFields" } }] } }] } }, ...PolicyGroupFieldsFragmentDoc.definitions] };
export const PolicyGroupsUsersAddDocument = { "kind": "Document", "definitions": [{ "kind": "OperationDefinition", "operation": "mutation", "name": { "kind": "Name", "value": "policyGroupsUsersAdd" }, "variableDefinitions": [{ "kind": "VariableDefinition", "variable": { "kind": "Variable", "name": { "kind": "Name", "value": "policyGroupId" } }, "type": { "kind": "NonNullType", "type": { "kind": "NamedType", "name": { "kind": "Name", "value": "ID" } } } }, { "kind": "VariableDefinition", "variable": { "kind": "Variable", "name": { "kind": "Name", "value": "userId" } }, "type": { "kind": "NonNullType", "type": { "kind": "NamedType", "name": { "kind": "Name", "value": "ID" } } } }], "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "Field", "name": { "kind": "Name", "value": "policyGroupsUsersAdd" }, "arguments": [{ "kind": "Argument", "name": { "kind": "Name", "value": "policyGroupId" }, "value": { "kind": "Variable", "name": { "kind": "Name", "value": "policyGroupId" } } }, { "kind": "Argument", "name": { "kind": "Name", "value": "userId" }, "value": { "kind": "Variable", "name": { "kind": "Name", "value": "userId" } } }], "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "FragmentSpread", "name": { "kind": "Name", "value": "resultFields" } }] } }] } }, ...ResultFieldsFragmentDoc.definitions] };
export const PolicyGroupsUsersRemoveDocument = { "kind": "Document", "definitions": [{ "kind": "OperationDefinition", "operation": "mutation", "name": { "kind": "Name", "value": "policyGroupsUsersRemove" }, "variableDefinitions": [{ "kind": "VariableDefinition", "variable": { "kind": "Variable", "name": { "kind": "Name", "value": "policyGroupId" } }, "type": { "kind": "NonNullType", "type": { "kind": "NamedType", "name": { "kind": "Name", "value": "ID" } } } }, { "kind": "VariableDefinition", "variable": { "kind": "Variable", "name": { "kind": "Name", "value": "userId" } }, "type": { "kind": "NonNullType", "type": { "kind": "NamedType", "name": { "kind": "Name", "value": "ID" } } } }], "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "Field", "name": { "kind": "Name", "value": "policyGroupsUsersRemove" }, "arguments": [{ "kind": "Argument", "name": { "kind": "Name", "value": "policyGroupId" }, "value": { "kind": "Variable", "name": { "kind": "Name", "value": "policyGroupId" } } }, { "kind": "Argument", "name": { "kind": "Name", "value": "userId" }, "value": { "kind": "Variable", "name": { "kind": "Name", "value": "userId" } } }], "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "FragmentSpread", "name": { "kind": "Name", "value": "resultFields" } }] } }] } }, ...ResultFieldsFragmentDoc.definitions] };
export const UsersCreateDocument = { "kind": "Document", "definitions": [{ "kind": "OperationDefinition", "operation": "mutation", "name": { "kind": "Name", "value": "usersCreate" }, "variableDefinitions": [{ "kind": "VariableDefinition", "variable": { "kind": "Variable", "name": { "kind": "Name", "value": "payload" } }, "type": { "kind": "NonNullType", "type": { "kind": "NamedType", "name": { "kind": "Name", "value": "UserCreatePayload" } } } }], "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "Field", "name": { "kind": "Name", "value": "usersCreate" }, "arguments": [{ "kind": "Argument", "name": { "kind": "Name", "value": "payload" }, "value": { "kind": "Variable", "name": { "kind": "Name", "value": "payload" } } }], "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "FragmentSpread", "name": { "kind": "Name", "value": "userFields" } }] } }] } }, ...UserFieldsFragmentDoc.definitions] };
export const UsersDeleteDocument = { "kind": "Document", "definitions": [{ "kind": "OperationDefinition", "operation": "mutation", "name": { "kind": "Name", "value": "usersDelete" }, "variableDefinitions": [{ "kind": "VariableDefinition", "variable": { "kind": "Variable", "name": { "kind": "Name", "value": "userId" } }, "type": { "kind": "NonNullType", "type": { "kind": "NamedType", "name": { "kind": "Name", "value": "ID" } } } }], "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "Field", "name": { "kind": "Name", "value": "usersDelete" }, "arguments": [{ "kind": "Argument", "name": { "kind": "Name", "value": "userId" }, "value": { "kind": "Variable", "name": { "kind": "Name", "value": "userId" } } }], "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "FragmentSpread", "name": { "kind": "Name", "value": "resultFields" } }] } }] } }, ...ResultFieldsFragmentDoc.definitions] };
export const UsersUpdateDocument = { "kind": "Document", "definitions": [{ "kind": "OperationDefinition", "operation": "mutation", "name": { "kind": "Name", "value": "usersUpdate" }, "variableDefinitions": [{ "kind": "VariableDefinition", "variable": { "kind": "Variable", "name": { "kind": "Name", "value": "payload" } }, "type": { "kind": "NonNullType", "type": { "kind": "NamedType", "name": { "kind": "Name", "value": "UserUpdatePayload" } } } }], "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "Field", "name": { "kind": "Name", "value": "usersUpdate" }, "arguments": [{ "kind": "Argument", "name": { "kind": "Name", "value": "payload" }, "value": { "kind": "Variable", "name": { "kind": "Name", "value": "payload" } } }], "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "FragmentSpread", "name": { "kind": "Name", "value": "userFields" } }] } }] } }, ...UserFieldsFragmentDoc.definitions] };
export const UsersRndStatusSetDocument = { "kind": "Document", "definitions": [{ "kind": "OperationDefinition", "operation": "mutation", "name": { "kind": "Name", "value": "usersRndStatusSet" }, "variableDefinitions": [{ "kind": "VariableDefinition", "variable": { "kind": "Variable", "name": { "kind": "Name", "value": "userId" } }, "type": { "kind": "NonNullType", "type": { "kind": "NamedType", "name": { "kind": "Name", "value": "ID" } } } }, { "kind": "VariableDefinition", "variable": { "kind": "Variable", "name": { "kind": "Name", "value": "rndStatus" } }, "type": { "kind": "NonNullType", "type": { "kind": "NamedType", "name": { "kind": "Name", "value": "Boolean" } } } }], "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "Field", "name": { "kind": "Name", "value": "usersRndStatusSet" }, "arguments": [{ "kind": "Argument", "name": { "kind": "Name", "value": "userId" }, "value": { "kind": "Variable", "name": { "kind": "Name", "value": "userId" } } }, { "kind": "Argument", "name": { "kind": "Name", "value": "rndStatus" }, "value": { "kind": "Variable", "name": { "kind": "Name", "value": "rndStatus" } } }], "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "FragmentSpread", "name": { "kind": "Name", "value": "userAdminFields" } }] } }] } }, ...UserAdminFieldsFragmentDoc.definitions] };
export const InvitationsCreateDocument = { "kind": "Document", "definitions": [{ "kind": "OperationDefinition", "operation": "mutation", "name": { "kind": "Name", "value": "invitationsCreate" }, "variableDefinitions": [{ "kind": "VariableDefinition", "variable": { "kind": "Variable", "name": { "kind": "Name", "value": "payload" } }, "type": { "kind": "NonNullType", "type": { "kind": "NamedType", "name": { "kind": "Name", "value": "InvitationCreatePayload" } } } }], "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "Field", "name": { "kind": "Name", "value": "invitationsCreate" }, "arguments": [{ "kind": "Argument", "name": { "kind": "Name", "value": "payload" }, "value": { "kind": "Variable", "name": { "kind": "Name", "value": "payload" } } }], "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "FragmentSpread", "name": { "kind": "Name", "value": "invitationFields" } }] } }] } }, ...InvitationFieldsFragmentDoc.definitions] };
export const InvitationsUpdateDocument = { "kind": "Document", "definitions": [{ "kind": "OperationDefinition", "operation": "mutation", "name": { "kind": "Name", "value": "invitationsUpdate" }, "variableDefinitions": [{ "kind": "VariableDefinition", "variable": { "kind": "Variable", "name": { "kind": "Name", "value": "invitationId" } }, "type": { "kind": "NonNullType", "type": { "kind": "NamedType", "name": { "kind": "Name", "value": "ID" } } } }, { "kind": "VariableDefinition", "variable": { "kind": "Variable", "name": { "kind": "Name", "value": "payload" } }, "type": { "kind": "NonNullType", "type": { "kind": "NamedType", "name": { "kind": "Name", "value": "InvitationUpdatePayload" } } } }], "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "Field", "name": { "kind": "Name", "value": "invitationsUpdate" }, "arguments": [{ "kind": "Argument", "name": { "kind": "Name", "value": "invitationId" }, "value": { "kind": "Variable", "name": { "kind": "Name", "value": "invitationId" } } }, { "kind": "Argument", "name": { "kind": "Name", "value": "payload" }, "value": { "kind": "Variable", "name": { "kind": "Name", "value": "payload" } } }], "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "FragmentSpread", "name": { "kind": "Name", "value": "invitationFields" } }] } }] } }, ...InvitationFieldsFragmentDoc.definitions] };
export const InvitationsDeleteDocument = { "kind": "Document", "definitions": [{ "kind": "OperationDefinition", "operation": "mutation", "name": { "kind": "Name", "value": "invitationsDelete" }, "variableDefinitions": [{ "kind": "VariableDefinition", "variable": { "kind": "Variable", "name": { "kind": "Name", "value": "invitationId" } }, "type": { "kind": "NonNullType", "type": { "kind": "NamedType", "name": { "kind": "Name", "value": "ID" } } } }], "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "Field", "name": { "kind": "Name", "value": "invitationsDelete" }, "arguments": [{ "kind": "Argument", "name": { "kind": "Name", "value": "invitationId" }, "value": { "kind": "Variable", "name": { "kind": "Name", "value": "invitationId" } } }], "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "FragmentSpread", "name": { "kind": "Name", "value": "invitationFields" } }] } }] } }, ...InvitationFieldsFragmentDoc.definitions] };
export const InvitationsStatesUpdateDocument = { "kind": "Document", "definitions": [{ "kind": "OperationDefinition", "operation": "mutation", "name": { "kind": "Name", "value": "invitationsStatesUpdate" }, "variableDefinitions": [{ "kind": "VariableDefinition", "variable": { "kind": "Variable", "name": { "kind": "Name", "value": "invitationId" } }, "type": { "kind": "NonNullType", "type": { "kind": "NamedType", "name": { "kind": "Name", "value": "ID" } } } }, { "kind": "VariableDefinition", "variable": { "kind": "Variable", "name": { "kind": "Name", "value": "active" } }, "type": { "kind": "NonNullType", "type": { "kind": "NamedType", "name": { "kind": "Name", "value": "Boolean" } } } }], "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "Field", "name": { "kind": "Name", "value": "invitationsStatesUpdate" }, "arguments": [{ "kind": "Argument", "name": { "kind": "Name", "value": "invitationId" }, "value": { "kind": "Variable", "name": { "kind": "Name", "value": "invitationId" } } }, { "kind": "Argument", "name": { "kind": "Name", "value": "active" }, "value": { "kind": "Variable", "name": { "kind": "Name", "value": "active" } } }], "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "FragmentSpread", "name": { "kind": "Name", "value": "invitationFields" } }] } }] } }, ...InvitationFieldsFragmentDoc.definitions] };
export const InvitationsRespondDocument = { "kind": "Document", "definitions": [{ "kind": "OperationDefinition", "operation": "mutation", "name": { "kind": "Name", "value": "invitationsRespond" }, "variableDefinitions": [{ "kind": "VariableDefinition", "variable": { "kind": "Variable", "name": { "kind": "Name", "value": "invitationId" } }, "type": { "kind": "NonNullType", "type": { "kind": "NamedType", "name": { "kind": "Name", "value": "ID" } } } }, { "kind": "VariableDefinition", "variable": { "kind": "Variable", "name": { "kind": "Name", "value": "acceptInvitation" } }, "type": { "kind": "NonNullType", "type": { "kind": "NamedType", "name": { "kind": "Name", "value": "Boolean" } } } }], "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "Field", "name": { "kind": "Name", "value": "invitationsRespond" }, "arguments": [{ "kind": "Argument", "name": { "kind": "Name", "value": "invitationId" }, "value": { "kind": "Variable", "name": { "kind": "Name", "value": "invitationId" } } }, { "kind": "Argument", "name": { "kind": "Name", "value": "acceptInvitation" }, "value": { "kind": "Variable", "name": { "kind": "Name", "value": "acceptInvitation" } } }], "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "FragmentSpread", "name": { "kind": "Name", "value": "invitationFields" } }] } }] } }, ...InvitationFieldsFragmentDoc.definitions] };
