/*
 * Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */

import React, { FunctionComponent, PropsWithChildren, ReactElement } from "react";
import { OtaManager } from "@sade/data-access";
import { OtaPageProps } from "./ota-page-props";
import SettingsPageOtaInjectable from "./settings-page-ota.injectable";

export const SettingsPageOtaAuto: FunctionComponent<OtaPageProps> = (
  props: PropsWithChildren<OtaPageProps>
): ReactElement => {
  const $props = {
    ...props,
    otaManager: OtaManager.getInstance(),
  };
  return <SettingsPageOtaInjectable {...$props} />;
};
