/*
 * Copyright (C) 2022 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 *
 */
import { User } from "./User";
import { AWSUser } from "./AWSUser";
import { Organization } from "./Organization";
import { AWSOrganization } from "./AWSOrganization";
export function verifyUserType(user) {
    if (!AWSUser.instanceOf(user)) {
        throw new Error(`Wrong ${User.constructor.name} implementation: expect '${AWSUser.constructor.name}', received '${user.constructor.name}'`);
    }
}
export function verifyOrganizationType(organization) {
    if (!AWSOrganization.instanceOf(organization)) {
        throw new Error(`Wrong ${Organization.constructor.name} implementation: expect '${AWSUser.constructor.name}', received '${organization.constructor.name}'`);
    }
}
