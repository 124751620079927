/*
 * Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 *
 */
/*
lightStatus (string)
String encoded version of different light status flags & values
Encoding done to minimize the RAM consumption of shadow, with TI CC3200 devices RAM is on critical level
Fields are following
1: Light on/off (boolean, 0/1), 1 = on, 0 = off
2: Light brightness % (integer), 100 = full brightness, 1 = minimum brightness
*/
import { booleanToString, numberToString, parseStatusFromString, parseStatusToString, toBoolean, toNumber, } from "./StatusParser";
const fields = ["lightOn", "lightBrightness"];
const convertToValue = {
    lightOn: toBoolean,
    lightBrightness: toNumber,
};
const convertToString = {
    lightOn: booleanToString,
    lightBrightness: numberToString,
};
export const parseLightStatusFromString = (value) => parseStatusFromString(value, fields, convertToValue, fields.length);
export const parseLightStatusToString = (lightStatus) => parseStatusToString(lightStatus, fields, convertToString);
