/*
 * Copyright (C) 2022 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 *
 */
export function policyListContainsPermission(policies, permission) {
    const lowercaseAction = permission.toLowerCase();
    return policies
        .map((policy) => policy.toLowerCase())
        .some((policy) => policy.endsWith("*") ? lowercaseAction.startsWith(policy.replace("*", "")) : lowercaseAction === policy);
}
/**
 * Strips users-service entity id to a non-prefixed version.
 * TODO: update API so that prefixes are no longer leaked to the clients.
 * @param id
 */
export function prefixlessId(id) {
    var _a;
    const parts = id.split("/");
    const strippedId = (_a = parts[1]) !== null && _a !== void 0 ? _a : parts[0];
    if (!strippedId) {
        throw new Error(`Not a valid entity id: '${id}'`);
    }
    return strippedId;
}
export function equalInvitationObjects(list1, list2) {
    if (list1.length !== list2.length)
        return false;
    for (const obj of list1) {
        const matching = list2.find((obj2) => obj.id === obj2.id && obj.accessType === obj2.accessType);
        if (!matching)
            return false;
    }
    return true;
}
