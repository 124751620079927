/*
 * Copyright (C) 2022 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */
export const SUPPORTED_LANGUAGES = ["en", "fi"];
export function isDefined(value) {
    return value != null;
}
/**
 * Converts string to a number or undefined. NaN values result into undefined
 *
 * @param value
 *    string potentially representing a number
 */
export function toNumber(value) {
    const n = Number(value);
    return Number.isNaN(n) ? undefined : n;
}
/**
 * Verifies language code validity against supported language codes {@link SUPPORTED_LANGUAGES}
 * @param {string} languageCode language code string
 * @returns {boolean}
 */
export function isValidLanguageCode(languageCode) {
    return SUPPORTED_LANGUAGES.includes(languageCode);
}
