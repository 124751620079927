/*
 * Copyright (C) 2022 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 *
 */
import { __awaiter } from "tslib";
import { DeviceGroup } from "./DeviceGroup";
import { AppSyncClientFactory } from "../backend/AppSyncClientFactory";
import { Service } from "../backend/AppSyncClientProvider";
import { DeviceGroupsDeleteDocument, DeviceGroupsDevicesAddDocument, DeviceGroupsDevicesRemoveDocument, } from "../../generated/gqlDevice";
import { AuthWrapper } from "../auth/AuthWrapper";
import { throwGQLError } from "../utils/Utils";
import { PromiseSemaphore } from "../utils/PromiseSemaphore";
import { AWSThing } from "./AWSThing";
const ORGANIZATION_KEY = "organization";
const IOT_MAX_CHILD_GROUPS = 100;
export class AWSThingGroup extends DeviceGroup {
    constructor(backend, params) {
        super(params);
        this.entityType = AWSThingGroup;
        this.devicesSemaphore = new PromiseSemaphore(() => this.backend.getDeviceGroupDevices(this));
        this.childGroupsSemaphore = new PromiseSemaphore(() => this.backend.getDeviceGroups({ parent: this }));
        this.backend = backend;
    }
    static instanceOf(value) {
        return value instanceof AWSThingGroup;
    }
    static getLocalisedName(groupAttributes, defaultValue, language = "label_default") {
        var _a, _b;
        return (_b = (_a = groupAttributes.find((attr) => attr.key === language)) === null || _a === void 0 ? void 0 : _a.value) !== null && _b !== void 0 ? _b : defaultValue;
    }
    static getCurrentUserOrganization() {
        return __awaiter(this, void 0, void 0, function* () {
            const claims = yield AuthWrapper.getCurrentAuthenticatedUserClaims();
            return claims === null || claims === void 0 ? void 0 : claims.homeOrganizationId;
        });
    }
    getLabel() {
        return AWSThingGroup.getLocalisedName(this.getAttributes(), this.getId());
    }
    getOrganization() {
        var _a;
        return (_a = this.getAttributes().find((attribute) => attribute.key === ORGANIZATION_KEY)) === null || _a === void 0 ? void 0 : _a.value;
    }
    getGroups() {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.childGroupsSemaphore.guard();
            return this.unguardedGetGroups();
        });
    }
    getDevices() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.backend.getDeviceGroupDevices(this);
        });
    }
    addDevice(device) {
        return __awaiter(this, void 0, void 0, function* () {
            const deviceId = typeof device === "string" ? device : device.getId();
            const devices = yield this.getDevices();
            if (devices.find((dev) => dev.getId() === deviceId)) {
                console.log(`Attempted to add device '${deviceId}' to group '${this.getId()}' but it was already there!`);
                return;
            }
            yield this.backendAddDevice(deviceId);
            const addedDevice = yield this.toDevice(device);
            if (AWSThing.instanceOf(addedDevice)) {
                this.backend.entityRelationCache.link(this, addedDevice);
            }
            else {
                throw new Error(`Failed to add device ${deviceId} to group ${this.getId()}`);
            }
        });
    }
    removeDevice(device) {
        return __awaiter(this, void 0, void 0, function* () {
            const deviceId = typeof device === "string" ? device : device.getId();
            const devices = yield this.getDevices();
            if (!devices.find((dev) => dev.getId() === deviceId)) {
                console.log(`Attempted to remove device '${deviceId}' from group '${this.getId()}' but it was not there!`);
                return;
            }
            yield this.backendRemoveDevice(deviceId);
            const removedDevice = yield this.toDevice(device);
            if (AWSThing.instanceOf(removedDevice)) {
                this.backend.entityRelationCache.unlink(this, removedDevice);
            }
            else {
                throw new Error(`Failed to remove device ${deviceId} from group ${this.getId()}`);
            }
        });
    }
    /// AWSThingGroup specific public methods
    delete() {
        return __awaiter(this, void 0, void 0, function* () {
            const organizationId = yield this.getOrganizationIdForRequest();
            try {
                const appSyncClient = AppSyncClientFactory.createProvider().getTypedClient(Service.DEVICE);
                yield appSyncClient.mutate(DeviceGroupsDeleteDocument, {
                    groupId: this.getId(),
                    organizationId: organizationId,
                });
                yield this.backend.removeLocal(this);
                yield this.notifyDelete();
            }
            catch (error) {
                console.error("Failed to remove group", error);
                throw error;
            }
        });
    }
    // Private methods
    childGroupCanBeAdded() {
        return this.childGroupsSemaphore.invoked() && this.unguardedGetGroups().length < IOT_MAX_CHILD_GROUPS;
    }
    canBeDeleted() {
        const noChildGroups = this.childGroupsSemaphore.invoked() && this.unguardedGetGroups().length === 0;
        const noDevices = this.devicesSemaphore.invoked() && this.unguardedGetDevices().length === 0;
        return noChildGroups && noDevices;
    }
    onRelationChange(change) {
        return __awaiter(this, void 0, void 0, function* () {
            if (change.ofType(AWSThing)) {
                yield this.notifyDeviceChange();
            }
            else if (change.ofType(AWSThingGroup)) {
                yield this.notifyGroupChange();
            }
        });
    }
    backendAddDevice(deviceId) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            const organizationId = yield this.getOrganizationIdForRequest();
            const appSyncClient = AppSyncClientFactory.createProvider().getTypedClient(Service.DEVICE);
            const response = yield appSyncClient.mutate(DeviceGroupsDevicesAddDocument, {
                deviceId,
                groupId: this.getId(),
                overrideDynamics: false,
                organizationId,
            });
            if (response.errors || !((_a = response.data) === null || _a === void 0 ? void 0 : _a.deviceGroupsDevicesAdd)) {
                throwGQLError(response, `Failed to add device ${deviceId} to group ${this.getId()}`);
            }
        });
    }
    backendRemoveDevice(deviceId) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            const organizationId = yield this.getOrganizationIdForRequest();
            const appSyncClient = AppSyncClientFactory.createProvider().getTypedClient(Service.DEVICE);
            const response = yield appSyncClient.mutate(DeviceGroupsDevicesRemoveDocument, {
                deviceId,
                groupId: this.getId(),
                organizationId,
            });
            if (response.errors || !((_a = response.data) === null || _a === void 0 ? void 0 : _a.deviceGroupsDevicesRemove)) {
                throwGQLError(response, `Failed to remove device ${deviceId} from group ${this.getId()}`);
            }
        });
    }
    toDevice(device) {
        return __awaiter(this, void 0, void 0, function* () {
            return typeof device !== "string" ? device : yield this.backend.getDevice(device);
        });
    }
    unguardedGetGroups() {
        const groupRecords = this.backend.entityRelationCache.listEntityRecordsFor(this, AWSThingGroup);
        return groupRecords
            .filter((record) => { var _a; return ((_a = record.metadata) === null || _a === void 0 ? void 0 : _a.parentId) !== record.entity.getId(); })
            .map((record) => record.entity);
    }
    unguardedGetDevices() {
        return this.backend.entityRelationCache.listFor(this, AWSThing);
    }
    getOrganizationIdForRequest() {
        return __awaiter(this, void 0, void 0, function* () {
            const organizationId = this.getOrganization();
            if (organizationId) {
                return organizationId;
            }
            const userOrganization = yield AWSThingGroup.getCurrentUserOrganization();
            if (!userOrganization) {
                throw new Error("Could not resolve organization!");
            }
            return userOrganization;
        });
    }
    /// STATIC METHODS
    notifyGroupChange() {
        return __awaiter(this, void 0, void 0, function* () {
            const groups = yield this.getGroups();
            this.notifyAction((observer) => { var _a; return (_a = observer.onGroupsChanged) === null || _a === void 0 ? void 0 : _a.call(observer, groups, this); });
        });
    }
    notifyDeviceChange() {
        return __awaiter(this, void 0, void 0, function* () {
            const devices = yield this.getDevices();
            this.notifyAction((observer) => { var _a; return (_a = observer.onDevicesChanged) === null || _a === void 0 ? void 0 : _a.call(observer, devices, this); });
        });
    }
    notifyDelete() {
        return __awaiter(this, void 0, void 0, function* () {
            this.notifyAction((observer) => { var _a; return (_a = observer.onDelete) === null || _a === void 0 ? void 0 : _a.call(observer, this); });
        });
    }
}
