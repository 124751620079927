/*
 * Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */

import { Button, Grid, List } from "@material-ui/core";
import React, { ReactNode } from "react";
import { OtaManager, OtaState, OtaUpdate, OtaUpdateObserver, OtaUpdateState } from "@sade/data-access";
import SettingsListItem from "../settings-list-item";
import OtaPackageInformationItem from "./ota-package-information-item";
import { OtaPageProps } from "./ota-page-props";
import OtaProgressInformation from "./ota-progress-information";
import { translations } from "../../../../generated/translationHelper";
import { semverishCompare } from "./semverCompare";

interface Props extends OtaPageProps {
  otaManager: OtaManager;
}

interface State {
  availableUpdates: OtaUpdate[];
  selectedOtaPackage?: string;
  currentOtaUpdate?: OtaUpdateState;
}

export default class SettingsPageOtaInjectable extends React.Component<Props, State> implements OtaUpdateObserver {
  public constructor(props: Props) {
    super(props);
    this.state = {
      availableUpdates: [],
      selectedOtaPackage: props.deviceState.otaId ?? undefined,
    };
  }

  public componentDidMount(): void {
    // Force OtaManager to listen for updates sent with active device's ID
    OtaManager.getInstance().onReceiversChanged([this.props.selectedDevice.getId()]);
    this.props.otaManager.addObserver(this);
    this.updateAvailableOTAs(true).then();
  }

  public componentDidUpdate(prevProps: Readonly<Props>): void {
    if (this.props.deviceState !== prevProps.deviceState) {
      this.setState({
        selectedOtaPackage: this.props.deviceState.otaId ?? undefined,
      });
      this.updateAvailableOTAs(true).then();
    }
  }

  public componentWillUnmount(): void {
    // This is the only UI listening for OTA progress
    this.props.otaManager.onReceiversChanged([]);
    this.props.otaManager.removeObserver(this);
  }

  public async onOtaUpdateState(state: OtaUpdateState): Promise<void> {
    if (state.deviceId === this.props.deviceState.deviceId) {
      this.setState({ currentOtaUpdate: state });
    }
  }

  private isOtaOngoing = (): boolean => {
    return !!this.state.currentOtaUpdate && this.state.currentOtaUpdate.updateState === OtaState.InProgress;
  };

  private updateAvailableOTAs = async (force?: boolean): Promise<void> => {
    const updates = await this.props.otaManager.getOtaUpdates(this.props.selectedDevice.getType(), force, true);
    console.log("selected ota package is " + this.state.selectedOtaPackage);
    this.setState({
      availableUpdates: updates
        .filter((update: OtaUpdate) => update.enabled)
        .sort((a, b) => semverishCompare(b.firmwareVersion, a.firmwareVersion)),
    });
  };

  private handleSelectOta = (id: string): void => {
    this.setState({ selectedOtaPackage: id });
  };

  private startInstall = (): void => {
    const ota = this.state.selectedOtaPackage;

    if (!ota) {
      console.warn("No OTA package selected");
      return;
    }

    if (!this.isOtaOngoing()) {
      console.log("Triggering OTA update");
      this.props.otaManager.triggerDeviceOtaUpdate(this.props.deviceState.deviceId, ota).then();
      this.setState({ currentOtaUpdate: { deviceId: this.props.deviceState.deviceId } });
    } else {
      console.warn("OTA update already in progress");
    }
  };

  private cancelInstall = (): void => {
    if (this.state.currentOtaUpdate) {
      this.props.otaManager.cancelDeviceOtaUpdate(this.state.currentOtaUpdate.deviceId).then();
    }
  };

  private getInstallButton(): JSX.Element {
    return (
      <Grid item>
        <Button
          disabled={!this.state.selectedOtaPackage || Boolean(this.state.currentOtaUpdate)}
          variant="contained"
          color="primary"
          onClick={this.startInstall}
        >
          {translations.deviceSettings.buttons.installOta()}
        </Button>
      </Grid>
    );
  }

  private getCancelButton(): ReactNode {
    return (
      <Grid item>
        <Button variant="contained" onClick={this.cancelInstall}>
          {translations.deviceSettings.buttons.cancelOta()}
        </Button>
      </Grid>
    );
  }

  private getCloseButton(): ReactNode {
    return (
      <Grid item>
        <Button variant="contained" color="primary" onClick={this.props.onCloseSettings}>
          {translations.common.buttons.close()}
        </Button>
      </Grid>
    );
  }

  private renderSectionHeader(text: string): ReactNode {
    return <h4>{text}</h4>;
  }

  private renderOtaDeviceInformation(): ReactNode {
    const firmwareVersion = this.props.deviceState.firmwareVersion ?? "N/A";
    return (
      <List>
        <SettingsListItem label={translations.deviceSettings.texts.firmwareVersion()}>
          {firmwareVersion}
        </SettingsListItem>
        <SettingsListItem label={translations.deviceSettings.texts.otaId()}>
          {this.props.deviceState.otaId || translations.common.texts.notAvailable()}
        </SettingsListItem>
      </List>
    );
  }

  private renderOtaProgressInformation(): ReactNode {
    return this.state.currentOtaUpdate ? (
      <OtaProgressInformation update={this.state.currentOtaUpdate} />
    ) : (
      <p>{translations.deviceSettings.texts.noInstallationInProgress()}</p>
    );
  }

  private renderUpdatePackageListing(): ReactNode {
    if (!this.state.availableUpdates || this.state.availableUpdates.length === 0) {
      return <p>{translations.deviceSettings.texts.noUpdateAvailable()}</p>;
    }
    // force contents to be scrollable - otherwise the whole popup will be scrollable.
    return (
      <div style={{ height: "300px", overflowY: "auto" }}>
        {this.state.availableUpdates.map((update: OtaUpdate): JSX.Element => {
          return (
            <OtaPackageInformationItem
              key={update.otaId}
              item={update}
              selected={this.state.selectedOtaPackage === update.otaId}
              onClick={(): void => this.handleSelectOta(update.otaId)}
            />
          );
        })}
      </div>
    );
  }

  public render(): ReactNode {
    return (
      <Grid container={true} spacing={2}>
        <Grid item={true} xs={6}>
          {this.renderSectionHeader(translations.deviceSettings.texts.deviceFirmwareInformation())}
          {this.renderOtaDeviceInformation()}
          {this.renderSectionHeader(translations.deviceSettings.texts.otaUpdateProcess())}
          {this.renderOtaProgressInformation()}
        </Grid>
        <Grid item={true} xs={6}>
          {this.renderSectionHeader(translations.deviceSettings.texts.availableOtaPackages())}
          {this.renderUpdatePackageListing()}
        </Grid>
        <Grid item={true} container={true} justifyContent="center" spacing={4}>
          {!this.isOtaOngoing() ? this.getInstallButton() : this.getCancelButton()}
          {this.getCloseButton()}
        </Grid>
      </Grid>
    );
  }
}
