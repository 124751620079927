/*
 * Copyright (C) 2023 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */
import { ConnectivityUnitHWState } from "../ConnectivityUnitHW/ConnectivityUnitHWState";
export class GateUnitHWState extends ConnectivityUnitHWState {
    get radioConnected() {
        return this.reported.radioConnected;
    }
}
