/* Copyright */
export function semverishCompare(v1: string, v2: string): number {
  const v1p = v1
    .split(".")
    .map((n) => Number(n))
    .filter((n) => !Number.isNaN(n));
  const v2p = v2
    .split(".")
    .map((n) => Number(n))
    .filter((n) => !Number.isNaN(n));
  const cycles = Math.max(v1p.length, v2p.length);

  for (let i = 0; i < cycles; i++) {
    const n1 = v1p.shift() ?? 0;
    const n2 = v2p.shift() ?? 0;
    const diff = n1 - n2;

    if (diff !== 0) return diff;
  }
  return 0;
}
