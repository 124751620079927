/*
 * Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */

import { Grid, List, Paper, Radio, Typography } from "@material-ui/core";
import React, { FunctionComponent, PropsWithChildren, ReactElement } from "react";
import { OtaUpdate } from "@sade/data-access";
import SettingsListItem from "../settings-list-item";
import { translations } from "../../../../generated/translationHelper";

interface Props {
  item: OtaUpdate;
  onClick: () => void;
  selected: boolean;
}

function bytesToKb(bytes: number): number {
  return bytes / 1000;
}

const OtaPackageInformationItem: FunctionComponent<Props> = (props: PropsWithChildren<Props>): ReactElement => {
  return (
    <Paper className={"ota-package-information-item"} onClick={props.onClick}>
      <Grid container={true} alignItems={"center"}>
        <Grid item={true} xs={1}>
          <Radio onClick={props.onClick} checked={props.selected} />
        </Grid>
        <Grid item={true} xs={11}>
          <List>
            <SettingsListItem label={translations.deviceSettings.texts.version()}>
              {props.item.firmwareVersion}
            </SettingsListItem>
            <SettingsListItem label={translations.deviceSettings.texts.size()}>
              {bytesToKb(props.item?.size ?? 0).toFixed(2)}
            </SettingsListItem>
            <SettingsListItem
              label={translations.deviceSettings.texts.description()}
              secondary={
                <Typography component="span" variant="body2" display="inline">
                  {" "}
                  {props.item.description}{" "}
                </Typography>
              }
            />
          </List>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default OtaPackageInformationItem;
