/*
 * Copyright (C) 2023 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 *
 */

import React, { Component, ReactNode } from "react";
import { IconButton, Grid } from "@material-ui/core";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";

interface Props {
  maximum: number;
  index: number;
  onDeviceSelect: (index: number) => void;
}

export default class PageChanger extends Component<Props> {
  public constructor(props: Props) {
    super(props);
  }

  private navPrevDevice = (index: number): void => {
    this.props.onDeviceSelect(index - 1);
  };

  private navNextDevice = (index: number): void => {
    this.props.onDeviceSelect(index + 1);
  };

  public render(): ReactNode {
    return (
      <Grid container spacing={1} style={{ alignItems: "center" }}>
        <Grid item>
          <IconButton
            disabled={this.props.index === 0}
            onClick={(): void => this.navPrevDevice(this.props.index)}
            style={{ margin: "0rem" }}
            size="small"
          >
            <KeyboardArrowLeftIcon />
          </IconButton>
        </Grid>
        <Grid item className="device-nav-tab-indicator">
          {`${this.props.index + 1}/${this.props.maximum}`}
        </Grid>
        <Grid item>
          <IconButton
            disabled={this.props.index === this.props.maximum - 1}
            onClick={(): void => this.navNextDevice(this.props.index)}
            style={{ margin: "0rem" }}
            size="small"
          >
            <KeyboardArrowRightIcon />
          </IconButton>
        </Grid>
      </Grid>
    );
  }
}
