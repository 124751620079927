/*
 * Copyright (C) 2022 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */
import { __awaiter } from "tslib";
import { User } from "./User";
import { AppSyncClientFactory } from "../backend/AppSyncClientFactory";
import { Service } from "../backend/AppSyncClientProvider";
import { ResultType, UsersDeleteDocument, UsersGrantsListDocument, UsersPolicyGroupsListDocument, UsersUpdateDocument, } from "../../generated/gqlUsers";
import { AWSOrganization } from "./AWSOrganization";
import { verifyOrganizationType } from "./AWSTypeUtils";
import { AWSPolicyGroup } from "./AWSPolicyGroup";
import { AuthWrapper } from "../auth/AuthWrapper";
import { isDefined } from "../../common/Utils";
import { throwGQLError } from "../utils/Utils";
import { PromiseSemaphore } from "../utils/PromiseSemaphore";
import { PromiseWaitList } from "../utils/PromiseWaitList";
import { policyListContainsPermission } from "./Utils";
export class AWSUser extends User {
    constructor(backend, parameters) {
        super(parameters);
        this.backend = backend;
        this.entityType = AWSUser;
        this.policyGroupSemaphore = new PromiseSemaphore(() => this.backendFetchPolicyGroups());
        // caches getUserGrants results
        // TODO: these need to react to both user's PolicyGroup and Organization changes. Now the cache never updates.
        // TODO: this is dependent on PolicyGroup's policies - it is not enough to listen to relation changes from EntityPairCache
        this.grantCache = new Map();
    }
    static instanceOf(value) {
        return value instanceof AWSUser;
    }
    getHomeOrganization() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.homeOrganization) {
                this.homeOrganization = yield this.backendGetHomeOrganization();
            }
            return this.homeOrganization;
        });
    }
    getOrganizations() {
        return __awaiter(this, void 0, void 0, function* () {
            // TODO: backend does not have an implementation for this
            return [yield this.getHomeOrganization()];
        });
    }
    belongsToPolicyGroup(policyGroupId) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.policyGroupSemaphore.guard();
            const policyGroups = this.backend.entityRelationCache.listFor(this, AWSPolicyGroup);
            for (const pg of policyGroups) {
                if (pg.getId() === policyGroupId) {
                    return true;
                }
            }
            return false;
        });
    }
    hasGrants(organizationId, ...actions) {
        return __awaiter(this, void 0, void 0, function* () {
            const grants = yield this.getUserGrants(organizationId);
            return actions.reduce((hasPermission, action) => {
                return hasPermission && grants.some((grant) => policyListContainsPermission(grant.policies, action));
            }, true);
        });
    }
    hasPolicyGroupNamed(policyGroupName, organizationId) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            const organization = organizationId !== null && organizationId !== void 0 ? organizationId : (_a = (yield AuthWrapper.getCurrentAuthenticatedUserClaims())) === null || _a === void 0 ? void 0 : _a.homeOrganizationId;
            yield this.policyGroupSemaphore.guard();
            return this.backend.entityRelationCache
                .listFor(this, AWSPolicyGroup)
                .filter((policyGroup) => policyGroup.getOrganizationId() === organization)
                .some((policyGroup) => policyGroup.getName() === policyGroupName);
        });
    }
    getPolicyGroups(organizationId) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.policyGroupSemaphore.guard();
            return organizationId
                ? this.backend.entityRelationCache
                    .listFor(this, AWSPolicyGroup)
                    .filter((pg) => pg.getOrganizationId() === organizationId)
                : this.backend.entityRelationCache.listFor(this, AWSPolicyGroup);
        });
    }
    delete() {
        var _a, _b, _c, _d;
        return __awaiter(this, void 0, void 0, function* () {
            const client = AppSyncClientFactory.createProvider().getTypedClient(Service.USERS);
            const result = yield client.mutate(UsersDeleteDocument, { userId: this.id });
            if (!result.data || ((_a = result.data.usersDelete) === null || _a === void 0 ? void 0 : _a.result) !== ResultType.Ok) {
                throwGQLError(result, (_d = (_c = (_b = result.data) === null || _b === void 0 ? void 0 : _b.usersDelete) === null || _c === void 0 ? void 0 : _c.failureReason) !== null && _d !== void 0 ? _d : "Failed to delete user");
            }
            yield this.backend.cleanEntityFromCaches(this.id);
            this.notifyAction((observer) => { var _a; return (_a = observer.onDelete) === null || _a === void 0 ? void 0 : _a.call(observer, this); });
            this.clearObservers();
        });
    }
    onRelationChange(change) {
        if (change.ofType(AWSPolicyGroup) && this.policyGroupSemaphore.invoked()) {
            const policyGroups = this.backend.entityRelationCache.listFor(this, AWSPolicyGroup);
            // TODO:  we could potentially trash the grants cache here, but it would cause the grants to be
            //        reloaded at least one extra time:
            //          someone asks for grants, then policy groups. policy groups get updated for the first time, and cause
            //          this callback to ping, wiping grants. very annoying
            this.notifyAction((observer) => { var _a; return (_a = observer.onPolicyGroupsChange) === null || _a === void 0 ? void 0 : _a.call(observer, policyGroups, this); });
        }
        else if (change.ofType(AWSOrganization)) {
            // TODO: invocation guard once getOrganizations is actually implemented
            const organizations = this.backend.entityRelationCache.listFor(this, AWSOrganization);
            this.notifyAction((observer) => { var _a; return (_a = observer.onOrganizationsChange) === null || _a === void 0 ? void 0 : _a.call(observer, organizations, this); });
        }
    }
    setDisplayName(value) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            const client = AppSyncClientFactory.createProvider().getTypedClient(Service.USERS);
            const response = yield client.mutate(UsersUpdateDocument, {
                payload: {
                    id: this.getId(),
                    displayName: value,
                },
            });
            if (!((_a = response.data) === null || _a === void 0 ? void 0 : _a.usersUpdate)) {
                throwGQLError(response);
            }
            else {
                this.displayName = value;
            }
        });
    }
    backendGetHomeOrganization() {
        return __awaiter(this, void 0, void 0, function* () {
            const organization = yield this.backend.getOrganization(this.homeOrganizationId);
            if (!organization) {
                throw new Error(`Home organization of user '${this.id}' does not exist`);
            }
            verifyOrganizationType(organization);
            return organization;
        });
    }
    backendFetchPolicyGroups() {
        var _a, _b, _c;
        return __awaiter(this, void 0, void 0, function* () {
            const client = AppSyncClientFactory.createProvider().getTypedClient(Service.USERS);
            // TODO: nextToken
            const response = yield client.query(UsersPolicyGroupsListDocument, {
                userId: this.getId(),
            });
            // TODO: same efficiency problem as AWSOrganization::getUsers. OrganizationBackend's caching should alleviate this.
            const policyGroups = yield Promise.all((_c = (_b = (_a = response.data.usersPolicyGroupsList) === null || _a === void 0 ? void 0 : _a.groups) === null || _b === void 0 ? void 0 : _b.map((id) => this.backend.getPolicyGroup(id))) !== null && _c !== void 0 ? _c : []);
            this.backend.entityRelationCache.replaceTypedLinks(this, AWSPolicyGroup, policyGroups.filter(isDefined));
        });
    }
    getUserGrants(organizationId) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function* () {
            // TODO: for the current users, we could use token claims instead of reaching to backend
            if (this.grantCache.has(organizationId)) {
                return this.grantCache.get(organizationId).get();
            }
            this.grantCache.set(organizationId, new PromiseWaitList(this.email + ".grants"));
            const client = AppSyncClientFactory.createProvider().getTypedClient(Service.USERS);
            const response = yield client.query(UsersGrantsListDocument, {
                userId: this.getId(),
                organizationId,
            });
            // ignoring grants errors - no grants, no permissions
            const grants = (_b = (_a = response.data.usersGrantsList) === null || _a === void 0 ? void 0 : _a.grants) !== null && _b !== void 0 ? _b : [];
            this.grantCache.get(organizationId).set(grants);
            return grants;
        });
    }
}
