/* Copyright */

import { FormControl, InputLabel, MenuItem, Select, PropTypes } from "@material-ui/core";
import React, { ChangeEvent, Component, Fragment, ReactNode } from "react";
import { Maybe } from "../../../../../types/aliases";
import ConfirmationDialog from "../../../../ui/confirmation-dialog";

type Selection = { key: string; label: string | number | boolean };

interface Props {
  label?: string;
  disabled?: boolean;
  selectionList: Selection[];
  emptySelectionItem?: string;
  onSelect: (selection?: number) => void;
  currentSelection?: number;
  "data-testid"?: string;
  variant?: "standard" | "outlined" | "filled";
  fullWidth?: boolean;
  margin?: PropTypes.Margin;
  confirmationTitle: string;
  confirmationMessage: string;
}

interface State {
  selected?: string;
  pendingSelection?: number;
}

export default class DangerousDropdown extends Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.confirmSelection = this.confirmSelection.bind(this);
    this.cancelSelection = this.cancelSelection.bind(this);
    this.state = {
      selected: this.getSelection(this.props.currentSelection),
    };
  }

  public componentDidUpdate(prevProps: Readonly<Props>): void {
    if (prevProps.currentSelection !== this.props.currentSelection) {
      this.setState({
        selected: this.getSelection(this.props.currentSelection),
      });
    }
  }

  private getSelection(index?: number): Maybe<string> {
    return index != null ? this.props.selectionList[index]?.key : undefined;
  }

  private getLabel(): Maybe<JSX.Element> {
    if (this.props.label) {
      return <InputLabel shrink={true}>{this.props.label}</InputLabel>;
    }
  }

  private getValue(): string {
    return this.state.selected ?? "";
  }

  private getSelectionList(): Maybe<JSX.Element[]> {
    if (this.props.selectionList && this.props.selectionList.length > 0) {
      return this.props.selectionList.map((selection: Selection, index: number) => {
        return (
          <MenuItem
            key={selection.key}
            value={selection.key}
            data-testid={this.props["data-testid"] ? `${this.props["data-testid"]}-${index}` : undefined}
          >
            {selection.label}
          </MenuItem>
        );
      });
    }
  }

  private getEmptySelection(): Maybe<JSX.Element> {
    if (this.props.emptySelectionItem) {
      return <MenuItem value="">{this.props.emptySelectionItem}</MenuItem>;
    }
  }

  private handleSelection = (event: ChangeEvent<{ name?: string; value: unknown }>): void => {
    let index: Maybe<number> = undefined;
    const value = (event.target?.value as string) ?? undefined;

    if (value != null) {
      const foundIndex = this.props.selectionList.findIndex((selection) => selection.key === value);
      index = foundIndex !== -1 ? foundIndex : undefined;
    }

    this.setState({ pendingSelection: index });
  };

  private async confirmSelection(): Promise<void> {
    if (this.state.pendingSelection != null) {
      const index = this.state.pendingSelection;
      this.setState({ pendingSelection: undefined });
      this.setState({ selected: this.props.selectionList[index].key });
      this.props.onSelect(index);
    }
  }

  private cancelSelection(): void {
    this.setState({ pendingSelection: undefined });
  }

  private renderConfirmationDialog(): ReactNode {
    if (this.state.pendingSelection == null) return;

    return (
      <ConfirmationDialog
        title={this.props.confirmationTitle}
        message={this.props.confirmationMessage}
        onConfirm={this.confirmSelection}
        onCancel={this.cancelSelection}
      />
    );
  }

  public render(): JSX.Element {
    return (
      <Fragment>
        <FormControl margin={this.props.margin} fullWidth={this.props.fullWidth}>
          {this.getLabel()}
          <Select
            value={this.getValue()}
            onChange={this.handleSelection}
            disabled={this.props.disabled}
            displayEmpty={true}
            data-testid={this.props["data-testid"]}
            variant={this.props.variant}
            fullWidth={this.props.fullWidth}
          >
            {this.getEmptySelection()}
            {this.getSelectionList()}
          </Select>
        </FormControl>
        {this.renderConfirmationDialog()}
      </Fragment>
    );
  }
}
