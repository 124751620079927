/*
 * Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */
import { AWSThing } from "../../data";
import { iotItem24Px } from "../../generated/SadeDataIconModel";
export class ConnectivityUnitHW extends AWSThing {
    performOTA() {
        // TODO: Implement OTA functionality here
    }
    getIcon() {
        return iotItem24Px.data;
    }
    static instanceOf(device) {
        return device instanceof ConnectivityUnitHW;
    }
}
