/*
 * Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */

import React, { Component, ReactNode } from "react";
import HEADER_LOGO from "../../assets/logo-nice-header.svg";
import { AuthenticatedUser, AuthWrapper } from "@sade/data-access";
import CompleteAccountCreationForm from "./components/complete-account-creation-form";
import ForgotPasswordForm from "./components/forgot-password-form";
import LoginForm from "./components/login-form";
import { translations } from "../../generated/translationHelper";
import NotificationDialog from "../ui/notification-dialog";

interface Props {}

interface State {
  form: Forms;
  user?: string | AuthenticatedUser;
  message?: string;
}

export enum Forms {
  ForgotPassword,
  ConfirmSignUp,
  SignIn,
}

export default class LoginView extends Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = {
      form: Forms.SignIn,
      message: AuthWrapper.getLogoutReason(),
    };
  }

  private renderInputFields(): JSX.Element {
    if (this.state.form === Forms.ForgotPassword && LoginView.isString(this.state.user)) {
      return (
        <ForgotPasswordForm
          onLoginRequest={(): void => this.setState({ form: Forms.SignIn })}
          username={this.state.user}
        />
      );
    } else if (this.state.form === Forms.ConfirmSignUp && this.state.user && !LoginView.isString(this.state.user)) {
      return <CompleteAccountCreationForm user={this.state.user} />;
    }
    return (
      <LoginForm
        onSignUpConfirmRequest={(user): void => this.setState({ form: Forms.ConfirmSignUp, user })}
        onPasswordResetRequest={(user): void => this.setState({ form: Forms.ForgotPassword, user })}
      />
    );
  }

  private static isString(value: unknown): value is string {
    return typeof value === "string";
  }

  private renderNotificationPopUpMessage(): ReactNode {
    if (this.state.message) {
      return (
        <NotificationDialog
          message={this.state.message}
          onClose={(): void =>
            this.setState({
              message: undefined,
            })
          }
        />
      );
    }
  }

  public render(): JSX.Element {
    return (
      <section className="login-page-container">
        <section className="login-page-bg col-lg-6">
          <section className="login-container col-xsm-11 col-sm-9 col-md-6 col-lg-9 col-xlg-6">
            <header className="login-header-container col-md-12">
              <div className="login-logo-container login col-xlg-10 col-lg-8 col-mg-8 col-sm-8 col-xsm-10">
                <img className="login-header-logo" src={HEADER_LOGO} alt="SADE Innovations" />
              </div>
            </header>
            <div className="login-content">{this.renderInputFields()}</div>
            <div className="login-legal">
              <p className="login-legal-links">
                <a href="https://na.niceforyou.com/legal/privacy-policy/">{translations.logIn.texts.privacy()}</a>|
                <a href="https://na.niceforyou.com/legal/terms-and-conditions-of-purchase/">
                  {translations.logIn.texts.termsOfUse()}
                </a>
              </p>
              <p className="login-copyright">{translations.logIn.texts.copyright()}</p>
            </div>
            {this.renderNotificationPopUpMessage()}
          </section>
        </section>
      </section>
    );
  }
}
