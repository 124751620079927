/*
 * Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */
import { MMS100ESP32HWStateProperties } from "./MMS100ESP32HWStateProperties";
import { MMS100ESP32HWState } from "./MMS100ESP32HWState";
import { GateUnitHW } from "../GateUnitHW";
export class MMS100ESP32HW extends GateUnitHW {
    constructor(backend, params) {
        super(MMS100ESP32HW.type, backend, params);
    }
    createState(timestamp, version, reported, desired, connectionState) {
        return new MMS100ESP32HWState(this.getId(), new MMS100ESP32HWStateProperties(reported !== null && reported !== void 0 ? reported : {}), new MMS100ESP32HWStateProperties(desired !== null && desired !== void 0 ? desired : {}), timestamp, version, connectionState);
    }
    static instanceOf(device) {
        return device instanceof MMS100ESP32HW;
    }
}
MMS100ESP32HW.type = "MMS100_ESP32";
