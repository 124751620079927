/*
 * Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */
import { Mms100HWStateProperties } from "./Mms100HWStateProperties";
import { Mms100HWState } from "./Mms100HWState";
import { GateUnitHW } from "../GateUnitHW";
export class Mms100HW extends GateUnitHW {
    constructor(backend, params) {
        super(Mms100HW.type, backend, params);
    }
    createState(timestamp, version, reported, desired, connectionState) {
        return new Mms100HWState(this.getId(), new Mms100HWStateProperties(reported !== null && reported !== void 0 ? reported : {}), new Mms100HWStateProperties(desired !== null && desired !== void 0 ? desired : {}), timestamp, version, connectionState);
    }
    static instanceOf(device) {
        return device instanceof Mms100HW;
    }
}
Mms100HW.type = "Mms100";
