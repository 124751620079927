/*
 * Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */

import React, { ChangeEvent, Component, Fragment } from "react";
import { Button, TextField } from "@material-ui/core";
import { Maybe } from "../../../types/aliases";
import Loader from "../../../components/ui/loader";
import PasswordField from "../../ui/password-field";
import { translations } from "../../../generated/translationHelper";
import { isError, AuthWrapper, ErrorWithCode } from "@sade/data-access";

export interface Props {
  username: string;
  onLoginRequest: () => void;
}

interface State {
  newPassword: string;
  confirmPassword: string;
  verificationCode: string;
  codeError?: string;
  newPasswordError?: string;
  confirmPasswordError?: string;
  generalError?: string;
  isLoaderVisible?: boolean;
}

export default class ForgotPasswordForm extends Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = {
      newPassword: "",
      confirmPassword: "",
      verificationCode: "",
    };
  }

  private fieldsHaveValues(): boolean {
    const { verificationCode, newPassword, confirmPassword } = this.state;
    return Boolean(verificationCode.length && newPassword.length && confirmPassword.length);
  }

  private passwordsMatch(): boolean {
    return this.state.newPassword === this.state.confirmPassword;
  }

  private async submitNewPassword(): Promise<void> {
    this.resetErrors();

    if (!this.passwordsMatch()) {
      this.setState({ confirmPasswordError: translations.common.texts.passwordsNotMatching() });
      return;
    }

    try {
      this.setState({ isLoaderVisible: true });
      await AuthWrapper.checkCodeAndSubmitNewPassword(
        this.props.username,
        this.state.verificationCode,
        this.state.newPassword
      );
      await AuthWrapper.logIn(this.props.username, this.state.newPassword);
      const claims = await AuthWrapper.getCurrentAuthenticatedUserClaims();
      if (claims?.homeOrganizationId.includes(":")) {
        console.error("User is not a root level user, logging out");
        await AuthWrapper.logOut(translations.logIn.texts.logoutMessageNoAccess());
        throw new ErrorWithCode("UserNotFoundException");
      }
    } catch (error) {
      console.error("submitNewPassword", error);
      if (isError(error)) this.handleErrors(error.message);
      this.setState({ isLoaderVisible: false });
    }
  }

  private handleErrors(code?: string): void {
    switch (code) {
      case "Attempt limit exceeded, please try after some time.":
        this.setState({ generalError: translations.common.texts.tooManyAttempts() });
        break;
      case "Invalid verification code provided, please try again.":
        this.setState({ codeError: translations.logIn.texts.invalidCode() });
        break;
      case "Invalid code provided, please request a code again.":
        this.setState({ codeError: translations.logIn.texts.codeExpired() });
        break;
      case "Network error":
        this.setState({ generalError: translations.common.texts.networkError() });
        break;
      case "Password does not conform to policy: Password must have numeric characters":
        this.setState({ newPasswordError: translations.common.texts.passwordMustHaveNumbers() });
        break;
      case "Password does not conform to policy: Password must have lowercase characters":
        this.setState({ newPasswordError: translations.common.texts.passwordMustHaveLowercaseCharacters() });
        break;
      case "1 validation error detected: Value at 'password' failed to satisfy constraint: Member must have length greater than or equal to 6":
      case "Password does not conform to policy: Password not long enough":
      case "Password did not conform with policy: Password not long enough":
        this.setState({ newPasswordError: translations.common.texts.passwordMustBeLongEnough() });
        break;
      case "Invalid session for the user, session is expired.":
        this.setState({ generalError: translations.common.texts.userSessionExpired() });
        break;
      default:
        this.setState({ generalError: translations.common.texts.unableToPerformAction() });
        break;
    }
  }

  private resetErrors(): void {
    this.setState({
      generalError: undefined,
      codeError: undefined,
      newPasswordError: undefined,
      confirmPasswordError: undefined,
    });
  }

  private renderLoader(): Maybe<JSX.Element> {
    if (this.state.isLoaderVisible) {
      return <Loader />;
    }
  }

  private renderBackToLogInForm(): JSX.Element {
    return (
      <Fragment>
        <div className="login-links small">
          <span onClick={(): void => this.props.onLoginRequest()} data-testid="back-to-login-page">
            {translations.logIn.buttons.backToLogIn()}
          </span>
        </div>
      </Fragment>
    );
  }

  private renderGeneralError(): Maybe<JSX.Element> {
    if (this.state.generalError) {
      return (
        <div className="login-errortext" data-testid="forgot-pass-general-err">
          {translations.common.texts.errorOccurred({ error: this.state.generalError })}
        </div>
      );
    }
  }

  private renderInputs(): JSX.Element {
    return (
      <Fragment>
        <div className="login-fields">
          <TextField
            label={translations.logIn.inputs.confirmationCode()}
            type="text"
            autoComplete="confirmation-code"
            margin="normal"
            variant="outlined"
            required={true}
            value={this.state.verificationCode}
            error={this.state.codeError != null}
            helperText={this.state.codeError}
            onChange={(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>): void => {
              this.resetErrors();
              this.setState({ verificationCode: event.target.value });
            }}
            fullWidth={true}
            inputProps={{ "data-testid": "verification-code" }}
          />
          <PasswordField
            label={translations.common.inputs.newPassword()}
            autoComplete="new-password"
            fullWidth={true}
            required={true}
            margin="normal"
            error={this.state.newPasswordError != null}
            helperText={this.state.newPasswordError}
            onChange={(newPassword: string): void => {
              this.resetErrors();
              this.setState({ newPassword });
            }}
            inputProps={{ "data-testid": "new-password-field" }}
          />
          <PasswordField
            label={translations.common.inputs.confirmNewPassword()}
            autoComplete="confirm-password"
            fullWidth={true}
            required={true}
            margin="normal"
            error={this.state.confirmPasswordError != null}
            helperText={this.state.confirmPasswordError}
            onChange={(confirmPassword: string): void => {
              this.resetErrors();
              this.setState({ confirmPassword });
            }}
            inputProps={{ "data-testid": "confirm-password-field" }}
          />
        </div>
        <div className="login-buttons">
          <Button
            disabled={!this.fieldsHaveValues()}
            variant="contained"
            color="primary"
            onClick={(): Promise<void> => this.submitNewPassword()}
            data-testid="confirm-login-button"
          >
            {translations.logIn.buttons.confirmLogIn()}
          </Button>
        </div>
      </Fragment>
    );
  }

  public render(): JSX.Element {
    return (
      <Fragment>
        <div className="login-header">
          {translations.logIn.texts.enterVerificationCodeAndPassword({ email: this.props.username })}
        </div>
        {this.renderLoader()}
        {this.renderInputs()}
        {this.renderBackToLogInForm()}
        {this.renderGeneralError()}
      </Fragment>
    );
  }
}
