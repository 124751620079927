/*
 * Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */
import { __awaiter } from "tslib";
import { DeviceState } from "../../data";
import { parseTimedEventStrings, parseTimedEventsToString } from "../../common/TimedEvents";
import { parseBarrierStatusFromString, parseBarrierStatusToString } from "../../common/BarrierStatus";
import { parseLightStatusFromString } from "../../common/LightStatus";
import { AppSyncClientFactory } from "../../data/backend/AppSyncClientFactory";
import { Service } from "../../data/backend/AppSyncClientProvider";
import { ControlTarget, DevicesControlDocument } from "../../generated/gqlDevice";
export var BarrierAction;
(function (BarrierAction) {
    BarrierAction[BarrierAction["Close"] = 0] = "Close";
    BarrierAction[BarrierAction["Open"] = 1] = "Open";
})(BarrierAction || (BarrierAction = {}));
export var UpdateAction;
(function (UpdateAction) {
    UpdateAction[UpdateAction["Perform"] = 1] = "Perform";
})(UpdateAction || (UpdateAction = {}));
export var GdoBrandId;
(function (GdoBrandId) {
    GdoBrandId[GdoBrandId["MightyMule"] = 0] = "MightyMule";
    GdoBrandId[GdoBrandId["Nice"] = 1] = "Nice";
})(GdoBrandId || (GdoBrandId = {}));
export class ConnectivityUnitHWState extends DeviceState {
    constructor() {
        super(...arguments);
        this.controlBarrier = (deviceId, action) => __awaiter(this, void 0, void 0, function* () {
            var _a, _b;
            try {
                const client = AppSyncClientFactory.createProvider().getTypedClient(Service.DEVICE);
                const response = yield client.mutate(DevicesControlDocument, {
                    deviceId,
                    payload: {
                        target: ControlTarget.B,
                        action,
                    },
                });
                return (_b = (_a = response.data) === null || _a === void 0 ? void 0 : _a.devicesControl) !== null && _b !== void 0 ? _b : false;
            }
            catch (error) {
                return false;
            }
        });
        this.requestStateUpdate = (deviceId) => __awaiter(this, void 0, void 0, function* () {
            var _c, _d;
            try {
                const client = AppSyncClientFactory.createProvider().getTypedClient(Service.DEVICE);
                const response = yield client.mutate(DevicesControlDocument, {
                    deviceId,
                    payload: {
                        target: ControlTarget.U,
                        action: UpdateAction.Perform,
                    },
                });
                return (_d = (_c = response.data) === null || _c === void 0 ? void 0 : _c.devicesControl) !== null && _d !== void 0 ? _d : false;
            }
            catch (error) {
                return false;
            }
        });
        this.controlLight = (deviceId, action) => __awaiter(this, void 0, void 0, function* () {
            var _e, _f;
            try {
                const client = AppSyncClientFactory.createProvider().getTypedClient(Service.DEVICE);
                const response = yield client.mutate(DevicesControlDocument, {
                    deviceId,
                    payload: {
                        target: ControlTarget.L,
                        action,
                    },
                });
                return (_f = (_e = response.data) === null || _e === void 0 ? void 0 : _e.devicesControl) !== null && _f !== void 0 ? _f : false;
            }
            catch (error) {
                return false;
            }
        });
    }
    get doorOpen() {
        return this.deltaExists("doorOpen") ? this.desired.doorOpen : this.reported.doorOpen;
    }
    get autoClose() {
        return this.deltaExists("autoClose") ? this.desired.autoClose : this.reported.autoClose;
    }
    set autoClose(autoClose) {
        this.desired.autoClose = autoClose;
        this.changedValues.autoClose = autoClose;
    }
    get autoEvents() {
        const value = this.deltaExists("autoEvents") ? this.desired.autoEvents : this.reported.autoEvents;
        if (!value) {
            return [];
        }
        return parseTimedEventStrings(value);
    }
    set autoEvents(timedEvents) {
        const value = parseTimedEventsToString(timedEvents);
        this.desired.autoEvents = value;
        this.changedValues.autoEvents = value;
    }
    get migrationStatus() {
        return this.reported.migrationStatus;
    }
    set migrationStatus(status) {
        this.desired.migrationStatus = status;
        this.changedValues.migrationStatus = status;
    }
    get signalStrength() {
        return this.reported.signalStrength;
    }
    get barrierStatus() {
        const value = this.deltaExists("barrierStatus") ? this.desired.barrierStatus : this.reported.barrierStatus;
        if (!value) {
            return null;
        }
        return parseBarrierStatusFromString(value);
    }
    set barrierStatus(barrierStatus) {
        const value = barrierStatus ? parseBarrierStatusToString(barrierStatus) : null;
        this.desired.barrierStatus = value;
        this.changedValues.barrierStatus = value;
    }
    get lightStatus() {
        const value = this.deltaExists("lightStatus") ? this.desired.lightStatus : this.reported.lightStatus;
        if (!value) {
            return null;
        }
        return parseLightStatusFromString(value);
    }
    set vacationMode(value) {
        this.desired.vcnMode = value;
        this.changedValues.vcnMode = value;
    }
    get vacationMode() {
        return this.deltaExists("vcnMode") ? this.desired.vcnMode : this.reported.vcnMode;
    }
    get firmwareVersion() {
        return this.reported.deviceFwVersion;
    }
    get wifiRssi() {
        return this.reported.wifiRssi;
    }
    get wifiSsid() {
        return this.reported.wifiSsid;
    }
    get dst() {
        return this.deltaExists("dst") ? this.desired.dst : this.reported.dst;
    }
    set dst(value) {
        this.desired.dst = value;
        this.changedValues.dst = value;
    }
    get resetCount() {
        return this.reported.resetCount;
    }
    get gdoModelId() {
        return this.reported.gdoModelId;
    }
    get gdoBrandId() {
        return this.reported.gdoBrandId;
    }
}
