/*
 * Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */
import { DeviceStateProperties } from "../../data";
export var MigrationStatus;
(function (MigrationStatus) {
    MigrationStatus["NotStarted"] = "NOT_STARTED";
    MigrationStatus["OtaGroupUpdated"] = "OTA_GROUP_UPDATED";
    MigrationStatus["Done"] = "DONE";
})(MigrationStatus || (MigrationStatus = {}));
export class ConnectivityUnitHWStateProperties extends DeviceStateProperties {
    constructor(properties) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q;
        super(properties);
        this.doorOpen = (_a = properties.doorOpen) !== null && _a !== void 0 ? _a : null;
        this.autoClose = (_b = properties.autoClose) !== null && _b !== void 0 ? _b : null;
        this.autoEvents = (_c = properties.autoEvents) !== null && _c !== void 0 ? _c : null;
        this.migrationStatus = (_d = properties.migrationStatus) !== null && _d !== void 0 ? _d : null;
        this.barrierStatus = (_e = properties.barrierStatus) !== null && _e !== void 0 ? _e : null;
        this.lightStatus = (_f = properties.lightStatus) !== null && _f !== void 0 ? _f : null;
        this.vcnMode = (_g = properties.vcnMode) !== null && _g !== void 0 ? _g : null;
        this.deviceFwVersion = (_h = properties.deviceFwVersion) !== null && _h !== void 0 ? _h : null;
        this.wifiRssi = (_j = properties.wifiRssi) !== null && _j !== void 0 ? _j : null;
        this.wifiSsid = (_k = properties.wifiSsid) !== null && _k !== void 0 ? _k : null;
        this.signalStrength = (_l = properties.signalStrength) !== null && _l !== void 0 ? _l : null;
        this.dst = (_m = properties.dst) !== null && _m !== void 0 ? _m : null;
        this.resetCount = (_o = properties.resetCount) !== null && _o !== void 0 ? _o : null;
        this.gdoModelId = (_p = properties.gdoModelId) !== null && _p !== void 0 ? _p : null;
        this.gdoBrandId = (_q = properties.gdoBrandId) !== null && _q !== void 0 ? _q : null;
    }
}
