/*
 * Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */

import { RouteComponentProps } from "react-router-dom";

enum Paths {
  ROOT = "/",
  LOGIN = "/login",
  ADMIN = "/admin",
  USER = "/user",
  ANALYTICS = "/analytics",
  SUPPORT = "/support",
}

interface RouteParamPreservationConfig {
  pathParams: string[];
}
type PreservationConfigurations = Record<Paths, RouteParamPreservationConfig>;

const preservationConfigs: PreservationConfigurations = {
  [Paths.ROOT]: {
    pathParams: [],
  },
  [Paths.LOGIN]: {
    pathParams: [],
  },
  [Paths.ADMIN]: {
    pathParams: [],
  },
  [Paths.USER]: {
    pathParams: [],
  },
  [Paths.ANALYTICS]: {
    pathParams: ["id"],
  },
  [Paths.SUPPORT]: {
    pathParams: [],
  },
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getPathWithRetainedParams(path: Paths, routeProps: RouteComponentProps<any>): string {
  const retainedPathParams: string[] = [];

  for (const pathParam of preservationConfigs[path].pathParams) {
    if (routeProps.match.params[pathParam]) {
      retainedPathParams.push(routeProps.match.params[pathParam]);
    } else {
      break;
    }
  }

  return path + (retainedPathParams.length > 0 ? "/" + retainedPathParams.join("/") : "");
}

export default Paths;
