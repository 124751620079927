/*
 * Copyright (C) 2022 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */
import { __awaiter } from "tslib";
import { getBackendCompatibilityVersion } from "../../Config";
export var VersionCompatibility;
(function (VersionCompatibility) {
    /**
     * The client cannot work with the backend in a reliable way.
     * An update is required.
     */
    VersionCompatibility["Incompatible"] = "Incompatible";
    /**
     * The client can work with the backend, but it is not using some of the latest features.
     * An update is recommended.
     */
    VersionCompatibility["LimitedCapability"] = "LimitedCapability";
    /**
     * The client can work with the backend in a reliable way.
     * No action is required.
     */
    VersionCompatibility["Compatible"] = "Compatible";
})(VersionCompatibility || (VersionCompatibility = {}));
export class AppConfiguration {
    static configure(configurationProvider) {
        return __awaiter(this, void 0, void 0, function* () {
            const { awsConfig, resources, backendVersion, minimumVersions } = yield configurationProvider.getConfiguration();
            AppConfiguration.awsConfig = awsConfig;
            AppConfiguration.resources = resources;
            AppConfiguration.backendVersion = backendVersion;
            AppConfiguration.minimumVersions = minimumVersions;
        });
    }
    static getAwsConfiguration() {
        if (!AppConfiguration.awsConfig) {
            throw new Error("AWS not configured. Have you called AwsConfiguration.configure from SADE data access module?");
        }
        return AppConfiguration.awsConfig;
    }
    static getResources() {
        if (!AppConfiguration.resources) {
            throw new Error("Resources not configured. Have you called AwsConfiguration.configure from SADE data access module?");
        }
        return AppConfiguration.resources;
    }
    static unconfigure() {
        this.backendVersion = undefined;
        this.awsConfig = undefined;
        this.resources = undefined;
    }
    /**
     * Returns the compatibility of the backend with the client, and a reason if the compatibility is not Compatible.
     * Relies on {@link getBackendCompatibilityVersion} to determine the expected version.
     *
     * @returns CompatibilityResult
     * @throws Error if AppConfiguration is not configured
     */
    static getBackendCompatibility() {
        if (!AppConfiguration.backendVersion)
            throw new Error("AppConfiguration is not configured");
        const expectedVersions = getBackendCompatibilityVersion();
        const [actualMajor, actualMinor] = AppConfiguration.backendVersion.split(".").map((v) => parseInt(v, 10));
        if (actualMajor == null || Number.isNaN(actualMajor) || actualMinor == null || Number.isNaN(actualMinor)) {
            throw new Error("Cannot parse backend version");
        }
        if (actualMajor < expectedVersions.major) {
            return {
                compatibility: VersionCompatibility.Incompatible,
                reason: "Backend major version is less than expected",
            };
        }
        else if (actualMajor > expectedVersions.major) {
            return {
                compatibility: VersionCompatibility.Incompatible,
                reason: "Backend major version is greater than expected",
            };
        }
        if (actualMinor < expectedVersions.minor) {
            // If application expects a greater backend version than the actual has, we might end up calling non-existing APIs
            // or reading response fields that do not exist.
            // Missing error handling for these cases might cause the application to crash.
            return {
                compatibility: VersionCompatibility.Incompatible,
                reason: "Backend minor version is less than expected",
            };
        }
        else if (actualMinor > expectedVersions.minor) {
            return {
                compatibility: VersionCompatibility.LimitedCapability,
                reason: "Backend minor version is greater than expected",
            };
        }
        return {
            compatibility: VersionCompatibility.Compatible,
        };
    }
    static getAppCompatibility(platform, version) {
        var _a;
        if (!/^\d+\.\d+\.\d+/.test(version)) {
            throw new Error(`Version '${version}' is invalid semver`);
        }
        const minimumVersion = (_a = AppConfiguration.minimumVersions) === null || _a === void 0 ? void 0 : _a[platform];
        if (!minimumVersion)
            return VersionCompatibility.Compatible;
        const mins = minimumVersion.split(".").map((v) => parseInt(v, 10));
        const actuals = version.split(".").map((v) => parseInt(v, 10));
        for (let i = 0; i < 3; i++) {
            if (actuals[i] < mins[i])
                return VersionCompatibility.Incompatible;
        }
        return VersionCompatibility.Compatible;
    }
}
