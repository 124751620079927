/*
 * Copyright (C) 2022 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */
import { Service } from "../backend/AppSyncClientProvider";
import { EventsFeedDocument } from "../../generated/gqlEvents";
import { AbstractMapSubscriptionManager } from "../utils/subscriptions/AbstractMapSubscriptionManager";
export class EventsSubscriptionManager extends AbstractMapSubscriptionManager {
    constructor() {
        super(Service.EVENTS, EventsFeedDocument);
    }
    static get instance() {
        return this.__instance;
    }
    subscriptionHandler(result) {
        var _a, _b;
        if (!result)
            return;
        const event = (_a = result.eventsFeed) === null || _a === void 0 ? void 0 : _a.item;
        if (event) {
            (_b = this.getListener(event.deviceId)) === null || _b === void 0 ? void 0 : _b.addOrUpdateEvent(event);
        }
    }
}
EventsSubscriptionManager.__instance = new EventsSubscriptionManager();
