/*
 * Copyright (C) 2022 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */
export var EventType;
(function (EventType) {
    EventType["Sensor"] = "SENSOR";
    EventType["Generic"] = "GENERIC";
})(EventType || (EventType = {}));
export var EventSeverity;
(function (EventSeverity) {
    EventSeverity["Low"] = "LOW";
    EventSeverity["Medium"] = "MEDIUM";
    EventSeverity["High"] = "HIGH";
})(EventSeverity || (EventSeverity = {}));
export var NotificationState;
(function (NotificationState) {
    NotificationState["MissingEndpoint"] = "MISSING_ENDPOINT";
    NotificationState["Ok"] = "OK";
})(NotificationState || (NotificationState = {}));
export var EventState;
(function (EventState) {
    EventState["Active"] = "ACTIVE";
    EventState["Inactive"] = "INACTIVE";
})(EventState || (EventState = {}));
export var NotificationType;
(function (NotificationType) {
    NotificationType["Email"] = "EMAIL";
    NotificationType["Sms"] = "SMS";
    NotificationType["Push"] = "PUSH";
})(NotificationType || (NotificationType = {}));
export function eventIdentitiesMatch(e1, e2) {
    return e1.deviceId === e2.deviceId && e1.eventId === e2.eventId && e1.timestamp === e2.timestamp;
}
export function getEventDefinitionName(event) {
    var _a, _b;
    return (_b = (_a = event.name) !== null && _a !== void 0 ? _a : event.description) !== null && _b !== void 0 ? _b : "Unknown event";
}
