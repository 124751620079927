/*
 * Copyright (C) 2022 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */
import { __awaiter } from "tslib";
import { AppSyncClientFactory, Service } from "../backend";
import { NotificationsSubscriptionsCreateDocument, NotificationsSubscriptionsListDocument, NotificationsSubscriptionsRemoveDocument, UsersEventsListDocument, } from "../../generated/gqlEvents";
import { prefixlessId } from "../organization";
export class AWSNotificationBackend {
    getUserNotificationSubscriptions(query) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const client = AppSyncClientFactory.createProvider().getTypedClient(Service.EVENTS);
                const response = yield client.query(NotificationsSubscriptionsListDocument, {
                    userId: prefixlessId(query.userId),
                    organizationId: query.owningOrganizationId,
                }, {
                    fetchPolicy: "network-only",
                });
                return (_b = (_a = response.data.notificationsSubscriptionsList) === null || _a === void 0 ? void 0 : _a.subscriptions) !== null && _b !== void 0 ? _b : [];
            }
            catch (error) {
                console.error("getUserNotificationSubscriptions", error);
                return [];
            }
        });
    }
    createNotificationSubscription(params) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function* () {
            const subscriptionDetails = Object.assign({ userId: prefixlessId(params.userId), type: params.eventType, owningOrganization: params.owningOrganizationId }, params.target);
            const client = AppSyncClientFactory.createProvider().getTypedClient(Service.EVENTS);
            try {
                const response = yield client.mutate(NotificationsSubscriptionsCreateDocument, {
                    subscriptionDetails,
                });
                return (_b = (_a = response.data) === null || _a === void 0 ? void 0 : _a.notificationsSubscriptionsCreate) !== null && _b !== void 0 ? _b : undefined;
            }
            catch (error) {
                console.error("createNotificationSubscription", error);
            }
        });
    }
    removeNotificationSubscription(params) {
        var _a, _b, _c;
        return __awaiter(this, void 0, void 0, function* () {
            const client = AppSyncClientFactory.createProvider().getTypedClient(Service.EVENTS);
            try {
                const response = yield client.mutate(NotificationsSubscriptionsRemoveDocument, {
                    userId: prefixlessId(params.userId),
                    subscriptionId: params.subscriptionId,
                });
                return (_c = (_b = (_a = response.data) === null || _a === void 0 ? void 0 : _a.notificationsSubscriptionsRemove) === null || _b === void 0 ? void 0 : _b.subscription) !== null && _c !== void 0 ? _c : undefined;
            }
            catch (error) {
                console.error("removeNotificationSubscription", error);
            }
        });
    }
    getCurrentUserEvents(startTimestamp, endTimestamp) {
        var _a, _b, _c;
        return __awaiter(this, void 0, void 0, function* () {
            const client = AppSyncClientFactory.createProvider().getTypedClient(Service.EVENTS);
            const events = [];
            let nextToken = undefined;
            do {
                const response = yield client.query(UsersEventsListDocument, {
                    endTimestamp: `${endTimestamp}`,
                    startTimestamp: `${startTimestamp}`,
                    nextToken,
                }, { fetchPolicy: "network-only" });
                nextToken = (_a = response.data.usersEventsList) === null || _a === void 0 ? void 0 : _a.nextToken;
                events.push(...((_c = (_b = response.data.usersEventsList) === null || _b === void 0 ? void 0 : _b.events) !== null && _c !== void 0 ? _c : []));
            } while (nextToken);
            return events;
        });
    }
}
