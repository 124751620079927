/*
 * Copyright (C) 2022 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */
import { __awaiter } from "tslib";
import { AuthListener, AuthWrapper } from "../auth";
import { AppSyncClientFactory, Service } from "../backend";
import { AWSOrganization } from "./AWSOrganization";
import { AWSUser } from "./AWSUser";
import { InvitationIdentifierType, InvitationsCreateDocument, OrganizationsCreateDocument, OrganizationsGetDocument, PolicyGroupsGetDocument, UsersAdminGetDocument, UsersCreateDocument, UsersGetDocument, UsersInvitationsReceivedListDocument, UsersInvitationsSentListDocument, UsersSearchDocument, } from "../../generated/gqlUsers";
import { AWSPolicyGroup } from "./AWSPolicyGroup";
import { isDefined } from "../../common/Utils";
import { AsyncCache, EntityRelationCache, throwGQLError, } from "../utils";
import { AWSReceivedInvitation, AWSSentInvitation } from "./AWSInvitation";
import { AWSUserAdminDetails } from "./AWSUserAdminDetails";
export class SentInvitationCache {
    static getSentInvitationById(invitationId) {
        return this.sentInvitationMap.get(invitationId);
    }
    static cacheInvitations(sentInvitations) {
        this.sentInvitationMap.clear();
        sentInvitations.forEach((i) => this.sentInvitationMap.set(i.id, i));
    }
    static getCachedInvitations() {
        return [...this.sentInvitationMap.values()];
    }
}
SentInvitationCache.sentInvitationMap = new Map();
// TODO:  method for cache pruning - or an actual cache
//        also, cache could be extracted with the pruning code
//        also, would graphql cache be enough here?
//        the cache should also be invalidated when the user logs out - is there a way to listen for that?
export class AWSOrganizationBackend {
    constructor() {
        // this is public so AWSEntities can reach into it
        this.entityRelationCache = new EntityRelationCache();
        this.cache = new AsyncCache();
        this.authEventHandler = (event) => {
            if (event === "SignedOut") {
                this.cache.clear();
                this.entityRelationCache.clear();
            }
        };
        this.authListener = new AuthListener(this.authEventHandler);
    }
    static getCurrentUserId() {
        return __awaiter(this, void 0, void 0, function* () {
            const claims = yield AuthWrapper.getCurrentAuthenticatedUserClaims();
            return claims === null || claims === void 0 ? void 0 : claims.userId;
        });
    }
    getOrganization(organizationId) {
        return __awaiter(this, void 0, void 0, function* () {
            const fetchOrganization = () => __awaiter(this, void 0, void 0, function* () {
                var _a, _b;
                const client = AppSyncClientFactory.createProvider().getTypedClient(Service.USERS);
                const response = yield client.query(OrganizationsGetDocument, { organizationId });
                if ((_a = response.data.organizationsGet) === null || _a === void 0 ? void 0 : _a.id) {
                    return new AWSOrganization(this, {
                        id: response.data.organizationsGet.id,
                        name: response.data.organizationsGet.name,
                        parentOrganizationId: (_b = response.data.organizationsGet.organizationId) !== null && _b !== void 0 ? _b : undefined,
                    });
                }
            });
            return this.cache.get(organizationId, fetchOrganization);
        });
    }
    getUser(userId) {
        return __awaiter(this, void 0, void 0, function* () {
            const fetchUser = () => __awaiter(this, void 0, void 0, function* () {
                var _a, _b;
                const client = AppSyncClientFactory.createProvider().getTypedClient(Service.USERS);
                const response = yield client.query(UsersGetDocument, { userId });
                if ((_a = response.data.usersGet) === null || _a === void 0 ? void 0 : _a.id) {
                    return new AWSUser(this, {
                        id: response.data.usersGet.id,
                        email: response.data.usersGet.email,
                        policies: response.data.usersGet.policies,
                        homeOrganization: response.data.usersGet.organizationId,
                        displayName: (_b = response.data.usersGet.displayName) !== null && _b !== void 0 ? _b : undefined,
                    });
                }
            });
            return this.cache.get(userId, fetchUser);
        });
    }
    getUserAdmin(userId) {
        var _a, _b, _c, _d, _e, _f, _g;
        return __awaiter(this, void 0, void 0, function* () {
            const client = AppSyncClientFactory.createProvider().getTypedClient(Service.USERS);
            const response = yield client.query(UsersAdminGetDocument, { userId });
            if ((_a = response.data.usersAdminGet) === null || _a === void 0 ? void 0 : _a.id) {
                return new AWSUserAdminDetails(this, {
                    id: response.data.usersAdminGet.id,
                    email: response.data.usersAdminGet.email,
                    androidPn: (_b = response.data.usersAdminGet.androidPn) !== null && _b !== void 0 ? _b : undefined,
                    iosPn: (_c = response.data.usersAdminGet.iosPn) !== null && _c !== void 0 ? _c : undefined,
                    creationDate: response.data.usersAdminGet.creationDate
                        ? new Date(response.data.usersAdminGet.creationDate)
                        : undefined,
                    emailVerified: response.data.usersAdminGet.emailVerified,
                    organizationId: response.data.usersAdminGet.organizationId,
                    firstName: (_d = response.data.usersAdminGet.firstName) !== null && _d !== void 0 ? _d : undefined,
                    lastName: (_e = response.data.usersAdminGet.lastName) !== null && _e !== void 0 ? _e : undefined,
                    phoneNumber: (_f = response.data.usersAdminGet.phoneNumber) !== null && _f !== void 0 ? _f : undefined,
                    updatedDate: response.data.usersAdminGet.updatedDate
                        ? new Date(response.data.usersAdminGet.updatedDate)
                        : undefined,
                    migrationDetails: response.data.usersAdminGet.migrationDetails.map((detail) => {
                        const data = detail.data;
                        return {
                            type: detail.type,
                            timestamp: new Date(detail.timestamp),
                            data: data ? JSON.parse(data) : undefined,
                        };
                    }),
                    rndStatus: (_g = response.data.usersAdminGet.rndStatus) !== null && _g !== void 0 ? _g : false,
                });
            }
        });
    }
    searchUsers(attributeName, attributeValue) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            const client = AppSyncClientFactory.createProvider().getTypedClient(Service.USERS);
            const response = yield client.query(UsersSearchDocument, { attributeName, attributeValue });
            if ((_a = response.data.usersSearch) === null || _a === void 0 ? void 0 : _a.users) {
                const users = yield Promise.all(response.data.usersSearch.users.map((user) => {
                    return this.cache.get(user.id, () => {
                        var _a;
                        return Promise.resolve(new AWSUser(this, {
                            id: user.id,
                            email: user.email,
                            policies: user.policies,
                            homeOrganization: user.organizationId,
                            displayName: (_a = user.displayName) !== null && _a !== void 0 ? _a : undefined,
                        }));
                    });
                }));
                return users.filter(isDefined);
            }
            else {
                return [];
            }
        });
    }
    getPolicyGroup(policyGroupId) {
        return __awaiter(this, void 0, void 0, function* () {
            const fetchPolicyGroup = () => __awaiter(this, void 0, void 0, function* () {
                var _a;
                const client = AppSyncClientFactory.createProvider().getTypedClient(Service.USERS);
                const response = yield client.query(PolicyGroupsGetDocument, { policyGroupId });
                if ((_a = response.data.policyGroupsGet) === null || _a === void 0 ? void 0 : _a.id) {
                    return new AWSPolicyGroup(this, {
                        id: response.data.policyGroupsGet.id,
                        name: response.data.policyGroupsGet.name,
                        organization: response.data.policyGroupsGet.organizationId,
                        policies: response.data.policyGroupsGet.policies,
                    });
                }
            });
            return this.cache.get(policyGroupId, fetchPolicyGroup);
        });
    }
    getCurrentHomeOrganization() {
        return __awaiter(this, void 0, void 0, function* () {
            const claims = yield AuthWrapper.getCurrentAuthenticatedUserClaims();
            if (!claims) {
                throw new Error("No authenticated user");
            }
            const organization = yield this.getOrganization(claims.homeOrganizationId);
            if (!organization) {
                throw new Error("Could not resolve home organization of current user");
            }
            return organization;
        });
    }
    getCurrentUser() {
        return __awaiter(this, void 0, void 0, function* () {
            const userId = yield AWSOrganizationBackend.getCurrentUserId();
            if (!userId)
                return;
            return yield this.getUser(userId);
        });
    }
    createOrganization(owner, parameters) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function* () {
            const client = AppSyncClientFactory.createProvider().getTypedClient(Service.USERS);
            const response = yield client.mutate(OrganizationsCreateDocument, {
                payload: {
                    name: parameters.name,
                    parentOrganizationId: owner.getId(),
                },
            });
            if (!((_a = response.data) === null || _a === void 0 ? void 0 : _a.organizationsCreate)) {
                throwGQLError(response, "Failed to create organization");
            }
            const newOrganization = new AWSOrganization(this, {
                id: response.data.organizationsCreate.id,
                name: response.data.organizationsCreate.name,
                parentOrganizationId: (_b = response.data.organizationsCreate.organizationId) !== null && _b !== void 0 ? _b : undefined,
            });
            this.cache.set(newOrganization.getId(), newOrganization);
            return newOrganization;
        });
    }
    createUser(owner, parameters) {
        var _a, _b, _c;
        return __awaiter(this, void 0, void 0, function* () {
            if (!parameters.username.includes("@"))
                throw new Error("Username does not look like an email address");
            const client = AppSyncClientFactory.createProvider().getTypedClient(Service.USERS);
            const response = yield client.mutate(UsersCreateDocument, {
                payload: {
                    email: parameters.username.toLowerCase(),
                    organizationId: owner.getId(),
                    policies: [],
                    resendInvitation: (_a = parameters.resendInvitation) !== null && _a !== void 0 ? _a : false,
                    displayName: parameters.displayName,
                },
            });
            if (!((_b = response.data) === null || _b === void 0 ? void 0 : _b.usersCreate)) {
                throwGQLError(response, "Failed to create new user");
            }
            const newUser = new AWSUser(this, {
                id: response.data.usersCreate.id,
                email: response.data.usersCreate.email,
                policies: [],
                homeOrganization: owner,
                displayName: (_c = response.data.usersCreate.displayName) !== null && _c !== void 0 ? _c : undefined,
            });
            this.cache.set(newUser.getId(), newUser);
            return newUser;
        });
    }
    createInvitation(params) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            const client = AppSyncClientFactory.createProvider().getTypedClient(Service.USERS);
            const response = yield client.mutate(InvitationsCreateDocument, {
                payload: {
                    devices: params.devices,
                    targetUser: {
                        value: params.email,
                        type: InvitationIdentifierType.Email,
                    },
                },
            });
            if (!((_a = response.data) === null || _a === void 0 ? void 0 : _a.invitationsCreate)) {
                throwGQLError(response, "Failed to create invitation");
            }
            const newInvitation = new AWSSentInvitation(response.data.invitationsCreate);
            SentInvitationCache.cacheInvitations(SentInvitationCache.getCachedInvitations().concat(newInvitation));
            return newInvitation;
        });
    }
    getReceivedInvitations() {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            const userId = yield AWSOrganizationBackend.getCurrentUserId();
            if (!userId) {
                return [];
            }
            const client = AppSyncClientFactory.createProvider().getTypedClient(Service.USERS);
            const results = [];
            const params = {
                userId,
                nextToken: undefined,
            };
            do {
                const response = yield client.query(UsersInvitationsReceivedListDocument, params, {
                    fetchPolicy: "network-only",
                });
                if (!((_a = response.data) === null || _a === void 0 ? void 0 : _a.usersInvitationsReceivedList)) {
                    throwGQLError(response);
                }
                results.push(...response.data.usersInvitationsReceivedList.invitations.map((gqlInvitation) => new AWSReceivedInvitation(gqlInvitation, AuthWrapper.refreshAuthentication)));
                params.nextToken = response.data.usersInvitationsReceivedList.nextToken;
            } while (params.nextToken);
            return results;
        });
    }
    getSentInvitations(id) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            const userId = id !== null && id !== void 0 ? id : (yield AWSOrganizationBackend.getCurrentUserId());
            if (!userId) {
                return [];
            }
            const client = AppSyncClientFactory.createProvider().getTypedClient(Service.USERS);
            const results = [];
            const params = {
                userId,
                nextToken: undefined,
            };
            do {
                const response = yield client.query(UsersInvitationsSentListDocument, params, {
                    fetchPolicy: "network-only",
                });
                if (!((_a = response.data) === null || _a === void 0 ? void 0 : _a.usersInvitationsSentList)) {
                    throwGQLError(response);
                }
                results.push(...response.data.usersInvitationsSentList.invitations.map((gqlInvitation) => new AWSSentInvitation(gqlInvitation)));
                params.nextToken = response.data.usersInvitationsSentList.nextToken;
            } while (params.nextToken);
            SentInvitationCache.cacheInvitations(results);
            return results;
        });
    }
    //
    // OrganizationBackend implementation ends
    //
    createPolicyGroup(_owner, _parameters) {
        return __awaiter(this, void 0, void 0, function* () {
            throw new Error("Not implemented");
        });
    }
    cleanEntityFromCaches(id) {
        return __awaiter(this, void 0, void 0, function* () {
            const entity = yield this.cache.delete(id);
            if (entity) {
                this.entityRelationCache.remove(entity);
            }
        });
    }
}
