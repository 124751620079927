/*
 * Copyright (C) 2023 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 *
 */

import React, { Component, Fragment, ReactNode } from "react";
import { Grid, List, ListItem } from "@material-ui/core";
import { translations } from "../../../../generated/translationHelper";
import { MigrationEvent, UserAdminDetails } from "@sade/data-access";

interface Props {
  userDetails: UserAdminDetails;
}

interface State {
  migrationDetails: MigrationEvent[];
  migratedDevices?: (string | undefined)[];
  migratedGuests?: string[];
}

export default class UserMigrationDetails extends Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = {
      migrationDetails: this.props.userDetails.getMigrationDetails(),
    };
  }

  public async componentDidMount(): Promise<void> {
    this.getMigratedDevices();
    this.getMigratedGuests();
  }

  private getMigratedDevices = (): void => {
    const migratedDevices = this.state.migrationDetails
      .filter((event) => event.type === "DEVICE_THING_CREATED")
      .map((event) => {
        if (event.data?.deviceId) return event.data.deviceId as string;
        if (event.data?.device) {
          const creationDetails = event.data.device as Record<string, string>;
          return creationDetails.id;
        }
      });
    this.setState({
      migratedDevices,
    });
  };

  private getMigratedGuests = (): void => {
    const [migratedGuests] = this.state.migrationDetails
      .filter((event) => event.type === "USER_VERIFIED_FROM_AZURE")
      .map((event) => {
        if (event.data?.guestUsers) return event.data.guestUsers as Record<string, unknown>[];
      });
    if (!migratedGuests) return;
    const guestNames = migratedGuests.map((guest) => {
      return `
      ${guest.givenName ? guest.givenName : ""}
      ${guest.givenName && guest.familyName ? " " : ""}
      ${guest.familyName ? guest.familyName : ""}
      `;
    });
    this.setState({
      migratedGuests: guestNames,
    });
  };

  private renderHeader(): ReactNode {
    return (
      <Grid container spacing={1} className="overview-header-grid">
        <Grid item>{translations.admin.texts.migrationInfo()}</Grid>
      </Grid>
    );
  }

  private renderMigrationDetails(): ReactNode {
    if (this.state.migrationDetails.length === 0) {
      return (
        <Grid container className="overview-content-grid">
          <Grid item className="no-content-grid">
            {translations.admin.texts.noMigration().toUpperCase()}
          </Grid>
        </Grid>
      );
    }
    const [timestamp] = this.state.migrationDetails.map((event) => event.timestamp.toLocaleString());
    const devices = this.state.migratedDevices;
    const users = this.state.migratedGuests;
    return (
      <Grid container className="overview-content-grid">
        <Grid item xs={4}>
          <List>
            <ListItem className="list-item">{translations.admin.texts.migrationDate().toUpperCase()}</ListItem>
            <ListItem className="list-item">{translations.admin.texts.migratedDevices().toUpperCase()}</ListItem>
            {devices?.map((v, index) => (
              <ListItem className="list-item" key={index} />
            ))}
            <ListItem className="list-item">{translations.admin.texts.guestsMigrated().toUpperCase()}</ListItem>
            {users?.map((v, index) => (
              <ListItem className="list-item" key={index} />
            ))}
          </List>
        </Grid>
        <Grid item>
          <List>
            <ListItem className="list-item">{timestamp}</ListItem>
            <ListItem className="list-item">
              {devices && devices.length > 0
                ? devices.length > 1
                  ? `${devices.length} ${translations.admin.texts.devices()}`
                  : `1 ${translations.admin.texts.device()}`
                : "No devices migrated"}
            </ListItem>
            {devices?.map((id) => (
              <ListItem className="list-item sub" key={id}>
                {id}
              </ListItem>
            ))}
            <ListItem className="list-item">
              {users && users.length > 0
                ? users.length > 1
                  ? `${users.length} ${translations.admin.texts.users()}`
                  : `1 ${translations.admin.texts.user()}`
                : "No users migrated"}
            </ListItem>
            {users?.map((email) => (
              <ListItem className="list-item sub" key={email}>
                {email}
              </ListItem>
            ))}
          </List>
        </Grid>
      </Grid>
    );
  }

  public render(): ReactNode {
    return (
      <Fragment>
        {this.renderHeader()}
        {this.renderMigrationDetails()}
      </Fragment>
    );
  }
}
