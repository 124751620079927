/*
 * Copyright (C) 2022 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */
export var DeliveryStatus;
(function (DeliveryStatus) {
    DeliveryStatus["Pending"] = "Pending";
    DeliveryStatus["Delivered"] = "Delivered";
    DeliveryStatus["DeliveryFailed"] = "DeliveryFailed";
})(DeliveryStatus || (DeliveryStatus = {}));
export var ResponseStatus;
(function (ResponseStatus) {
    ResponseStatus["None"] = "None";
    ResponseStatus["Accepted"] = "Accepted";
    ResponseStatus["Declined"] = "Declined";
})(ResponseStatus || (ResponseStatus = {}));
export var AccessType;
(function (AccessType) {
    AccessType["Guest"] = "Guest";
    AccessType["Owner"] = "Owner";
})(AccessType || (AccessType = {}));
export function isSentInvitation(invitation) {
    return (typeof invitation.delete === "function" &&
        typeof invitation.setActive === "function");
}
export function isReceivedInvitation(invitation) {
    return (typeof invitation.accept === "function" &&
        typeof invitation.decline === "function");
}
