/* Copyright */
import { AbstractSetSubscriptionManager } from "../utils";
import { DevicesAccessUpdateFeedDocument } from "../../generated/gqlDevice";
import { Service } from "../backend";
export class DeviceAccessSubscriptionManager extends AbstractSetSubscriptionManager {
    constructor() {
        super(Service.DEVICE, DevicesAccessUpdateFeedDocument);
    }
    subscriptionHandler(result) {
        var _a, _b;
        if (!result)
            return;
        const { deviceId, accessGranted } = (_b = (_a = result.devicesAccessUpdateFeed) === null || _a === void 0 ? void 0 : _a.item) !== null && _b !== void 0 ? _b : {};
        if (deviceId) {
            this.forEachListener((listener) => accessGranted ? listener.accessGranted(deviceId) : listener.accessRemoved(deviceId));
        }
    }
}
DeviceAccessSubscriptionManager.instance = new DeviceAccessSubscriptionManager();
