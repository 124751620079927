/*
 * Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 *
 */

import React, { Component, ReactNode } from "react";
import Loader from "../../ui/loader";
import { Maybe } from "../../../types/aliases";
import { AuthWrapper, isErrorWithCode } from "@sade/data-access";
import { Button, Grid, Typography } from "@material-ui/core";
import PasswordField from "../../ui/password-field";
import { translations } from "../../../generated/translationHelper";
import ErrorDialog from "../../ui/error-dialog";
import SuccessDialog from "../../ui/success-dialog";

interface Props {}

interface State {
  isLoading: boolean;
  username: string;
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
  error?: string;
  successMessage?: string;
}

export default class PasswordForm extends Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = {
      isLoading: false,
      username: "",
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    };
  }

  public componentDidMount(): void {
    this.getloggedInUserName();
  }

  private async getloggedInUserName(): Promise<void> {
    const username = await AuthWrapper.getCurrentAuthenticatedUsername();
    this.setState({ username });
  }

  private handlePasswordSubmit = async (): Promise<void> => {
    try {
      this.setState({ isLoading: true });
      await AuthWrapper.submitNewPassword(this.state.oldPassword, this.state.newPassword);
      this.setSuccessMessage(translations.user.texts.successInPasswordChange());
    } catch (error) {
      console.error("handlePasswordSubmit", error);
      if (isErrorWithCode(error)) this.handleErrorCode(error.message);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  private handleErrorCode(code?: string): void {
    switch (code) {
      case "Attempt limit exceeded, please try after some time.":
        this.setErrorMessage(translations.common.texts.tooManyAttempts());
        break;
      case "Incorrect username or password.":
        this.setErrorMessage(translations.user.texts.incorrectCredentials());
        break;
      case "Network error":
        this.setErrorMessage(translations.common.texts.networkError());
        break;
      case "Password did not conform with policy: Password must have numeric characters":
        this.setErrorMessage(translations.common.texts.passwordMustHaveNumbers());
        break;
      case "Password did not conform with policy: Password must have lowercase characters":
        this.setErrorMessage(translations.common.texts.passwordMustHaveLowercaseCharacters());
        break;
      case "1 validation error detected: Value at 'previousPassword' failed to satisfy constraint: Member must have length greater than or equal to 6":
      case "2 validation errors detected: Value at 'previousPassword' failed to satisfy constraint: Member must have length greater than or equal to 6; Value at 'proposedPassword' failed to satisfy constraint: Member must have length greater than or equal to 6":
      case "1 validation error detected: Value at 'proposedPassword' failed to satisfy constraint: Member must have length greater than or equal to 6":
      case "Password did not conform with policy: Password not long enough":
        this.setErrorMessage(translations.common.texts.passwordMustBeLongEnough());
        break;
      case "Invalid session for the user, session is expired.":
        this.setErrorMessage(translations.common.texts.userSessionExpired());
        break;
      default:
        this.setErrorMessage(translations.common.texts.unableToPerformAction());
        break;
    }
  }

  private setErrorMessage(error?: string): void {
    this.setState({ error });
  }

  private setSuccessMessage(successMessage?: string): void {
    this.setState({ successMessage });
  }

  private renderPasswordsNotMatchingMessage(): ReactNode {
    if (
      this.state.newPassword.length > 0 &&
      this.state.confirmPassword.length > 0 &&
      this.state.newPassword !== this.state.confirmPassword
    ) {
      return (
        <Grid item={true} container={true} justifyContent="center">
          <span className="user-errortext">{translations.common.texts.passwordsNotMatching()}</span>
        </Grid>
      );
    }
  }

  private renderLoader(): Maybe<JSX.Element> {
    if (this.state.isLoading) {
      return (
        <Grid item={true} container={true} justifyContent="center">
          <Loader />
        </Grid>
      );
    }
  }

  private renderErrorPopUpMessage(): ReactNode {
    if (this.state.error) {
      return <ErrorDialog errorMsg={this.state.error} onClose={(): void => this.setState({ error: undefined })} />;
    }
  }

  private renderSuccessPopUpMessage(): ReactNode {
    if (this.state.successMessage) {
      return (
        <SuccessDialog
          successMsg={this.state.successMessage}
          onClose={(): void =>
            this.setState({
              successMessage: undefined,
              // clear password states in order to render blank password field values after a successful password change:
              oldPassword: "",
              newPassword: "",
              confirmPassword: "",
            })
          }
        />
      );
    }
  }

  private renderInputs(): JSX.Element {
    const hasPasswords =
      this.state.oldPassword.length > 0 && this.state.newPassword.length > 0 && this.state.confirmPassword.length > 0;
    const newPasswordsMatch = this.state.newPassword === this.state.confirmPassword;
    return (
      <Grid item={true} xs={12} sm={10} md={8}>
        <Grid item={true}>
          <PasswordField
            fullWidth={true}
            label={translations.user.inputs.oldPassword()}
            inputProps={{ "data-testid": "old-password-field" }}
            autoComplete="current-password"
            value={this.state.oldPassword}
            margin="normal"
            onChange={(password: string): void => {
              this.setErrorMessage(undefined);
              this.setState({ oldPassword: password, successMessage: undefined });
            }}
          />
        </Grid>
        <Grid item={true}>
          <PasswordField
            fullWidth={true}
            label={translations.common.inputs.newPassword()}
            inputProps={{ "data-testid": "new-password-field" }}
            autoComplete="current-password"
            value={this.state.newPassword}
            margin="normal"
            onChange={(password: string): void => {
              this.setErrorMessage(undefined);
              this.setState({ newPassword: password, successMessage: undefined });
            }}
          />
        </Grid>
        <Grid item={true}>
          <PasswordField
            fullWidth={true}
            label={translations.common.inputs.confirmNewPassword()}
            inputProps={{ "data-testid": "confirm-password-field" }}
            autoComplete="current-password"
            value={this.state.confirmPassword}
            margin="normal"
            onChange={(password: string): void => {
              this.setErrorMessage(undefined);
              this.setState({ confirmPassword: password, successMessage: undefined });
            }}
          />
        </Grid>
        <Grid item={true} container={true} justifyContent="center">
          <Button
            disabled={!hasPasswords || !newPasswordsMatch}
            variant="contained"
            data-testid="confirm-change-password"
            color="primary"
            onClick={this.handlePasswordSubmit}
          >
            {translations.user.buttons.confirmPasswordChange()}
          </Button>
        </Grid>
      </Grid>
    );
  }

  public render(): JSX.Element {
    return (
      <Grid container={true} spacing={2}>
        <Grid item={true} container={true} justifyContent="center">
          <Typography variant="h6" style={{ fontWeight: "bold" }}>
            {translations.user.texts.enterOldPasswordAndNewPassword()}
          </Typography>
        </Grid>
        <Grid item={true} container={true} spacing={2} justifyContent="center">
          {this.renderInputs()}
        </Grid>
        {this.renderPasswordsNotMatchingMessage()}
        {this.renderLoader()}
        {this.renderErrorPopUpMessage()}
        {this.renderSuccessPopUpMessage()}
      </Grid>
    );
  }
}
