/*
 * Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 *
 */
const fields = [
    "enabled",
    "subDeviceTag",
    "action",
    "launchTime",
    "repeatOnMonday",
    "repeatOnTuesday",
    "repeatOnWednesday",
    "repeatOnThursday",
    "repeatOnFriday",
    "repeatOnSaturday",
    "repeatOnSunday",
];
const toBoolean = (str) => Boolean(Number(str));
const booleanToString = (b) => (b ? "1" : "0");
const launchTimeToString = (input) => input.split(":").join(",");
const toNumber = (str) => Number(str);
const numberToString = (n) => n.toString(10);
const identity = (str) => str;
const convertToValue = {
    action: toNumber,
    enabled: toBoolean,
    launchTime: identity,
    repeatOnFriday: toBoolean,
    repeatOnMonday: toBoolean,
    repeatOnSaturday: toBoolean,
    repeatOnSunday: toBoolean,
    repeatOnThursday: toBoolean,
    repeatOnTuesday: toBoolean,
    repeatOnWednesday: toBoolean,
    subDeviceTag: identity,
};
const convertToString = {
    action: numberToString,
    enabled: booleanToString,
    launchTime: launchTimeToString,
    repeatOnFriday: booleanToString,
    repeatOnMonday: booleanToString,
    repeatOnSaturday: booleanToString,
    repeatOnSunday: booleanToString,
    repeatOnThursday: booleanToString,
    repeatOnTuesday: booleanToString,
    repeatOnWednesday: booleanToString,
    subDeviceTag: identity,
};
function parseStringToTimedEventValue(name, value) {
    const ret = convertToValue[name](value);
    if (ret == null)
        throw new Error(`no conversion function for ${name} with value ${value}`);
    return ret;
}
function parseTimedEventValueToString(name, value) {
    const ret = convertToString[name](value);
    if (ret == null)
        throw new Error(`no conversion function for ${name} with value ${value}`);
    return ret;
}
const parseTimedEventFromString = (value) => {
    const listOfValues = value.split(",");
    // Get time from string. Original array is also modified with splice
    // Not fan of modifying the original value, but I think works nice for this case
    const time = listOfValues.splice(3, 3).join(":");
    // Create new array and add parsed time back to right place
    const withParsedTme = [...listOfValues.slice(0, 3), time, ...listOfValues.slice(3)];
    return withParsedTme.reduce((prevValue, currentValue, currentIndex) => {
        const name = fields[currentIndex];
        prevValue[name] = parseStringToTimedEventValue(name, currentValue);
        return prevValue;
    }, {});
};
const createEventStringChunks = (value, size = 13) => {
    const valueList = value.split(",");
    if (valueList.length % size !== 0) {
        throw Error(`String is not divisible by size (${size})`);
    }
    const result = [];
    // Splits string to chunks and adds them to array.
    for (let i = 0; i < valueList.length; i += size) {
        const chunk = valueList.slice(i, i + size);
        result.push(chunk.join(","));
    }
    return result;
};
const parseTimedEventToString = (timedEvent) => fields.map((fieldName) => parseTimedEventValueToString(fieldName, timedEvent[fieldName])).join(",");
export const parseTimedEventStrings = (value) => createEventStringChunks(value).map(parseTimedEventFromString);
export const parseTimedEventsToString = (timedEvents) => timedEvents.map(parseTimedEventToString).join(",");
