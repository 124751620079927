/*
 * Copyright (C) 2023 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 *
 */

import React, { Component, ReactNode } from "react";
import { Chart } from "react-google-charts";
import { EventsRepository } from "@sade/data-access";
import { Moment } from "moment";

const CHART_OPTIONS = {
  chartArea: { left: 0, top: 0, width: "100%", height: "100%" },
  legend: { position: "left", alignment: "center", textStyle: { fontSize: 12 } },
  pieSliceText: "value",
  pieSliceTextStyle: { fontSize: 14 },
};

interface Props {
  deviceId: string;
  data: Map<string /* event id */, Moment[] /* event dates */>;
}

interface State {
  chartData?: (string[] | [string, number])[];
}

export default class EventsPieChart extends Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = {};
  }

  public async componentDidMount(): Promise<void> {
    this.updateChartData();
  }

  public async componentDidUpdate(prevProps: Readonly<Props>): Promise<void> {
    if (prevProps.deviceId !== this.props.deviceId || prevProps.data !== this.props.data) {
      this.updateChartData();
    }
  }

  private updateChartData = (): void => {
    if (this.props.data.size === 0) {
      return;
    }
    const chartData: [eventId: string, eventCount: number][] = [...this.props.data.entries()].map(
      ([eventId, eventDates]) => [EventsRepository.instance.getEventDescription(eventId), eventDates.length]
    );
    this.setState({
      chartData: [["eventId", "count"], ...chartData],
    });
  };

  public render(): ReactNode {
    if (!this.state.chartData) return null;
    return <Chart chartType="PieChart" data={this.state.chartData} options={CHART_OPTIONS} />;
  }
}
