/*
 * Copyright (C) 2022 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */
import { __awaiter } from "tslib";
import { AppSyncClientFactory, Service } from "../backend";
import { EventsFeedDocument, EventsMetadataListDocument } from "../../generated/gqlEvents";
import { AbstractSetSubscriptionManager } from "../utils";
export class EventsRepository extends AbstractSetSubscriptionManager {
    constructor() {
        super(Service.EVENTS, EventsFeedDocument);
        this.events = [];
        this.eventMetadata = new Map();
    }
    static get instance() {
        return EventsRepository.__instance;
    }
    static getIsSameEventComparator(event) {
        return (e) => event.deviceId === e.deviceId && event.eventId === e.eventId && event.timestamp === e.timestamp;
    }
    init() {
        return __awaiter(this, void 0, void 0, function* () {
            this.eventMetadata = new Map();
            yield this.fetchEventMetadata();
            this.subscribeOnce();
        });
    }
    uninit() {
        this.unsubscribe();
        if (this.eventMetadata) {
            this.eventMetadata.clear();
        }
    }
    getEventName(eventId) {
        var _a, _b;
        return (_b = (_a = this.eventMetadata.get(eventId)) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : eventId;
    }
    getEventDescription(eventId) {
        var _a, _b;
        return (_b = (_a = this.eventMetadata.get(eventId)) === null || _a === void 0 ? void 0 : _a.description) !== null && _b !== void 0 ? _b : eventId;
    }
    getAllEventDefinitions() {
        return [...this.eventMetadata.values()];
    }
    subscriptionHandler(result) {
        if (!(result === null || result === void 0 ? void 0 : result.eventsFeed))
            return;
        const event = result.eventsFeed.item;
        const isNew = this.handleEvent(event);
        this.forEachListener((listener) => {
            if (isNew) {
                listener.onEvent(event);
            }
            else {
                listener.onEventStateChanged(event);
            }
        });
    }
    fetchEventMetadata() {
        var _a, _b, _c;
        return __awaiter(this, void 0, void 0, function* () {
            let nextToken = null;
            try {
                do {
                    const client = AppSyncClientFactory.createProvider().getTypedClient(Service.EVENTS);
                    const metadataResponse = yield client.query(EventsMetadataListDocument, {
                        nextToken,
                    }, {
                        fetchPolicy: "network-only",
                    });
                    // cast is required or response's type inference becomes cyclic
                    nextToken = ((_b = (_a = metadataResponse.data.eventsMetadataList) === null || _a === void 0 ? void 0 : _a.nextToken) !== null && _b !== void 0 ? _b : null);
                    (_c = metadataResponse.data.eventsMetadataList) === null || _c === void 0 ? void 0 : _c.eventMetadataItems.forEach((metadata) => {
                        const { eventId, name, description } = metadata;
                        this.eventMetadata.set(eventId, { eventId, name: name !== null && name !== void 0 ? name : undefined, description: description !== null && description !== void 0 ? description : undefined });
                    });
                } while (nextToken);
            }
            catch (error) {
                console.error("Error", error);
            }
        });
    }
    handleEvent(event) {
        const index = this.events.findIndex(EventsRepository.getIsSameEventComparator(event));
        const isNew = index === -1;
        if (isNew) {
            this.events.push(event);
        }
        else {
            this.events[index] = event;
        }
        return isNew;
    }
}
EventsRepository.EVENT_AGE_DAYS = 365;
EventsRepository.__instance = new EventsRepository();
