/*
 * Copyright (C) 2022 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */
import { Hub } from "aws-amplify";
import { AuthWrapper } from "."; // importing through index.ts avoids circular reference problems (ugly!)
export class AuthListener {
    constructor(callback, notifyRetroactively) {
        this.removeHandle = Hub.listen("auth", (data) => AuthListener.listener(data, callback));
        if (notifyRetroactively) {
            // if the has authenticated before the listener is registered,
            // retroactively notify the listener creator about the situation
            AuthWrapper.isCurrentUserAuthenticated().then((authenticated) => {
                if (authenticated) {
                    callback("SignedIn");
                }
            });
        }
    }
    static listener(data, callback) {
        switch (data.payload.event) {
            case "signIn":
                callback("SignedIn");
                break;
            case "signUp":
                callback("SignedUp");
                break;
            case "signOut":
                callback("SignedOut");
                break;
            case "signIn_failure":
                callback("SignInFailed");
                break;
            case "tokenRefresh":
                callback("TokenRefreshSucceeded");
                break;
            case "tokenRefresh_failure":
                callback("TokenRefreshFailed");
                break;
        }
    }
    removeListener() {
        var _a;
        (_a = this.removeHandle) === null || _a === void 0 ? void 0 : _a.call(this);
        this.removeHandle = undefined;
    }
}
