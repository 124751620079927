/*
 * Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */

import React, { Component, ReactNode } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Gatekeeper from "./components/access-control/gatekeeper";
import Content from "./components/content";
import LoginView from "./components/login-view";
import Paths from "./components/Paths";

export default class App extends Component {
  private static renderWithGatekeeper(
    authenticatedComponent: ReactNode,
    redirectPath: Paths,
    redirectIfAuthenticated?: boolean
  ): JSX.Element {
    return (
      <Gatekeeper redirectIfAuthenticated={redirectIfAuthenticated} redirectPath={redirectPath}>
        {authenticatedComponent}
      </Gatekeeper>
    );
  }

  public render(): ReactNode {
    return (
      <BrowserRouter>
        <Switch>
          <Route
            path={Paths.LOGIN}
            component={(): JSX.Element => App.renderWithGatekeeper(<LoginView />, Paths.ROOT, true)}
          />
          <Route path={Paths.ROOT} render={(): JSX.Element => App.renderWithGatekeeper(<Content />, Paths.LOGIN)} />
        </Switch>
      </BrowserRouter>
    );
  }
}
