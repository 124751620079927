/*
 * Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */

import { Grid } from "@material-ui/core";
import React, { Component, Fragment, ReactNode } from "react";

import { AuthWrapper, SessionSet, TimeWrapper, BackendFactory, TimePeriod } from "@sade/data-access";
import ErrorDialog from "../ui/error-dialog";
import Loader from "../ui/loader";
import TimeRangePicker from "../ui/time-range-picker";
import DropdownSelection from "../ui/dropdown-selection";
import { Maybe } from "../../types/aliases";
import { RouteComponentProps, withRouter } from "react-router";
import { translations } from "../../generated/translationHelper";
import accessControlled from "../access-control/access-controlled";
import ViewAccessMethods from "../../ViewAccessMethods";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const QuickSightEmbedding = require("amazon-quicksight-embedding-sdk");

interface QsParams {
  url: string;
  container: HTMLElement;
  width: string;
  height: string;
  parameters: {
    startTime?: number;
    endTime?: number;
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type QuickSightDashboard = any;

type Props = RouteComponentProps;

interface State {
  startTimestamp: number;
  endTimestamp: number;
  isLoading: boolean;
  selection?: number;
  sessionSet?: SessionSet;
  errorMsg?: string;
}

export class AnalyticsView extends Component<Props, State> {
  private dashboard: QuickSightDashboard = null;

  public constructor(props: Props) {
    super(props);
    const endTimestamp = Date.now();
    const startTimestamp = TimeWrapper.getTimestampXUnitsAgo(endTimestamp, 1, "month");
    this.state = {
      startTimestamp,
      endTimestamp,
      isLoading: true,
    };
  }

  public async componentDidMount(): Promise<void> {
    await this.createDashboard();
  }

  private async createDashboard(): Promise<void> {
    try {
      this.setState({ isLoading: true });

      const params: QsParams = {
        url: await AnalyticsView.getEmbeddingUrl(),
        container: await AnalyticsView.getEmbeddingElement(),
        width: "100%",
        height: "98%",
        parameters: {
          startTime: this.state.startTimestamp,
          endTime: this.state.endTimestamp,
        },
      };
      this.dashboard = QuickSightEmbedding.embedDashboard(params);
      this.dashboard.on("error", this.onDashboardError);
      this.dashboard.on("load", this.onDashboardLoad);
    } catch (error) {
      console.error("createDashboard", error);
      this.setState({ errorMsg: translations.analytics.texts.dashboardCreateError() });
    } finally {
      this.setState({ isLoading: false });
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private onDashboardLoad = (payload: any): void => {
    console.log("onDashboardLoad", payload);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private onDashboardError = (payload: any): void => {
    console.error("onDashboardError", payload);
    this.setState({ errorMsg: translations.analytics.texts.dashboardLoadError() });
  };

  private updateDashboardTimestamps(start: number, end: number): void {
    if (this.dashboard) {
      this.dashboard.setParameters({
        startTime: start,
        endTime: end,
      });
    }
  }

  private handleCloseErrorNote = (): void => {
    this.setState({ errorMsg: undefined });
  };

  private handleTimeRangeSelect = async (startTimestamp: number, endTimestamp: number): Promise<void> => {
    this.setState({
      startTimestamp,
      endTimestamp,
    });
    this.updateDashboardTimestamps(startTimestamp, endTimestamp);
  };

  private static async getEmbeddingUrl(): Promise<string> {
    const dashboardId = process.env.REACT_APP_DEFAULT_QUICKSIGHT_DASHBOARD_ID;

    if (!dashboardId) {
      throw new Error("Dashboard id not set");
    }

    const openIdToken = await AuthWrapper.getOpenIdToken();

    if (!openIdToken) {
      throw new Error("No open id token");
    }

    const embedUrl = await BackendFactory.getBackend().getQsEmbedUrl(openIdToken, dashboardId);

    if (!embedUrl) {
      throw new Error("Did not receive embedding url");
    }

    return embedUrl;
  }

  private static async getEmbeddingElement(): Promise<HTMLElement> {
    const embeddingElement = document.getElementById("dashboardContainer");

    if (!embeddingElement) {
      throw new Error("Could not find container");
    }
    return embeddingElement;
  }

  private static getTimestampsBySelection(selection: number): Maybe<TimePeriod> {
    switch (selection) {
      case 0:
        return {
          startTimestamp: TimeWrapper.getStartOfXDaysAgo(0),
          endTimestamp: Date.now(),
        };
      case 1:
        return {
          startTimestamp: TimeWrapper.getStartOfXDaysAgo(1),
          endTimestamp: TimeWrapper.getStartOfXDaysAgo(0),
        };
      case 2:
        return {
          startTimestamp: TimeWrapper.getTimestampXDaysAgo(7),
          endTimestamp: Date.now(),
        };
      case 3:
        return {
          startTimestamp: TimeWrapper.getTimestampXDaysAgo(14),
          endTimestamp: Date.now(),
        };
      case 4:
        return {
          startTimestamp: TimeWrapper.getTimestampXDaysAgo(30),
          endTimestamp: Date.now(),
        };
    }
  }

  private handleDropdownSelect = (selection?: number): void => {
    if (selection != null) {
      const queryArguments = AnalyticsView.getTimestampsBySelection(selection);

      if (queryArguments) {
        this.setState({
          selection,
          startTimestamp: queryArguments.startTimestamp,
          endTimestamp: queryArguments.endTimestamp,
        });
        this.updateDashboardTimestamps(queryArguments.startTimestamp, queryArguments.endTimestamp);
      }
    } else {
      this.setState({
        selection,
      });
    }
  };

  private isTimeRangeSelectionDisabled(): boolean {
    return this.state.selection !== undefined;
  }

  private renderToolbar(): ReactNode {
    return (
      <Grid
        container={true}
        spacing={2}
        alignItems="center"
        justifyContent="center"
        style={{ marginTop: "2rem", width: "100%" }}
      >
        <Grid item={true}>
          <DropdownSelection
            label={translations.analytics.inputs.period()}
            selectionList={[
              { key: "today", label: translations.analytics.inputs.today() },
              { key: "yesterday", label: translations.analytics.inputs.yesterday() },
              { key: "last7days", label: translations.analytics.inputs.lastXdays({ days: 7 }) },
              { key: "last14days", label: translations.analytics.inputs.lastXdays({ days: 14 }) },
              { key: "last30days", label: translations.analytics.inputs.lastXdays({ days: 30 }) },
            ]}
            emptySelectionItem={translations.common.inputs.custom()}
            onSelect={this.handleDropdownSelect}
          />
        </Grid>
        <Grid item={true}>
          <TimeRangePicker
            startTimestamp={this.state.startTimestamp}
            endTimestamp={this.state.endTimestamp}
            onTimeRangeSelect={this.handleTimeRangeSelect}
            disabled={this.isTimeRangeSelectionDisabled()}
          />
        </Grid>
      </Grid>
    );
  }

  private renderDashboard(): ReactNode {
    const suffix = this.state.isLoading ? ".hidden" : "";
    const className = `iot-content-container${suffix} col-sm-12 col-xsm-12`;

    return (
      <Fragment>
        <div id="dashboardContainer" className={className} />
        {this.renderLoader()}
      </Fragment>
    );
  }

  private renderLoader(): ReactNode {
    return <Loader show={this.state.isLoading} />;
  }

  private renderErrorNote(): ReactNode {
    if (this.state.errorMsg) {
      return <ErrorDialog errorMsg={this.state.errorMsg} onClose={this.handleCloseErrorNote} />;
    }
  }

  public render(): ReactNode {
    return (
      <Fragment>
        {this.renderToolbar()}
        {this.renderErrorNote()}
        {this.renderDashboard()}
      </Fragment>
    );
  }
}

export default withRouter(accessControlled(AnalyticsView, ViewAccessMethods.hasAdminAccess));
