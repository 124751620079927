/*
 * Copyright (C) 2023 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */

import { Button } from "@material-ui/core";
import React, { Component, Fragment, ReactNode } from "react";
import { Device } from "@sade/data-access";
import OtaUpdatePopup from "./ota-update-popup";
import { translations } from "../../../../../generated/translationHelper";

interface Props {
  device?: Device;
}

interface State {
  showSettings: boolean;
}

export default class OtaUpdateButton extends Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = {
      showSettings: false,
    };
  }

  private toggleOtaUpdate = (): void => {
    this.setState((prevState: State) => ({ showSettings: !prevState.showSettings }));
  };

  private renderOtaPopupIcon(): ReactNode {
    if (!this.props.device) return null;
    return (
      <Button variant="contained" color="primary" onClick={this.toggleOtaUpdate}>
        {translations.deviceSettings.tabs.otaUpdate()}
      </Button>
    );
  }

  private renderSettingsPopup(): ReactNode {
    if (this.state.showSettings) {
      return <OtaUpdatePopup selectedDevice={this.props.device!} closeOtaUpdate={this.toggleOtaUpdate} />;
    }
  }

  public render(): ReactNode {
    return (
      <Fragment>
        {this.renderOtaPopupIcon()}
        {this.renderSettingsPopup()}
      </Fragment>
    );
  }
}
