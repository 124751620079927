/*
 * Copyright (C) 2022 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */
import { __awaiter } from "tslib";
import { Auth } from "aws-amplify";
import AWSAppSyncClient, { AUTH_TYPE, defaultDataIdFromObject } from "aws-appsync";
import { TypedAppSyncClient } from "./TypedAppSyncClient";
import { AppConfiguration, AuthListener } from "../auth";
// using non-zero positive values in order for client caching to work
export var Service;
(function (Service) {
    Service[Service["USERS"] = 1] = "USERS";
    Service[Service["DATA"] = 2] = "DATA";
    Service[Service["DEVICE"] = 3] = "DEVICE";
    Service[Service["EVENTS"] = 4] = "EVENTS";
    Service[Service["STATS"] = 5] = "STATS";
})(Service || (Service = {}));
// using prime numbers to avoid client cache collisions
export var ClientType;
(function (ClientType) {
    ClientType[ClientType["TYPE_COGNITO"] = 3] = "TYPE_COGNITO";
    ClientType[ClientType["TYPE_IAM"] = 5] = "TYPE_IAM";
})(ClientType || (ClientType = {}));
// TODO: merge this with AppSyncClientFactory
export default class AppSyncClientProvider {
    constructor() {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.clientCache = []; // sparse array
        this.authEventHandler = (event) => {
            if (event === "SignedOut") {
                this.clientCache = [];
            }
        };
        // TODO: Fix any type
        this.authListener = new AuthListener(this.authEventHandler);
    }
    getTypedClient(service, type = ClientType.TYPE_COGNITO) {
        const innerClient = this.getAppSyncClient(service, type);
        return new TypedAppSyncClient(innerClient);
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getAppSyncClient(service, type = ClientType.TYPE_COGNITO) {
        const cacheSlot = service * type;
        if (!this.clientCache[cacheSlot]) {
            this.clientCache[cacheSlot] = this.createClient(service, type);
        }
        return this.clientCache[cacheSlot];
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    createClient(service, type) {
        let authOptions;
        if (type === ClientType.TYPE_IAM) {
            authOptions = {
                credentials: () => Auth.currentCredentials(),
                type: AUTH_TYPE.AWS_IAM,
            };
        }
        else if (type === ClientType.TYPE_COGNITO) {
            authOptions = {
                // using Identity Token for authentication, otherwise our custom claims cannot be added to the auth token.
                // this has the distinct downside that the token cannot be invalidated from "server" side, only timeout will
                // clean identity tokens. maybe one day AWS will allow custom claims on access tokens :fingers-crossed:
                jwtToken: () => __awaiter(this, void 0, void 0, function* () { return (yield Auth.currentSession()).getIdToken().getJwtToken(); }),
                type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
            };
        }
        else {
            throw new Error("Unknown AppSync authentication requested!");
        }
        const awsConfig = AppConfiguration.getAwsConfiguration();
        return new AWSAppSyncClient({
            auth: authOptions,
            region: awsConfig.AppSync.Region,
            url: this.getEndpoint(service, awsConfig),
            disableOffline: true,
            cacheOptions: {
                dataIdFromObject: (obj) => {
                    const defaultId = defaultDataIdFromObject(obj);
                    const { __typename: typename } = obj;
                    switch (typename) {
                        case "InvitationUser":
                            if (defaultId && defaultId.includes(":null")) {
                                return `${typename}:${obj.email}`;
                            }
                            break;
                        case "InvitationObject":
                            return null;
                    }
                    return defaultId;
                },
            },
        });
    }
    getEndpoint(service, awsConfig) {
        switch (service) {
            case Service.USERS:
                return awsConfig.AppSync.GqlEndpointUsers;
            case Service.DATA:
                return awsConfig.AppSync.GqlEndpointData;
            case Service.DEVICE:
                return awsConfig.AppSync.GqlEndpointDevice;
            case Service.EVENTS:
                return awsConfig.AppSync.GqlEndpointEvents;
            case Service.STATS:
                return awsConfig.AppSync.GqlEndpointStats;
            default:
                throw new Error("Unknown AppSync service requested");
        }
    }
}
