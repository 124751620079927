import { __awaiter, __rest } from "tslib";
import { InvitationsDeleteDocument, InvitationsRespondDocument, InvitationsStatesUpdateDocument, InvitationsUpdateDocument, } from "../../generated/gqlUsers";
import { AppSyncClientFactory, Service } from "../backend";
import { throwGQLError } from "../utils";
import { equalInvitationObjects } from "./Utils";
class AWSInvitation {
    constructor(invitation) {
        this.setValues(invitation);
    }
    get receiver() {
        return Object.assign({}, this._receiver);
    }
    get sender() {
        return Object.assign({}, this._sender);
    }
    get responseDate() {
        return this._responseDate;
    }
    get responseStatus() {
        return this._responseStatus;
    }
    get deliveryStatus() {
        return this._deliveryStatus;
    }
    get id() {
        return this._id;
    }
    get devices() {
        return [...this._devices];
    }
    get creationDate() {
        return this._creationDate;
    }
    get active() {
        return this._active;
    }
    get activeChangedDate() {
        return this._activeChangedDate;
    }
    setValues(invitation) {
        var _a, _b, _c, _d, _e;
        const sender = invitation.sender;
        const receiver = invitation.receiver;
        if (sender.id == null)
            throw new Error("Invalid sender, missing id!");
        this._active = invitation.active;
        this._activeChangedDate = invitation.activeChangedDate ? new Date(invitation.activeChangedDate) : undefined;
        this._creationDate = new Date(invitation.creationDate);
        this._devices = invitation.devices.map((gqlDevice) => {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { __typename } = gqlDevice, rest = __rest(gqlDevice, ["__typename"]);
            return rest;
        });
        this._id = invitation.id;
        this._sender = {
            email: sender.email,
            id: sender.id,
            firstName: (_a = sender.firstName) !== null && _a !== void 0 ? _a : undefined,
            lastName: (_b = sender.lastName) !== null && _b !== void 0 ? _b : undefined,
        };
        this._receiver = {
            email: receiver.email,
            id: (_c = receiver.id) !== null && _c !== void 0 ? _c : undefined,
            firstName: (_d = receiver.firstName) !== null && _d !== void 0 ? _d : undefined,
            lastName: (_e = receiver.lastName) !== null && _e !== void 0 ? _e : undefined,
        };
        this._deliveryStatus = invitation.deliveryStatus;
        this._responseStatus = invitation.responseStatus;
        this._responseDate = invitation.responseDate ? new Date(invitation.responseDate) : undefined;
    }
}
export class AWSSentInvitation extends AWSInvitation {
    constructor(invitation) {
        super(invitation);
        this.deleted = false;
    }
    delete() {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            if (this.deleted) {
                this.printDeleteWarning();
                return;
            }
            const client = AppSyncClientFactory.createProvider().getTypedClient(Service.USERS);
            const result = yield client.mutate(InvitationsDeleteDocument, {
                invitationId: this.id,
            });
            if (!((_a = result.data) === null || _a === void 0 ? void 0 : _a.invitationsDelete)) {
                throwGQLError(result, "Failed to delete invitation");
            }
            this.deleted = true;
        });
    }
    setActive(active) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            if (this.deleted) {
                this.printDeleteWarning();
                return;
            }
            const client = AppSyncClientFactory.createProvider().getTypedClient(Service.USERS);
            const result = yield client.mutate(InvitationsStatesUpdateDocument, {
                invitationId: this.id,
                active,
            });
            if (!((_a = result.data) === null || _a === void 0 ? void 0 : _a.invitationsStatesUpdate)) {
                throwGQLError(result, "Failed to change invitation's activity status");
            }
            this.setValues(result.data.invitationsStatesUpdate);
        });
    }
    updateDevices(newDevices) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            if (equalInvitationObjects(this.devices, newDevices))
                return;
            const client = AppSyncClientFactory.createProvider().getTypedClient(Service.USERS);
            const response = yield client.mutate(InvitationsUpdateDocument, {
                invitationId: this.id,
                payload: {
                    devices: newDevices,
                },
            });
            if (!((_a = response.data) === null || _a === void 0 ? void 0 : _a.invitationsUpdate)) {
                throwGQLError(response, "Failed to update invitation devices");
            }
            this.setValues(response.data.invitationsUpdate);
        });
    }
    printDeleteWarning() {
        console.warn(`Invitation '${this.id}' has been deleted`);
    }
}
export class AWSReceivedInvitation extends AWSInvitation {
    constructor(invitation, responseListener) {
        super(invitation);
        this.responseListener = responseListener;
    }
    accept() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.respond(true);
        });
    }
    decline() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.respond(false);
        });
    }
    respond(accept) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            const client = AppSyncClientFactory.createProvider().getTypedClient(Service.USERS);
            const result = yield client.mutate(InvitationsRespondDocument, {
                invitationId: this.id,
                acceptInvitation: accept,
            });
            if (!((_a = result.data) === null || _a === void 0 ? void 0 : _a.invitationsRespond)) {
                throwGQLError(result, "Responding to invitation failed");
            }
            this.setValues(result.data.invitationsRespond);
            yield this.responseListener();
        });
    }
}
