/*
 * Copyright (C) 2022 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */
import { __awaiter } from "tslib";
import { AppSyncClientFactory } from "../backend/AppSyncClientFactory";
import { Service } from "../backend/AppSyncClientProvider";
import { DataUtil } from "./Data";
import { LatestData } from "./LatestData";
import { LatestDataSubscriptionManager } from "./LatestDataSubscriptionManager";
import { DevicesMeasurementsLatestDocument } from "../../generated/gqlData";
import { timestampToHumanReadable } from "../utils/Utils";
export class AWSLatestData extends LatestData {
    constructor(deviceId, type) {
        super();
        this.deviceId = deviceId;
        this.type = type;
    }
    getId() {
        return this.deviceId;
    }
    getData() {
        return this.data;
    }
    fetch() {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            try {
                if (this.deviceId != null) {
                    const appSyncClient = AppSyncClientFactory.createProvider().getTypedClient(Service.DATA);
                    const latestDataResponse = yield appSyncClient.query(DevicesMeasurementsLatestDocument, {
                        deviceId: this.deviceId,
                    }, 
                    // mobile-app specific: Force fetch or will return old data from cache
                    {
                        fetchPolicy: "network-only",
                    });
                    if (latestDataResponse.data.devicesMeasurementsLatest) {
                        this.data = DataUtil.parseDataFragment(latestDataResponse.data.devicesMeasurementsLatest, this.type);
                        console.log("LatestData fetched:", timestampToHumanReadable((_a = this.data) === null || _a === void 0 ? void 0 : _a.timestamp));
                    }
                }
            }
            catch (error) {
                console.error("Error", error);
            }
        });
    }
    setData(data) {
        var _a;
        const dataWithDefinedValues = Object.fromEntries(Object.entries(data).filter(([_, v]) => v != null));
        this.data = Object.assign(Object.assign({}, this.data), dataWithDefinedValues);
        console.log("LatestData updated:", timestampToHumanReadable((_a = this.data) === null || _a === void 0 ? void 0 : _a.timestamp));
        this.notifyAction((observer) => observer.onDataUpdate(this));
    }
    addObserver(observer) {
        const _super = Object.create(null, {
            addObserver: { get: () => super.addObserver }
        });
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.data) {
                yield this.fetch();
            }
            _super.addObserver.call(this, observer);
            if (this.observerCount === 1) {
                LatestDataSubscriptionManager.instance.addListener(this);
            }
        });
    }
    removeObserver(observer) {
        super.removeObserver(observer);
        if (this.observerCount === 0) {
            LatestDataSubscriptionManager.instance.removeListener(this);
        }
    }
}
