/*
 * Copyright (C) 2022 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 *
 */
import { __awaiter } from "tslib";
import { PolicyGroup } from "./PolicyGroup";
import { User } from "./User";
import { AppSyncClientFactory } from "../backend/AppSyncClientFactory";
import { Service } from "../backend/AppSyncClientProvider";
import { PolicyGroupsDeleteDocument, PolicyGroupsUpdateDocument, PolicyGroupsUsersAddDocument, PolicyGroupsUsersListDocument, PolicyGroupsUsersRemoveDocument, ResultType, } from "../../generated/gqlUsers";
import { throwGQLError } from "../utils/Utils";
import { PromiseSemaphore } from "../utils/PromiseSemaphore";
import { AWSUser } from "./AWSUser";
import { verifyUserType } from "./AWSTypeUtils";
export class AWSPolicyGroup extends PolicyGroup {
    constructor(backend, parameters) {
        super(parameters);
        this.backend = backend;
        this.entityType = AWSPolicyGroup;
        this.usersSemaphore = new PromiseSemaphore(() => this.backendFetchUsers());
    }
    static instanceOf(value) {
        return value instanceof AWSPolicyGroup;
    }
    getUsers() {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.usersSemaphore.guard();
            const users = this.backend.entityRelationCache.listFor(this, AWSUser);
            users.sort(User.alphabeticUserOrdering);
            return users;
        });
    }
    update(parameters) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            if (Object.keys(parameters).length === 0) {
                return;
            }
            const client = AppSyncClientFactory.createProvider().getTypedClient(Service.USERS);
            const response = yield client.mutate(PolicyGroupsUpdateDocument, {
                payload: Object.assign({ id: this.id }, parameters),
            });
            if (!((_a = response.data) === null || _a === void 0 ? void 0 : _a.policyGroupsUpdate)) {
                throwGQLError(response, "Failed to update policy group");
            }
            const nameUpdated = this.name !== response.data.policyGroupsUpdate.name;
            const policiesUpdated = new Set([...this.policies, ...response.data.policyGroupsUpdate.policies]).size !== this.policies.length;
            this.name = response.data.policyGroupsUpdate.name;
            this.policies = response.data.policyGroupsUpdate.policies;
            if (nameUpdated) {
                this.notifyAction((observer) => { var _a; return (_a = observer.onNameChange) === null || _a === void 0 ? void 0 : _a.call(observer, this); });
            }
            if (policiesUpdated) {
                this.notifyAction((observer) => { var _a; return (_a = observer.onPoliciesChange) === null || _a === void 0 ? void 0 : _a.call(observer, this.policies, this); });
            }
        });
    }
    addUser(user) {
        var _a, _b, _c, _d;
        return __awaiter(this, void 0, void 0, function* () {
            verifyUserType(user);
            const client = AppSyncClientFactory.createProvider().getTypedClient(Service.USERS);
            const response = yield client.mutate(PolicyGroupsUsersAddDocument, {
                userId: user.getId(),
                policyGroupId: this.id,
            });
            if (!response.data || ((_a = response.data.policyGroupsUsersAdd) === null || _a === void 0 ? void 0 : _a.result) !== ResultType.Ok) {
                throwGQLError(response, (_d = (_c = (_b = response.data) === null || _b === void 0 ? void 0 : _b.policyGroupsUsersAdd) === null || _c === void 0 ? void 0 : _c.failureReason) !== null && _d !== void 0 ? _d : "Failed to add user to policy group");
            }
            this.backend.entityRelationCache.link(this, user);
        });
    }
    removeUser(user) {
        var _a, _b, _c, _d;
        return __awaiter(this, void 0, void 0, function* () {
            verifyUserType(user);
            const client = AppSyncClientFactory.createProvider().getTypedClient(Service.USERS);
            const response = yield client.mutate(PolicyGroupsUsersRemoveDocument, {
                userId: user.getId(),
                policyGroupId: this.id,
            });
            if (!response.data || ((_a = response.data.policyGroupsUsersRemove) === null || _a === void 0 ? void 0 : _a.result) !== ResultType.Ok) {
                throwGQLError(response, (_d = (_c = (_b = response.data) === null || _b === void 0 ? void 0 : _b.policyGroupsUsersRemove) === null || _c === void 0 ? void 0 : _c.failureReason) !== null && _d !== void 0 ? _d : "Failed to remove user from policy group");
            }
            this.backend.entityRelationCache.unlink(this, user);
        });
    }
    delete() {
        var _a, _b, _c, _d;
        return __awaiter(this, void 0, void 0, function* () {
            const client = AppSyncClientFactory.createProvider().getTypedClient(Service.USERS);
            const response = yield client.mutate(PolicyGroupsDeleteDocument, { policyGroupId: this.id });
            if (!response.data || ((_a = response.data.policyGroupsDelete) === null || _a === void 0 ? void 0 : _a.result) !== ResultType.Ok) {
                throwGQLError(response, (_d = (_c = (_b = response.data) === null || _b === void 0 ? void 0 : _b.policyGroupsDelete) === null || _c === void 0 ? void 0 : _c.failureReason) !== null && _d !== void 0 ? _d : "Failed to delete policy group");
            }
            yield this.backend.cleanEntityFromCaches(this.id);
            this.notifyAction((observer) => { var _a; return (_a = observer.onDelete) === null || _a === void 0 ? void 0 : _a.call(observer, this); });
            this.clearObservers();
        });
    }
    onRelationChange(change) {
        if (change.ofType(AWSUser) && this.usersSemaphore.invoked()) {
            const users = this.backend.entityRelationCache.listFor(this, AWSUser);
            this.notifyAction((observer) => { var _a; return (_a = observer.onUsersChange) === null || _a === void 0 ? void 0 : _a.call(observer, users, this); });
        }
    }
    backendFetchUsers() {
        return __awaiter(this, void 0, void 0, function* () {
            const client = AppSyncClientFactory.createProvider().getTypedClient(Service.USERS);
            const response = yield client.query(PolicyGroupsUsersListDocument, {
                policyGroupId: this.id,
            });
            if (!response.data.policyGroupsUsersList) {
                throwGQLError(response, "Failed to fetch policy group's users");
            }
            // As with AWSOrganization::getUsers - this is slow going
            const users = yield Promise.all(response.data.policyGroupsUsersList.users.map((userId) => this.backend.getUser(userId)));
            this.backend.entityRelationCache.replaceTypedLinks(this, AWSUser, users.filter(AWSUser.instanceOf));
        });
    }
}
