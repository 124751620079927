/*
 * Copyright (C) 2023 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */

import React, { Component, ReactNode } from "react";
import { TextField, debounce } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { Organization, Device, User, BackendFactory, UserAdminDetails } from "@sade/data-access";
import { OptionRenderer } from "./option-renderer";
import { SearchOption, getOrganizationId } from "./search-option";
import { translations } from "../../../generated/translationHelper";

const LUCENE_ESCAPE_CHARACTER = "\\";
const LUCENE_ESCAPE_CHARACTER_REGEX = /\\/g;
const LUCENE_SPECIAL_CHARACTERS: Array<[RegExp, string]> = [
  [/\+/g, `${LUCENE_ESCAPE_CHARACTER}+`],
  [/-/g, `${LUCENE_ESCAPE_CHARACTER}-`],
  [/&&/g, `${LUCENE_ESCAPE_CHARACTER}&${LUCENE_ESCAPE_CHARACTER}&`],
  [/\|\|/g, `${LUCENE_ESCAPE_CHARACTER}|${LUCENE_ESCAPE_CHARACTER}|`],
  [/!/g, `${LUCENE_ESCAPE_CHARACTER}!`],
  [/\(/g, `${LUCENE_ESCAPE_CHARACTER}(`],
  [/\)/g, `${LUCENE_ESCAPE_CHARACTER})`],
  [/{/g, `${LUCENE_ESCAPE_CHARACTER}{`],
  [/}/g, `${LUCENE_ESCAPE_CHARACTER}}`],
  [/\[/g, `${LUCENE_ESCAPE_CHARACTER}[`],
  [/]/g, `${LUCENE_ESCAPE_CHARACTER}]`],
  [/\^/g, `${LUCENE_ESCAPE_CHARACTER}^`],
  [/"/g, `${LUCENE_ESCAPE_CHARACTER}"`],
  [/~/g, `${LUCENE_ESCAPE_CHARACTER}~`],
  [/\*/g, `${LUCENE_ESCAPE_CHARACTER}*`],
  [/\?/g, `${LUCENE_ESCAPE_CHARACTER}?`],
  [/:/g, `${LUCENE_ESCAPE_CHARACTER}:`],
  [/\//g, `${LUCENE_ESCAPE_CHARACTER}/`],
];

// defines the minimum characters for the textfield input to launch the search
const MIN_SEARCH_CHARS = 4;

function escapeLuceneString(value: string): string {
  let escapedValue = value.replace(LUCENE_ESCAPE_CHARACTER_REGEX, LUCENE_ESCAPE_CHARACTER + LUCENE_ESCAPE_CHARACTER);

  for (const chrReplacementKit of LUCENE_SPECIAL_CHARACTERS) {
    escapedValue = escapedValue.replace(chrReplacementKit[0], chrReplacementKit[1]);
  }
  return escapedValue;
}

interface Props {
  onOptionSelect: (option: Organization) => void;
}

interface State {
  searchArray: SearchOption[];
  loading: boolean;
  currentSearchTerm: string;
  selectedValue?: SearchOption;
  selectedOrganization?: Organization;
  noOptionsText: string;
  userDetails?: UserAdminDetails;
}

export class OrganizationSearch extends Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = {
      searchArray: [],
      loading: false,
      currentSearchTerm: "",
      noOptionsText: translations.admin.texts.inputAtLeastNumChars({ number: MIN_SEARCH_CHARS }),
    };
  }

  private observeTextField(searchTerm: string): void {
    if (searchTerm.length < MIN_SEARCH_CHARS) {
      this.setState({
        searchArray: [],
        noOptionsText: translations.admin.texts.inputAtLeastNumChars({ number: MIN_SEARCH_CHARS }),
      });
      return;
    }
    this.debouncedSearch(searchTerm);
    this.setState({
      noOptionsText: "...",
    });
  }

  private debouncedSearch = debounce(this.search.bind(this), 600);

  private async search(searchTerm: string): Promise<void> {
    this.setState({
      loading: true,
      currentSearchTerm: searchTerm,
      noOptionsText: translations.admin.texts.noOptionsFound(),
    });
    const escapedSearchTerm = `thingName:${escapeLuceneString(searchTerm)}*`;
    let searchArray: SearchOption[] = [];

    const results = await Promise.allSettled([
      BackendFactory.getOrganizationBackend().searchUsers("email", searchTerm),
      (
        await BackendFactory.getBackend().searchDevices(escapedSearchTerm)
      ).filter((device: Device) => device.getAttribute("organization") !== undefined),
    ]);
    results.forEach((result) => {
      if (result.status === "fulfilled") {
        console.log("result", JSON.stringify(result.value));
        searchArray = searchArray.concat(result.value);
      }
    });

    if (searchTerm === this.state.currentSearchTerm) {
      this.setState({
        searchArray: searchArray,
        loading: false,
        selectedValue: undefined,
      });
    }
  }

  private handleOptionSelected = async (selectedOption: SearchOption): Promise<void> => {
    const organizationId = getOrganizationId(selectedOption);
    const selectedOrganization = organizationId
      ? await BackendFactory.getOrganizationBackend().getOrganization(organizationId)
      : undefined;
    if (selectedOrganization) {
      this.setState({
        selectedValue: selectedOption,
        selectedOrganization: selectedOrganization,
      });
      this.props.onOptionSelect(selectedOrganization);
    }
  };

  public render(): ReactNode {
    return (
      <Autocomplete
        id="organization-search"
        blurOnSelect
        value={this.state.selectedValue ?? null}
        options={this.state.searchArray}
        getOptionLabel={(): string => this.state.selectedOrganization?.getName() ?? ""}
        filterOptions={(x): Array<SearchOption> => x}
        noOptionsText={this.state.noOptionsText}
        onInputChange={(event, newInputValue, reason): void => {
          if (reason !== "reset") {
            this.observeTextField(newInputValue);
          }
        }}
        loading={this.state.loading}
        loadingText={translations.admin.texts.loading()}
        renderOption={(option: SearchOption): ReactNode => <OptionRenderer option={option} />}
        renderInput={(params): ReactNode => (
          <TextField {...params} label={translations.admin.inputs.organisationSearch()} variant="outlined" />
        )}
        onChange={(event, value): void => {
          if (value instanceof Device || value instanceof User) {
            this.handleOptionSelected(value);
          }
        }}
      />
    );
  }
}
