/*
 * Copyright (C) 2023 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */

import React, { Component, ReactNode } from "react";
import { Grid } from "@material-ui/core";
import { UserAdminDetails } from "@sade/data-access";

interface Props {
  userDetails: UserAdminDetails;
}

export default class UsernameRenderer extends Component<Props> {
  public constructor(props: Props) {
    super(props);
  }

  public render(): ReactNode {
    if (!this.props.userDetails.getFirstName() || !this.props.userDetails.getLastName()) {
      return (
        <Grid container className="adminview-username-grid">
          <Grid item className="adminview-username-text">
            {this.props.userDetails.getUsername()}
          </Grid>
        </Grid>
      );
    }
    return (
      <Grid container spacing={1} className="adminview-username-grid">
        <Grid item className="adminview-username-text">{`${this.props.userDetails.getFirstName()?.toUpperCase()} 
         ${this.props.userDetails.getLastName()?.toUpperCase()}`}</Grid>
      </Grid>
    );
  }
}
